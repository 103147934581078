import globalHook from "use-global-hook";

import * as actions from "../Actions";
import moment from "moment";

const initialState = {
  cost: 0,
  authState: {
    userName: null,
    userNt: null,
    userInfo: null,
    userId: null,
    rawToken: null,
    accessToken: null,
  },
  opsList: [],
  authClaims: [],
  permissionsTab: { key: "1" },
  poLineItemList: [],
  purchaseOrder: {
    poNumber: null,
    orderDate: moment(moment(), "MM/DD/YYYY"),
    vendorType: undefined,
    poLineItem: {
      id: null,
      migrateSoftware: true,
      publisherType: undefined,
      softwareType: undefined,
      publisherTypeList: [],
      softwareTypeList: [],
      softPubKeys: [],
      licenseSoftKeys: [],
      keyToMaintain: undefined,
      keysToUpgrade: [],
      licenseType: undefined,
      orgType: undefined,
      migrateUsers: true,
      installType: "User",
      installNumber: 1,
      checkBox: false,
      concurrency: false,
      installsPerSeat: 1,
      lineItemCost: null,
      maintainanceCostPerSeat: null,
      maintainanceStart: null,
      expDate: null,
      keyType: undefined,
      keyData: "",
      attachFile: [],
      attachReg: [],
      itemType: null,
    },
  },
  licenseInfo: {},
  vendorGuids: [],
  expandRows: [],
  activeTab: "1",
};

const useGlobal = globalHook(initialState, actions);

export default useGlobal;
