import React from "react";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from "antd";
import "./spinner.css";

const LoadingIcon = (
  <Loading3QuartersOutlined style={{ fontSize: 24 }} spin />
);

class Spinner extends React.Component {
  render() {
    return (
      <Spin
        indicator={LoadingIcon}
        size={this.props.size || null}
        spinning={this.props.loading || false}
        tip={this.props.tip || null}
      >
        {this.props.children}
      </Spin>
    );
  }
}

export default Spinner;
