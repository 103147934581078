import React, { useState } from "react";
import { Input } from "antd";
import "./index.css";

const { Search } = Input;

const HeaderSearch = (props) => {
  const [value, setValue] = useState();
  const searchChange = (e) => {
    setValue(e.target.value);
  };
  const onSearchSubmit = (e) => {
    props.history.push(`/Search/${e}`);
    setValue(null);
  };

  return (
    <Search
      style={{ width: 236, verticalAlign: "middle" }}
      onBlur={() => props.hideSearch(value)}
      value={value}
      onChange={searchChange}
      onSearch={onSearchSubmit}
      autoFocus
      placeholder="Search"
    />
  );
};

export default HeaderSearch;
