import React, { useState, useEffect } from "react";
import { Avatar, message, Input, Form } from "antd";
import ModalPopup from "../../ModalPopup/ModalPopup";
import Spinner from "../../../Shared/Spinner/Spinner";
import { graphql } from "react-apollo";
import { UPDATESOFTWARE_MUTATION } from "../../../Apollo/Mutations";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const colors = ["Red", "Green", "Blue", "Orange", "Purple"];

const EditSoftwares = (props) => {
  const { record, isVisible, setRecord } = props;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(true);
  const [newRec, setNewRec] = useState({
    name: "",
    ver: "",
  });
  useEffect(() => {
    function modalCheck() {
      if (isVisible) {
        setVisible(true);
      } else {
        setRecord("");
        setNewRec({
          name: "",
          ver: "",
        });
        handleCancel();
      }
    }
    modalCheck();
  }, [isVisible, setRecord]);

  const startLoading = () => {
    setLoading(true);
  };
  const stopLoading = () => {
    setLoading(false);
  };
  const onModalClose = () => {
    setVisible(false);
    props.setIsVisible(false);
  };
  const softChange = (e) => {
    setNewRec({ ...newRec, name: e.target.value });
  };
  const verChange = (e) => {
    setNewRec({ ...newRec, ver: e.target.value });
  };
  const avatar = () => {
    if (record) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            height: 50,
            paddingTop: 8,
          }}
        >
          <div style={{ marginRight: 10 }}>
            <Avatar
              size="large"
              style={{
                backgroundColor: colors[record.color],
              }}
            >
              {`${record.publisherName[0].toUpperCase()}`}
            </Avatar>
          </div>
          <div style={{ marginTop: 8 }}>
            <h3
              style={{ color: "whitesmoke" }}
            >{`${record.publisherName} `}</h3>
          </div>
        </div>
      );
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setUpdate(true);
    props.setIsVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };
  const onSubmit = () => {
    const softName = newRec.name ? newRec.name : record.name;
    const version = newRec.ver ? newRec.ver : record.version;
    if (softName === record.name && version === record.version) {
      message.info("No changes detected.");
    } else {
      startLoading();
      console.log(softName, version);
      props
        .mutate({
          variables: {
            software: {
              id: record.id,
              name: softName,
              version: version,
            },
          },
        })
        .then(({ data }) => {
          message.success(`${data.updateSoftware.name} Updated!`);

          stopLoading();
          onModalClose();
        })
        .catch((error) => {
          console.log(error);
          message.error("Error! please try again.");
          stopLoading();
        });
    }
  };

  const modalData = () => {
    if (visible) {
      return (
        <ModalPopup
          header={avatar()}
          name="Update"
          handleCancel={handleCancel}
          visible={visible}
          hideBtn={true}
          close={update ? "Close" : "Cancel"}
          submit={onSubmit}
          deleteBtn={false}
        >
          <Spinner loading={loading}>
            <h3
              style={{
                textAlign: "center",
                marginBottom: 25,
                color: "whitesmoke",
              }}
            >
              Edit Software
            </h3>
            <Form {...formItemLayout}>
              <Form.Item label="Name">
                <Input defaultValue={record.name} onChange={softChange} />
              </Form.Item>
              <Form.Item label="Version">
                <Input defaultValue={record.version} onChange={verChange} />
              </Form.Item>
            </Form>
          </Spinner>
        </ModalPopup>
      );
    }
  };

  return (
    <div>
      <div onClick={showModal}>{props.children}</div>
      {modalData()}
    </div>
  );
};

const EditSoftware = graphql(UPDATESOFTWARE_MUTATION)(EditSoftwares);

export default EditSoftware;
