import React from "react";
import { Form } from "antd";
import { Select } from "antd";
import { VENDORLIST_QUERY } from "../../Apollo/Queries";
import { Query } from "react-apollo";
//import AddVendorWithData from "./AddVendor";

const VendorList = (props) => {
  //let fullAccess;
  /* const addVendorHelper = () => {
    fullAccess = props.PoClaim.filter(i => i.authTypeName === "FullAccess");
    if (fullAccess.length > 0) {
      return <AddVendorWithData />;
    }
  }; */

  const { Option } = Select;
  return (
    <div>
      <Query query={VENDORLIST_QUERY}>
        {({ data, loading, error }) => {
          if (loading) return <div className="text-primary">Loading...</div>;
          if (error) return <p>ERROR</p>;
          return (
            <div>
              <Form.Item hasFeedback label="Vendor">
                <Select
                  showSearch
                  placeholder="Select Vendor"
                  style={{ minWidth: 180 }}
                  onChange={props.dropDownSelect}
                  value={props.value}
                >
                  {data.vendorList.map((p) => {
                    return <Option key={p.id}>{p.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              {/* <Form.Item>{addVendorHelper()}</Form.Item> */}
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default VendorList;
