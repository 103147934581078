import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Row, Col, Button, message, Empty } from "antd";
import Spinner from "../../../../Shared/Spinner/Spinner";
import PermissionTable from "../PermissionsBuilder/PermissionTable";
import { avatar } from "../PermissionsBuilder/PermissionFunctions";
import { Query, graphql } from "react-apollo";
import {
  USERACCESSLIST_QUERY,
  USERSACCESS_QUERY,
} from "../../../../Apollo/Queries";
import { UPDATEPERMISSIONS_MUTATION } from "../../../../Apollo/Mutations";
import useGlobal from "../../../../GlobalState/Store";
import Exception from "../../../../Shared/Errors/Exception";

// TODO: Azure Migration DONE - Add azureObjectId to state
const EditUser = (props) => {
  const [state, setState] = useState({
    azureObjectId: "",
    pidKey: "",
    permissions: [],
    loading: false,
    superAdmin: false,
  });
  const [adminAccess, setAdminAccess] = useState(false);
  const globalState = useGlobal()[0];
  const { authClaims } = globalState;
  const [hideBtn, sethideBtn] = useState(false);

  // FIXME: Azure Migration DONE - Change from authAccess to authAccesses
  const notAuthorized = (data) => {
    var admin = data.getUsersAccessList.authAccesses.filter(
      (i) => i.ops.id === 11
    );
    if (admin.length > 0  && adminAccess) {
      sethideBtn(true);
      return (
        <PermissionTable
          authAccesses={data.getUsersAccessList.authAccesses}
          edit={true}
          updateParent={updatePermissions}
          heading="New User Permissions Builder"
        />
      );
    } else if (admin.length === 0) {
      sethideBtn(true);
      return (
        <PermissionTable
          authAccesses={data.getUsersAccessList.authAccesses}
          edit={true}
          updateParent={updatePermissions}
          heading="New User Permissions Builder"
        />
      );
    }
    return (
      <div>
        <Exception errorCode={403} message="Not Authorized!" />
      </div>
    );
  };

  useEffect(() => {
    const claim = authClaims.filter((i) => i.ops.id === 11);
    if (claim.length > 0) {
      setAdminAccess(true);
    }
  }, []);

  const updatePermissions = (childState, admin) => {
    setState({ ...state, permissions: childState, superAdmin: admin });
  };

  const onSubmit = () => {
    if (
      (props.match.params.id && state.permissions.length > 0) ||
      (props.match.params.id && state.superAdmin)
    ) {
      setState({ ...state, loading: true });
      props
        .mutate({
          variables: {
            var: {
              id: props.match.params.id,
              admin: state.superAdmin,
              userPermissions: state.permissions,
            },
          },
          /* update: (store, { data: { updatePermissions } }) => {
            const User = store.readQuery({ query: USERSACCESS_QUERY });
            User.getUsersWithAccess.filter(
              (i) => i.pidKey === props.match.params.id
            );
            User.getUsersWithAccess.unshift(updatePermissions);
            store.writeQuery({ query: USERSACCESS_QUERY, data: User });
          }, */
          //TODO: Azure Migration DONE - Replace pidkey with azureObjectId
          refetchQueries: [
            {
              query: USERACCESSLIST_QUERY,
              variables: {
                //TODO: Azure Migration DONE - Replace pidkey with azureObjectId
                //pidkey: props.match.params.id,
                azureObjectId: props.match.params.id
              },
            },
            { query: USERSACCESS_QUERY },
          ],
        })
        .then(({ data }) => {
          setState({ ...state, loading: false });
          props.history.push("/Administration/Permissions");
          message.success(
            `${data.updatePermissions.name} updated successfully!`
          );
        })
        .catch((error) => {
          setState({ ...state, loading: false });
          if (error.networkError) {
            if (error.networkError.result.errors[0].message !== null) {
              message.error(error.networkError.result.errors[0].message);
            }
          } else {
            message.error("An Error occured. Please try again.");
          }
        });
    } else {
      message.info("Please fill out all mandatory fields properly.");
    }
  };

  //TODO: Azure Migration DONE - Replace pickey with azureObjectId
  return (
    <div style={{ padding: "1em", marginBottom: "1em" }}>
      <Spinner loading={state.loading}>
        <Query
          query={USERACCESSLIST_QUERY}
          variables={{
            //pidkey: props.match.parms.id,
            azureObjectId: props.match.params.id,
          }}
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <p style={{ padding: 20 }}>Loading...</p>;
            }
            if (error) return <p>ERROR</p>;
            if (data.getUsersAccessList === null)
              return (
                <>
                  <Row style={{ marginBottom: 100 }} gutter={[8, 24]}>
                    <Col span={24}>
                      <div className="ant-descriptions-title">
                        Edit Permissions
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[8, 24]}>
                    <Empty />
                  </Row>
                </>
              );
            const {
              getUsersAccessList: { name, email, color },
            } = data;

            return (
              <>
                <Row>
                  <Col span={24}>
                    <div className="ant-descriptions-title">
                      Edit Permissions
                    </div>
                  </Col>
                </Row>
                <Row gutter={[8, 24]}>
                  <Col span={23} offset={1}>
                    {avatar(name, email, color)}
                  </Col>
                </Row>
                <Row gutter={[8, 24]}>
                  <Col span={24}>{notAuthorized(data)}</Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Link to="/Administration/Permissions">
                      <Button type="primary">
                        <LeftOutlined />
                        Go back
                      </Button>
                    </Link>
                  </Col>
                  {hideBtn ? (
                    <Col span={12}>
                      <Button
                        onClick={onSubmit}
                        style={{ float: "right" }}
                        type="primary"
                      >
                        Submit
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </>
            );
          }}
        </Query>
      </Spinner>
    </div>
  );
};
const Edit = graphql(UPDATEPERMISSIONS_MUTATION)(EditUser);
export default Edit;
