import React, { useState } from "react";
import { Query, graphql } from "react-apollo";
import { DeleteOutlined } from "@ant-design/icons";
import { Table, Avatar, Button, Popconfirm, Skeleton, message } from "antd";

import { colors } from "../../../Shared/Helpers";
import { APPASSIGNMENTS_QUERY } from "../../../Apollo/Queries";
import AddNewAssignment from "./AddAssignment";
import { DELETEAPPASSIGNMENT_MUTATION } from "../../../Apollo/Mutations";
import Spinner from "../../../Shared/Spinner/Spinner";
import Exception from "../../../Shared/Errors/Exception";
import UpdateAssignment from "./UpdateAssignment";

const AppAssignment = (props) => {
  const [opsIds, setOpsIds] = useState([]);
  const [spinner, setLoading] = useState(false);

  const deleteAssignment = (id) => {
    startLoading();
    props
      .mutate({
        variables: {
          id,
        },
        update: (store, { data: { deleteAppAssignment } }) => {
          const remove = store.readQuery({
            query: APPASSIGNMENTS_QUERY,
            variables: { opsIds: [] },
          });
          remove.appAssignmentListByOrg = remove.appAssignmentListByOrg.filter(
            function (obj) {
              return obj.id !== deleteAppAssignment.id;
            }
          );
          store.writeQuery({
            query: APPASSIGNMENTS_QUERY,
            variables: { opsIds: [] },
            data: remove,
          });
        },
      })
      .then(({ data }) => {
        stopLoading();
      })
      .catch((error) => {
        if (error.networkError) {
          if (error.networkError.result.errors[0].message) {
            message.warn(error.networkError.result.errors[0].message);
          }
        } else {
          message.error("An error occured!");
        }
        stopLoading();
      });
  };

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const useColorTracking = [];
  const getUniqueColor = (input) => {
    //return the stored color if it exists
    if (useColorTracking[input]) {
      return useColorTracking[input];
    }
    //Grab the next color in the list, need to start at 0 or we skip red
    const color = 1 + useColorTracking.length++;
    //Store the color for future use
    useColorTracking[input] = color;
    //Return the color
    return color;
  };

  const columns = [
    {
      title: "Org",
      dataIndex: "ops.subRegion",
      key: "ops.subRegion",
      sorter: (a, b) =>
        a.ops.subRegion.localeCompare(b.ops.subRegion, "en", {
          numeric: false,
        }),
      render: (text, rec) => {
        const avatar = (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              height: 50,
              paddingTop: 8,
            }}
          >
            <div style={{ marginRight: 10 }}>
              <Avatar
                style={{
                  backgroundColor: colors[getUniqueColor(rec.ops.subRegion)],
                }}
              >
                {`${rec.ops.subRegion[0].toUpperCase()}`}
              </Avatar>
            </div>
            <div style={{ marginTop: 4 }}>
              <h4 style={{ color: "whitesmoke" }}>{`${rec.ops.subRegion} `}</h4>
            </div>
          </div>
        );
        return <div>{avatar}</div>;
      },
    },
    {
      title: "SAM Software",
      dataIndex: "software.name",
      key: "software.name",
      sorter: (a, b) =>
        a.software.name.localeCompare(b.software.name, "en", {
          numeric: false,
        }),
      render: (text, rec) => {
        const avatar = (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              maxHeight: 80,
            }}
          >
            <div>
              <h3 style={{ color: "whitesmoke", marginBottom: 0 }}>{text}</h3>
              <p>{rec.publisher.name}</p>
            </div>
          </div>
        );
        return (
          <div>
            <p style={{ marginBottom: 5, marginTop: 5 }}>{text}</p>
            <p style={{ marginBottom: 5, fontSize: ".8em" }}>
              {rec.publisher.name}
            </p>
          </div>
        );
      },
    },
    {
      title: "Version",
      dataIndex: "software.version",
      key: "software.version",
      sorter: (a, b) =>
        a.software.version.localeCompare(b.software.version, "en", {
          numeric: true,
        }),
    },
    {
      title: "SCCM Name",
      dataIndex: "sccmName",
      key: "sccmName",
      sorter: (a, b) =>
        a.sccmName.localeCompare(b.sccmName, "en", { numeric: false }),
      render: (text, rec) => {
        if (rec.sccmName) {
          return (
            <div>
              <p style={{ marginBottom: 5, marginTop: 5 }}>{rec.sccmName}</p>
            </div>
          );
        }
        return "";
      },
    },
    {
      title: "SCCM Collection",
      dataIndex: "collectionName",
      key: "SCCM Collection",
      sorter: (a, b) =>
        a.collectionName.localeCompare(b.collectionName, "en", {
          numeric: false,
        }),
      render: (text, rec) => {
        if (rec.collectionId) {
          return (
            <div>
              <p style={{ marginBottom: 5, marginTop: 5 }}>
                {rec.collectionName}
              </p>
              <p style={{ marginBottom: 5, fontSize: ".8em" }}>
                Collection ID: {rec.collectionId}
              </p>
            </div>
          );
        }
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, rec) => {
        return (
          <div>
            <div style={{ display: "inline-block", marginRight: 8 }}>
              <UpdateAssignment record={rec} />
            </div>
            <Popconfirm
              title={`Are you sure you want to remove ${rec.software.name}?`}
              onConfirm={() => deleteAssignment(rec.id)}
            >
              <Button
                style={{ marginTop: 8 }}
                type="danger"
                icon={<DeleteOutlined />}
                size="small"
                ghost
              >
                Remove
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ margin: "20px" }}>
      <Query query={APPASSIGNMENTS_QUERY} variables={{ opsIds }}>
        {({ data, loading, error }) => {
          if (loading)
            return (
              <Spinner loading={true}>
                <div className="ant-descriptions-title">App Assignments</div>
                <AddNewAssignment />
                <div style={{ minWidth: "24em" }}>
                  <Table style={{ marginTop: 10 }} />
                </div>
              </Spinner>
            );
          if (error)
            return (
              <Exception
                errorCode={403}
                message="Sorry, an error occured. Please try again. If problem presists please restart your browser."
              />
            );
          return (
            <Spinner loading={spinner}>
              <div className="ant-descriptions-title">App Assignments</div>
              <AddNewAssignment PoClaim={props.PoClaim} />
              <div style={{ minWidth: "24em" }}>
                <Table
                  style={{ marginTop: 10 }}
                  size="small"
                  columns={columns}
                  rowKey={(rec) => rec.id}
                  dataSource={data.appAssignmentListByOrg.sort((a, b) => {
                    if (a.ops.subRegion < b.ops.subRegion) {
                      return -1;
                    }
                    if (a.ops.subRegion > b.ops.subRegion) {
                      return 1;
                    }
                    return 0;
                  })}
                  pagination={{
                    defaultPageSize: 25,
                    pageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50"],
                  }}
                />
              </div>
            </Spinner>
          );
        }}
      </Query>
    </div>
  );
};
const AppAssignments = graphql(DELETEAPPASSIGNMENT_MUTATION)(AppAssignment);
export default AppAssignments;
