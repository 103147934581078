import { useMediaQuery } from "react-responsive";

export const newGuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const Responsive = () => {
  const isTablet = useMediaQuery({
    query: "(max-width: 830px)",
  });
  const isMobil = useMediaQuery({
    query: "(max-width: 665px)",
  });

  return [isTablet, isMobil];
};

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const dateFormat = "MM/DD/YYYY";

export const updateRecord = (records, id, prop, val) => {
  var record = records.find(function (p) {
    return p.id === id;
  });

  if (record && record[prop]) {
    record[prop] = val;
  }
  return records;
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export const PermissionsFormLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 3 },
    lg: { span: 3 },
    xl: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 17 },
    lg: { span: 17 },
    xl: { span: 16 },
  },
};

//First entry is the default color
export const colors = [
  "Red",
  "Red",
  "Green",
  "Blue",
  "Orange",
  "Purple",
  "Yellow",
  "Pink",
  "Brown",
  "Grey",
  "Black",
  "White",
];
