import { MsalAuthProvider, LoginType } from "react-aad-msal";

const config = {
  auth: {
    authority:
      "https://login.microsoftonline.com/906aefe9-76a7-4f65-b82d-5ec20775d5aa/",
    clientId: "f321cffe-184b-44ca-a4cf-62a3969793ca",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const authenticationParameters = {
  scopes: ["Group.Read.All", "User.Read.All"],
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  LoginType.Redirect
);
