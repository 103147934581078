import React from "react";
import SiderMenuItems from "./SiderMenuItems";
import { Layout } from "antd";
import config from "../config.json";

class SiderMenu extends React.Component {
  displayName = SiderMenu.name;

  render() {
    const { Sider } = Layout;

    return (
      <Sider
        width="255"
        trigger={null}
        collapsible
        collapsed={this.props.collapsed}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          this.props.callback(broken);
        }}
      >
        <SiderMenuItems claims={this.props.claims} />
        <div
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            fontSize: "0.8em",
            color: "rgb(128, 128, 128, .5)",
            textAlign: "center",
          }}
        >
          {config.Site.buildNum}
        </div>
      </Sider>
    );
  }
}

export default SiderMenu;
