import React, { useState } from "react";
import { CloseOutlined } from '@ant-design/icons';
import { Select, Popconfirm, Button } from "antd";

const SelectComputer = props => {
  const { record, editingKey, Update, removeSystem } = props;

  const [computer, selectComputer] = useState(
    props.record.computers ? props.record.computers[0].id : []
  );
  const selectOnChange = val => {
    selectComputer(val);
  };
  const clearComputer = () => {
    selectComputer([]);
  };
  return (
    <div
      style={{
        display: "inline"
      }}
    >
      <Select
        placeholder="Select computer"
        style={{ width: 140 }}
        onChange={selectOnChange}
        value={computer}
      >
        {record.computers.map(e => {
          return <Select.Option key={e.id}>{e.computerName}</Select.Option>;
        })}
      </Select>
      <span style={{ paddingTop: 4 }}>
        <Popconfirm
          title="Are you sure you want to remove this item?"
          disabled={editingKey !== "" || !Update}
          onConfirm={() =>
            removeSystem(
              computer,
              record.userNt,
              clearComputer,
              record.computers.length,
              record
            )
          }
        >
          <Button
            type="danger"
            icon={<CloseOutlined />}
            disabled={editingKey !== "" || !Update}
            size="small"
            style={{
              width: "18px",
              height: "18px",
              minWidth: "15px",
              fontSize: "8px",
              marginLeft: 10
            }}
            ghost
          />
        </Popconfirm>
      </span>
    </div>
  );
};

export default SelectComputer;
