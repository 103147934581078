import React, { useState } from "react";
import PermissionTable from "../PermissionsBuilder/PermissionTable";
import UserLookup from "./UserLookup";
import { LeftOutlined } from '@ant-design/icons';
import { Row, Col, Button, message } from "antd";
import { Link } from "react-router-dom";
import Spinner from "../../../../Shared/Spinner/Spinner";
import { graphql } from "react-apollo";
import { ADDNEWUSERPERMISSIONS_MUTATION } from "../../../../Apollo/Mutations";
import { USERSACCESS_QUERY } from "../../../../Apollo/Queries";

const AddNewUser = (props) => {
  const [state, setState] = useState({
    user: "",
    permissions: [],
    loading: false,
    isAdmin: false,
    superAdmin: false,
  });
  const updatePermissions = (childState, admin) => {
    setState({ ...state, permissions: childState, superAdmin: admin });
  };

  const updateUser = (user) => {
    setState({ ...state, user });
  };

  const onSubmit = () => {
    if (
      (state.user && state.permissions.length > 0) ||
      (state.user && state.superAdmin)
    ) {
      setState({ ...state, loading: true });
      props
        .mutate({
          variables: {
            var: {
              id: state.user,
              admin: state.superAdmin,
              userPermissions: state.permissions,
            },
          },
          update: (store, { data: { addNewUserPermissions } }) => {
            const User = store.readQuery({ query: USERSACCESS_QUERY });
            User.getUsersWithAccess.unshift(addNewUserPermissions);
            store.writeQuery({ query: USERSACCESS_QUERY, data: User });
          },
          refetchQueries: [
            {
              query: USERSACCESS_QUERY,
            },
          ],
        })
        .then(({ data }) => {
          setState({ ...state, loading: false });
          props.history.push("/Administration/Permissions");
          message.success(
            `${data.addNewUserPermissions.name} added successfully!`
          );
        })
        .catch((error) => {
          setState({ ...state, loading: false });
          if (error.networkError) {
            if (error.networkError.result.errors[0].message !== null) {
              message.error(error.networkError.result.errors[0].message);
            }
          } else {
            message.error("An Error occured. Please try again.");
          }
        });
    } else {
      message.info("Please fill out all mandatory fields properly.");
    }
  };

  return (
    <div style={{ padding: "1em", marginBottom: "1em" }}>
      <Spinner loading={state.loading}>
        <Row>
          <Col span={24}>
            <div className="ant-descriptions-title">Add New User</div>
          </Col>
        </Row>
        <Row gutter={[8, 24]}>
          <Col span={24}>
            <UserLookup updateParent={updateUser} />
            <PermissionTable
              updateParent={updatePermissions}
              heading="New User Permissions Builder"
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Link to="/Administration/Permissions">
              <Button type="primary">
                <LeftOutlined />
                Go back
              </Button>
            </Link>
          </Col>
          <Col span={12}>
            <Button
              onClick={onSubmit}
              style={{ float: "right" }}
              type="primary"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Spinner>
    </div>
  );
};
const NewUser = graphql(ADDNEWUSERPERMISSIONS_MUTATION)(AddNewUser);
export default NewUser;
