import React, { useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Table, Button, Avatar } from "antd";
import { Tabs } from "antd";
import { BROWSELIST_QUERY } from "../../Apollo/Queries";
import { Query } from "react-apollo";
import Purchases from "./Purchases";
import Keys from "./Keys";
import useGlobal from "../../GlobalState/Store";
import { Responsive } from "../../Shared/Helpers";
import Exception from "../../Shared/Errors/Exception";

const { TabPane } = Tabs;
const colors = ["Red", "Green", "Blue", "Orange", "Purple"];
const BrowseList = (props) => {
  const [globalState, globalActions] = useGlobal();
  const { expandRows, activeTab } = globalState;
  const { setExpandRows, setActiveTab } = globalActions;
  const [Loading, setLoading] = useState(false);
  const [isTablet, isMobil] = Responsive();

  const columns = [
    {
      title: "Software",
      dataIndex: "softwareName",
      key: "name",
      sorter: (a, b) =>
        a.softwareName.localeCompare(b.softwareName, "en", { numeric: false }),
      render: (text, rec) => {
        const avatar = (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              maxHeight: 200,
            }}
          >
            <div style={{ marginRight: 10, paddingTop: 2 }}>
              <Avatar
                style={{
                  backgroundColor: colors[rec.color],
                }}
              >
                {`${text[0].toUpperCase()}${text[1].toUpperCase()}`}
              </Avatar>
            </div>
            <div>
              <h3 style={{ color: "whitesmoke", marginBottom: 0 }}>{text}</h3>
              <p style={{ color: "whitesmoke", marginBottom: 0 }}>
                {rec.publisherName}
              </p>
            </div>
          </div>
        );
        return <div>{avatar}</div>;
      },
    },
    {
      title: "Key Overview",
      dataIndex: "keyOverview",
      key: "keyOverview",

      render: (text, record) => {
        const allowed = record.allowed;
        return `${text}/${allowed === 0 ? "Unlimited" : allowed}`;
      },
    },
  ];

  const columFilter = () => {
    return isMobil ? columns.filter((t) => t.title !== "Publisher") : columns;
  };

  const backButtonHelper = () => {
    if (props.match.params.id) {
      return (
        <Button
          style={{ marginLeft: 20 }}
          onClick={() => props.history.goBack()}
          type="primary"
        >
          <LeftOutlined />
          Go back
        </Button>
      );
    }
  };

  const handleExpandRow = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record.softwareId);
    }
    setExpandRows([...expandedRows]);
  };
  function callback(key) {
    setActiveTab(key);
  }
  const expandHelper = (record) => {
    return (
      <div>
        <Tabs defaultActiveKey={activeTab} onChange={callback} type="card">
          <TabPane tab="Keys" key="1">
            <Keys
              claim={props.KeyClaim}
              isMobil={isMobil}
              isTablet={isTablet}
              //opsId={record.opsId}
              name={`${record.publisherName} ${record.softwareName}`}
              softwareId={record.softwareId}
            />
          </TabPane>
          <TabPane tab="Purchases" key="2">
            <Purchases
              isMobil={isMobil}
              isTablet={isTablet}
              name={record.softwareName}
              softwareId={record.softwareId}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  };

  return (
    <div style={{ padding: "1em" }}>
      <Query
        query={BROWSELIST_QUERY}
        variables={{
          id: props.match.params.id,
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <div
                style={{
                  padding: "1em",
                }}
              >
                <Table loading={true} bordered />
              </div>
            );
          }
          if (error)
            return (
              <Exception
                errorCode={403}
                message="Sorry, an error occured. Please try again. If problem presists please restart your browser."
              />
            );
          return (
            <div
              style={{
                padding: "1em",
              }}
            >
              <Table
                rowKey={(rec) => rec.id}
                columns={columFilter()}
                dataSource={data.browseList}
                expandedRowRender={expandHelper}
                pagination={{
                  defaultPageSize: 25,
                  pageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "25", "50"],
                }}
                expandedRowKeys={expandRows}
                onExpand={handleExpandRow}
                bordered
                size="small"
              />
            </div>
          );
        }}
      </Query>
      {backButtonHelper()}
    </div>
  );
};

export default BrowseList;
