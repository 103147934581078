import React, { useState, useEffect } from "react";
import {
  KEYUSE_QUERY,
  BROWSELIST_QUERY,
  KEYSBYSOFTWAREID_QUERY,
  KEYSBYUSER_QUERY,
} from "../../Apollo/Queries";
import { Query, graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import {
  Table,
  DatePicker,
  Popconfirm,
  Button,
  message,
  Avatar,
  Tooltip,
} from "antd";
import moment from "moment";
import Spinner from "../../Shared/Spinner/Spinner";
import {
  UPDATE_ENDDATE_MUTATION,
  REMOVE_KEYUSE_MUTATION,
  REMOVE_KEYUSECOMPUTER_MUTATION,
} from "../../Apollo/Mutations";
import AssignKey from "./AssignKey";
import { useMediaQuery } from "react-responsive";
import useGlobal from "../../GlobalState/Store";
import API from "../../services/API";

const ManageKeys = (props) => {
  const globalState = useGlobal()[0];
  const { authClaims } = globalState;
  const [authState, setauthState] = useState({
    createDelete: false,
    update: false,
  });

  useEffect(() => {
    var opsId = props.match.params.opsid;
    if (authClaims.length > 0 && opsId) {
      authClaims.forEach((e) => {
        if (e.ops.id === 11) {
          setauthState({ createDelete: true, update: true });
        } else if (e.ops.id === parseInt(opsId) && e.moduleId === 3) {
          if (e.authTypeId === 11) {
            setauthState({ createDelete: true, update: true });
          }
          if (e.authTypeId === 8) {
            setauthState({ createDelete: true, update: false });
          }
          if (e.authTypeId === 9) {
            setauthState({ createDelete: false, update: true });
          }
        }
      });
    }
  }, [authClaims]);

  const KeyDataId = props.match.params.id;
  const softId = props.match.params.softid;
  const name = props.match.params.name;
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [apidata, setapidata] = useState(null);
  const [paging, setPaging] = useState({
    currentPage: 1,
    pageSize: 20,
    total: 0,
  });

  const isTablet = useMediaQuery({
    query: "(max-width: 830px)",
  });
  const isMobil = useMediaQuery({
    query: "(max-width: 665px)",
  });

  const endDateSelect = (a, b) => {
    setEndDate(b);
  };

  const editClick = (key) => {
    setEditingKey(key);
  };
  const isEditing = (record) => record.id === editingKey;

  const deleteRecord = async (rec) => {
    console.log(rec);
    setLoading(true);
    const result = await API.delete(`KeyUse`, {
      headers: {
        keyUseId: rec.id,
      },
    })
      // .then(({ data }) => {

      // })
      .catch((error) => {
        if (error.networkError) {
          if (error.networkError.result.errors[0].message) {
            message.warn(error.networkError.result.errors[0].message);
          }
        } else {
          message.error("An error occured!");
          console.log(error);
        }
      });
    console.log(result);
    setLoading(false);
    setEditingKey("");
  };

  const dateSubmit = (rec) => {
    const id = rec.id;
    const date = rec.userEndDate;
    const newDate = moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
    if (endDate !== newDate) {
      setLoading(true);
      props
        .updateKey({
          variables: {
            keyuse: {
              id: id,
              userEndDate: endDate || null,
            },
            keyDataId: KeyDataId,
          },
          refetchQueries: [
            {
              query: BROWSELIST_QUERY,
            },
            {
              query: KEYSBYUSER_QUERY,
              variables: {
                nt: rec.userNt,
              },
            },
            {
              query: KEYSBYSOFTWAREID_QUERY,
              variables: {
                SoftwareId: softId,
              },
            },
          ],
        })
        .then(({ data }) => {
          setLoading(false);
          setEditingKey("");
        })
        .catch((error) => {
          if (error.networkError) {
            if (error.networkError.result.errors[0].message) {
              message.warn(error.networkError.result.errors[0].message);
            }
          } else {
            message.error("An error occured!");
          }
          setLoading(false);
        });
    } else {
      setEditingKey("");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, rec) => {
        const string = text.split(",");
        const name = `${string[1] ? string[1].trim() : ""} ${
          string[0] ? string[0].trim() : ""
        }`;
        const letters = name.split(" ");
        const avatar = (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              height: 40,
              paddingTop: 5,
            }}
          >
            {isMobil ? (
              <></>
            ) : (
              <div style={{ marginRight: 10 }}>
                <Avatar style={{ backgroundColor: "blue" }}>
                  {letters[0] && letters[0][0]}
                  {letters[1] && letters[1][0]}
                </Avatar>
              </div>
            )}
            <div style={{ marginTop: 8 }}>
              <h4 style={{ color: "whitesmoke" }}>
                {`${name} `}
                <Tooltip
                  key={rec.userNt}
                  placement="right"
                  title={
                    <div>
                      <p>Username: {rec.userNt}</p>
                    </div>
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </h4>
            </div>
          </div>
        );
        return avatar;
      },
    },
    {
      title: "Assigned date",
      dataIndex: "userStartDate",
      key: "userStartDate",
      render: (text) => {
        return moment(text, "YYYY-MM-DD").format("MM/DD/YYYY");
      },
    },
    {
      title: "End Date",
      dataIndex: "userEndDate",
      key: "userEndDate",
      width: editingKey ? (isMobil ? 200 : 220) : 130,
      render: (text, record) => {
        const editable = isEditing(record);
        const date = moment().format("YYYY-MM-DD");
        return editable ? (
          <div
            style={{
              display: "inline",
            }}
          >
            <DatePicker
              style={{
                width: isMobil ? 120 : 130,
                marginBottom: 5,
              }}
              defaultValue={text ? moment(text, "YYYY-MM-DD") : null}
              format={"MM/DD/YYYY"}
              onChange={endDateSelect}
              loading
              autoFocus
            />
            <span
              style={{
                marginLeft: 5,
              }}
            >
              <Tooltip title="Save">
                <Button
                  shape="circle"
                  icon={<CheckOutlined />}
                  onClick={() => dateSubmit(record)}
                  size="small"
                  style={{
                    color: "rgba(0,255,0,.5)",
                    borderColor: "rgba(0,255,0,.5)",
                    marginRight: 5,
                  }}
                  ghost
                />
              </Tooltip>
              <Tooltip title="Cancel">
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => setEditingKey("")}
                  size="small"
                  style={{
                    marginLeft: 2,
                    color: "rgba(255,0,0,.8)",
                    borderColor: "rgba(255,0,0,.8)",
                  }}
                  ghost
                />
              </Tooltip>
            </span>
          </div>
        ) : (
          <div
            style={{
              color: text < date ? "red" : null,
            }}
          >
            {text ? moment(text, "YYYY-MM-DD").format("MM/DD/YYYY") : "No End"}
            <span style={{ float: "right" }}>
              <Tooltip visible={false} title="Edit end date">
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  disabled={editingKey !== "" || !authState.createDelete}
                  onClick={() => editClick(record.id)}
                  size="small"
                  ghost
                ></Button>
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      width: isMobil || isTablet ? 45 : 140,
      render: (t, r) => (
        <div style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure you want to remove this item?"
            disabled={editingKey !== "" || !authState.createDelete}
            onConfirm={() => deleteRecord(r)}
          >
            <Tooltip title="Remove key assignment" placement="left">
              <Button
                type="danger"
                icon={<UserDeleteOutlined />}
                disabled={editingKey !== "" || !authState.createDelete}
                size="small"
                ghost
              >
                {isMobil || isTablet ? "" : "Remove"}
              </Button>
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const columnsHelper = () => {
    if (isTablet || isMobil) {
      return columns.filter((i) => i.key !== "userStartDate");
    } else {
      return columns;
    }
  };

  const getApiData = async (pageSettings) => {
    const result = await API.get(`Browse/ManageKey`, {
      headers: {
        licenseId: pageSettings.licenseId,
        pageNum: pageSettings.currentPage,
        pageSize: pageSettings.pageSize,
      },
    });
    console.log(result);
    setapidata(result.data);
    console.log(result.data);
    setPaging({
      currentPage: pageSettings.currentPage,
      pageSize: pageSettings.pageSize,
      total: result.data.totalCount,
    });
  };

  const pageChange = (pagination) => {
    const { total, current, pageSize } = pagination;

    getApiData({
      licenseId: KeyDataId,
      currentPage: current,
      pageSize: pageSize,
      total: total,
    });
  };

  if (!apidata) {
    getApiData({
      licenseId: KeyDataId,
      currentPage: 1,
      pageSize: paging.pageSize,
      total: 0,
    });
    console.log(props.match.params);
  }

  return (
    <div>
      <Spinner loading={loading}>
        <div
          style={{
            padding: "1em",
          }}
        >
          {authState.createDelete ? (
            <AssignKey
              create={authState.createDelete}
              KeyDataId={KeyDataId}
              paging={paging}
              softId={softId}
            />
          ) : (
            <Button
              disabled={true}
              style={{ marginBottom: 10 }}
              icon={<UserAddOutlined />}
            >
              Assign new key
            </Button>
          )}
          {apidata ? (
            <>
              {paging.total !== 0 ? (
                <Table
                  title={() => (
                    <div className="ant-descriptions-title">{name}</div>
                  )}
                  bordered
                  rowKey={(rec) => rec.id}
                  columns={columnsHelper()}
                  dataSource={apidata.data}
                  onChange={(a, b, c, d) => pageChange(a)}
                  pagination={{
                    total: paging.total,
                    defaultPageSize: paging.pageSize,
                    pageSize: paging.pageSize,
                    current: paging.currentPage,
                    showSizeChanger: true,
                  }}
                  size="small"
                />
              ) : (
                "No Key Usage"
              )}
            </>
          ) : null}
        </div>
      </Spinner>

      <Button
        style={{ margin: "20px 20px 20px 20px" }}
        onClick={() => props.history.goBack()}
        type="primary"
      >
        <LeftOutlined />
        Go back
      </Button>
    </div>
  );
};

const ManageKey = compose(
  graphql(UPDATE_ENDDATE_MUTATION, {
    name: "updateKey",
  }),
  graphql(REMOVE_KEYUSE_MUTATION, {
    name: "removeKey",
  }),
  graphql(REMOVE_KEYUSECOMPUTER_MUTATION, {
    name: "removeSystem",
  })
)(ManageKeys);

export default ManageKey;
