import React from "react";
import { Drawer } from "antd";

import SiderMenuItems from "./SiderMenuItems";

class MobileDrawer extends React.Component {
  displayName = MobileDrawer.name;

  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <Drawer
        placement="left"
        closable={false}
        onClose={this.props.onClose}
        visible={this.props.visible}
        bodyStyle={{
          backgroundColor: "#2a2c36",
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
          height: "100%",
        }}
      >
        <SiderMenuItems claims={this.props.claims} />
      </Drawer>
    );
  }
}

export default MobileDrawer;
