import { gql } from "apollo-boost";

export const ALLUSERS_QUERY = gql`
  query {
    users {
      id
      name
      email
      role {
        roleName
      }
      jobDescr
      photoUrl
    }
  }
`;

export const PUBLISHERLIST_QUERY = gql`
  query {
    publisherList {
      id
      name
      supportContact
      kbUrl
      notes
    }
  }
`;

export const SOFTWARELIST_QUERY = gql`
  query {
    softwareList {
      id
      name
    }
  }
`;

export const SOFTWARELISTBYPUBLISHERID_QUERY = gql`
  query ($publisher: [ID!]) {
    softwareByPublisherId(publisher: $publisher) {
      id
      name
      version
      publisherId
    }
  }
`;

export const VENDORLIST_QUERY = gql`
  query {
    vendorList {
      id
      name
      supportContact
      kbUrl
      notes
    }
  }
`;

export const TOKENLIST_QUERY = gql`
  query {
    tokenList {
      id
      name
    }
  }
`;

export const LICENSETYPELIST_QUERY = gql`
  query {
    licenseTypeList {
      id
      name
    }
  }
`;

export const KEYTYPELIST_QUERY = gql`
  query {
    keyTypeList {
      id
      name
    }
  }
`;

export const BROWSELIST_QUERY = gql`
  query ($id: ID) {
    browseList(id: $id) {
      id
      softwareId
      softwareName
      publisherName
      keyOverview
      allowed
      opsId
      org
      color
    }
  }
`;
export const BROWSELIST_QUERY2 = gql`
  query ($var: UtilizationInputType!) {
    browseList2(utilization: $var) {
      id
      publisherName
      softwareName
      keyOverview
      softId
    }
  }
`;

/* export const LICENSEBYSOFTWARE_QUERY = gql`
  query {
    licenseUsageBySoftware {
      softwareId
      softwareName
      publisherName
      keyOverview
    }
  }
`; */

export const KEYSBYSOFTWAREID_QUERY = gql`
  query ($SoftwareId: ID!) {
    keysbySoftware(softwareId: $SoftwareId) {
      id
      keyUsage
      expiration
      licenseType
      softwareId
      licenseTypeId
      keyString
      cost
      maintainanceCost
      opsId
      org
      attachedFilename {
        id
        uid
        name
        type
        size
        status
      }
      keyType
      keyTypeId
      concurrentUsage
      installsPerSeat
      isUserBased
    }
  }
`;
export const BROWSEKEYSBYSOFTWAREID_QUERY = gql`
  query ($SoftwareId: ID!) {
    keysbySoftware(softwareId: $SoftwareId) {
      id
      keyUsage
      expiration
      licenseType
      licenseTypeId
      opsId
      org
      keyType
      keyTypeId
      concurrentUsage
      installsPerSeat
      isUserBased
    }
  }
`;
export const UPGRADEKEYS_LIST = gql`
  query ($SoftwareId: [ID!]) {
    keysbySoftwareList(softwareId: $SoftwareId) {
      id
      keyUsage
      licenseType
      softwareId
      opsId
      org
      isUserBased
    }
  }
`;
export const KEYSBYSOFTWARELIST_QUERY = gql`
  query ($SoftwareId: [ID!]) {
    keysbySoftwareList(softwareId: $SoftwareId) {
      id
      keyUsage
      expiration
      licenseType
      softwareId
      licenseTypeId
      keyString
      cost
      maintainanceCost
      opsId
      org
      software {
        name
      }
      attachedFilename {
        id
        uid
        name
        type
        size
        status
      }
      keyType
      keyTypeId
      concurrentUsage
      installsPerSeat
      isUserBased
    }
  }
`;

export const SAMTOSCCMUSAGE_QUERY = gql`
  query ($opsList: [Int]!) {
    samToSccmUsage(ops: $opsList)
  }
`;

export const PURCHASESBYSOFTWAREID_QUERY = gql`
  query ($softwareId: ID!) {
    purchaseListBySoftware(softwareId: $softwareId) {
      id
      ponumber
      dateOfPurchase
      costPerLicense
      licenseType
      keysPurchased
      softwareId
      purchaseOrderId
      opsId
      org
      lineItemId
    }
  }
`;

export const GETPO_QUERY = gql`
  query ($ID: ID!) {
    pObyId(id: $ID) {
      id
      dateOfPurchase
      ponumber
      cost
      vendor
      softwareDetails {
        poLineItemId
        softwareName
        licenseType
        isMachineBased
        isUserBased
        numberOfInstallsAllowed
        costPerLicense
        expiration
        keyType
        publisherId
        publisherName
        softwareId
        opsId
        org
        keyString
        installsPerSeat
        attachedFiles {
          name
        }
      }
    }
  }
`;

export const KEYUSE_QUERY = gql`
  query ($keyInput: PaginationDtoInputType) {
    keyUsage(pageInput: $keyInput) {
      totalCount
      pageSize
      keyUsageList {
        id
        keyDataId
        systemName
        userNt
        userStartDate
        userEndDate
        visible
        computers {
          id
          computerName
          keyUseId
        }
        userInfo {
          name
          email
          title
        }
      }
    }
  }
`;
// TODO: Azure Migration DONE - Add azureObjectId to query
export const AUTOSUGGEST_QUERY = gql`
  query ($nt: String) {
    autoSuggestByNt(ntLogon: $nt) {
      azureObjectId
      pidKey
      ntLogon
      name
      title
      email
    }
  }
`;

export const AUTOSUGGESTGROUP_QUERY = gql`
  query ($name: String) {
    autoSuggestByGroup(name: $name) {
      sid
      name
      description
    }
  }
`;

export const GLOBALSEARCH_QUERY = gql`
  query ($search: String!) {
    globalSearch(search: $search) {
      users {
        id
        userNt
        displayName
        userInfo {
          name
          email
          title
        }
      }
      softwareList {
        softwareId
        softwareName
        publisherName
      }
      userInfo {
        name
        email
        title
      }
    }
  }
`;

export const KEYSBYUSER_QUERY = gql`
  query ($nt: String!) {
    keysByUser(ntlogon: $nt) {
      userNt
      displayName
      id
      startDate
      endDate
      softwareName
      softwareId
      systemName
      opsId
      org
      color
      computers {
        id
        computerName
        keyUseId
      }
      licenseTypeId
      userInfo {
        name
        email
        title
      }
    }
  }
`;

export const SOFTWARESEARCH_QUERY = gql`
  query ($soft: String) {
    searchKeysBySoftware(software: $soft) {
      name
      id
      publisherId
      publisherName
      keys {
        licenseType
        org
        opsId
        keyUsage
        licenseTypeId
        expiration
      }
    }
  }
`;

export const AUTHCLAIMS_QUERY = gql`
  query {
    authClaims {
      id
      moduleId
      moduleName
      authTypeId
      authTypeName
      ops {
        id
        opsCenter1
        subRegion
      }
    }
  }
`;
//TODO: AzureMigration DONE - Add AzureObjectId
export const USERSACCESS_QUERY = gql`
  query {
    getUsersWithAccess {
      azureObjectId
      pidKey
      name
      email
      ntlogon
      color
      ops {
        id
        opsCenter1
        subRegion
      }
    }
  }
`;
//FIXME: Azure Migration DONE- Change authAccess to authAccesses
//TODO: AzureMigration DONE - Retain (ORIGINAL) change variable name to xUSERACCESSLIST_QUERY
export const xUSERACCESSLIST_QUERY = gql`
  query ($pidkey: Int!) {
    getUsersAccessList(pidkey: $pidkey) {
      pidKey
      name
      email
      color
      authAccesses {
        id
        ops {
          id
          subRegion
        }
        moduleId
        authTypeId
      }
    }
  }
`;
//FIXME: Azure Migration DONE- Change authAccess to authAccesses
//TODO: AzureMigration DONE - Add azureOjbectId field, Replace parameter pidkey with AzureObjectId and change parameter type to Guid (NEW Query)
export const USERACCESSLIST_QUERY = gql`
  query ($azureObjectId: Guid!) {
    getUsersAccessList(azureObjectId: $azureObjectId) {
      azureObjectId
      pidKey
      name
      email
      color
      authAccesses {
        id
        ops {
          id
          subRegion
        }
        moduleId
        authTypeId
      }
    }
  }
`;

export const GROUPACCESS_QUERY = gql`
  query {
    getGroupsWithAccess {
      id
      name
      sid
      description
      color
      ops {
        id
        opsCenter1
        subRegion
      }
    }
  }
`;
//FIXME: Azure Migration DONE - Change authAccess to authAccesses
export const GROUPACCESSLIST_QUERY = gql`
  query ($groupId: Int!) {
    getGroupAccessList(groupId: $groupId) {
      id
      authAccesses {
        id
        ops {
          id
          subRegion
        }
        moduleId
        authTypeId
      }
      name
      sid
      description
      color
    }
  }
`;

export const SOFTWAREPUBLISHERLIST_QUERY = gql`
  query {
    softwarePublisherList {
      id
      name
      publisherId
      publisherName
      version
      color
    }
  }
`;

export const SCCMSEARCH_QUERY = gql`
  query ($name: String) {
    autoSuggestBySccmName(name: $name) {
      collectionId
      collectionName
      requireApproval
      applicationName
      appModelId
    }
  }
`;

export const KEYSBYEXP_QUERY = gql`
  query ($opsList: [Int]!) {
    getExpiringKeys(opsList: $opsList) {
      id
      softwareName
      version
      expirationDate
      licenseType
      seatsAvailable
      seatsInUse
      keyType
      isMachineBased
      isUserBased
      softId
    }
  }
`;

export const RECENTPOS_QUERY = gql`
  query {
    recentPurchases {
      id
      ponumber
      dateOfPurchase
      cost
    }
  }
`;

export const ORGLIST_QUERY = gql`
  query {
    orgList {
      id
      opsCenter1
      subRegion
    }
  }
`;
export const APPASSIGNMENTS_QUERY = gql`
  query ($opsIds: [Int]) {
    appAssignmentListByOrg(opsIds: $opsIds) {
      id
      licenseId
      publisher {
        id
        name
      }
      software {
        id
        name
        version
      }
      sccmName
      appModelId
      collectionId
      collectionName
      collectionId
      color
      ops {
        id
        subRegion
      }
    }
  }
`;
