import React, { useEffect } from "react";
import AppLayout from "./Shared/AppLayout";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AUTHCLAIMS_QUERY } from "./Apollo/Queries";
import { graphql } from "react-apollo";
import { claimRouter } from "./ClaimsRouter";
import Loading from "./Shared/Loading/Loading";
import NotFoundPage from "./Shared/Errors/404";
import useGlobal from "./GlobalState/Store";
import configData from "./config.json";

const App = (props) => {
  const { data } = props;
  const globalActions = useGlobal()[1];
  const { addAuthClaims } = globalActions;
  const { authClaims } = data;
  useEffect(() => {
    addAuthClaims(authClaims);
  }, [authClaims]);
  if (data.loading) return <Loading error={false} />;
  if (data.error) return <Loading error={true} />;

  return (
    <Router basename={"/" + configData.Site.URL}>
      <AppLayout claims={authClaims}>
        <Switch>
          {claimRouter(authClaims)}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </AppLayout>
    </Router>
  );
};

export default graphql(AUTHCLAIMS_QUERY)(App);
