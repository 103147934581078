import React, { useState } from "react";
import ModalPopup from "../../ModalPopup/ModalPopup";
import Spinner from "../../../Shared/Spinner/Spinner";
import { Form, Avatar, Select, message, Radio } from "antd";
import { colors, formItemLayout } from "../../../Shared/Helpers";
import SoftwareCollectionSearchWithData from "./SoftwareCollectionSearch";
import { graphql } from "react-apollo";
import { UPDATEASSIGNMENT_MUTATION } from "../../../Apollo/Mutations";

const UpdateAssignments = (props) => {
  const { record } = props;
  const [loading, setloading] = useState(false);
  const [visible, setvisible] = useState(false);
  const [rec, setRec] = useState({});
  const [migrate, setMigrate] = useState(false);

  const { sccmName, sccmCollection } = rec;
  const selectStyle = {
    color: "whitesmoke",
  };
  const updateSccmInfo = (_sccm, coll, sccmAppId) => {
    setRec({
      ...rec,
      sccmName: sccmAppId,
      sccmCollection: coll,
    });
  };
  const startLoading = () => {
    setloading(true);
  };
  const stopLoading = () => {
    setloading(false);
  };
  const onSubmit = () => {
    if (sccmName && sccmCollection) {
      if (
        parseInt(sccmName) === record.appModelId &&
        sccmCollection === record.collectionId
      ) {
        message.warn("Duplicate Sccm Info.");
      } else {
        startLoading();
        props
          .mutate({
            variables: {
              update: {
                id: record.id,
                appModelId: sccmName,
                collectionId: sccmCollection,
                migrateUsers: migrate,
              },
            },
          })
          .then(({ data }) => {
            stopLoading();
            onModalClose();
          })
          .catch((error) => {
            if (error.networkError) {
              if (error.networkError.result.errors[0].message !== null) {
                message.error(error.networkError.result.errors[0].message);
              }
            } else {
              message.error("An error occured!");
            }

            stopLoading();
          });
      }
    } else {
      message.info("Please fill out all fields.");
    }
  };
  const migrateChange = (e) => {
    setMigrate(e.target.value);
  };
  const onModalClose = () => {
    handleCancel();
  };
  const handleCancel = () => {
    setvisible(false);
  };
  const showModal = () => {
    setvisible(true);
  };
  return (
    <div>
      <ModalPopup
        header="Update Assignment"
        buttonText="Update"
        name="Update"
        submit={onSubmit}
        onModalClose={onModalClose}
        showModal={showModal}
        handleCancel={handleCancel}
        size="small"
        type="primary"
        visible={visible}
        ghost={true}
        icon="edit"
      >
        <Spinner loading={loading}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              maxHeight: 80,
              marginBottom: 20,
            }}
          >
            <div style={{ marginRight: 10, paddingTop: 2 }}>
              <Avatar
                size="medium"
                style={{
                  backgroundColor: colors[record.color],
                }}
              >
                {`${record.ops.subRegion[0].toUpperCase()}`}
              </Avatar>
            </div>
            <div>
              <h3 style={{ color: "whitesmoke", marginBottom: 0 }}>
                {record.ops.subRegion}
              </h3>
              <h3 style={{ color: "whitesmoke", marginBottom: 0 }}>
                {record.software.name}
              </h3>
              <p>{record.publisher.name}</p>
            </div>
          </div>
          <Form {...formItemLayout}>
            <Form.Item label="Sccm Name">
              <Select
                showArrow={false}
                style={selectStyle}
                disabled
                value={record.sccmName}
              />
            </Form.Item>
            <Form.Item label="Sccm Collection">
              <Select
                showArrow={false}
                style={selectStyle}
                disabled
                value={record.collectionName}
              />
            </Form.Item>
          </Form>
          <h3
            style={{ color: "whitesmoke", textAlign: "center", minWidth: 140 }}
          >
            New Sccm Info
          </h3>
          <hr />
          <div style={{ marginTop: 20 }}>
            <Form {...formItemLayout}>
              <Form.Item label="Migrate users" required>
                <Radio.Group
                  defaultValue={false}
                  buttonStyle="solid"
                  onChange={migrateChange}
                  value={migrate}
                >
                  <Radio.Button value={true}>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Form>
            <SoftwareCollectionSearchWithData updateParent={updateSccmInfo} />
          </div>
        </Spinner>
      </ModalPopup>
    </div>
  );
};

const UpdateAssignment = graphql(UPDATEASSIGNMENT_MUTATION)(UpdateAssignments);
export default UpdateAssignment;
