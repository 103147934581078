import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { Checkbox } from "antd";
import { changeOptions } from "./PermissionFunctions";
import { radioStyle } from "./PermissionOptions";

const PermissionItem = (props) => {
  const KeyOptions = [
    { label: "Full Access", value: 11, disabled: false },
    { label: "Read", value: 7, disabled: true },
    { label: "Create/Remove", value: 8, disabled: false },
    { label: "Update", value: 9, disabled: false },
    //{ label: "Remove", value: 10, disabled: false },
  ];
  const PoOptions = [
    { label: "Full Access", value: 11, disabled: false },
    { label: "Read", value: 7, disabled: true },
    { label: "Create", value: 8, disabled: false },
  ];
  const PoInfoOptions = [{ label: "Full Access", value: 11, disabled: false }];
  const ReportOptions = [{ label: "Full Access", value: 11, disabled: false }];
  const AdminOptions = [
    { label: "Permissions", value: 12, disabled: false },
    { label: "PO administration", value: 13, disabled: false },
    { label: "API tokens", value: 14, disabled: false },
    { label: "AppAssignments", value: 16, disabled: false },
    { label: "Key Details", value: 15, disabled: false },
  ];

  const AuthTypes = [
    { id: 7, name: "Read" },
    { id: 8, name: "Create" },
    { id: 9, name: "Update" },
    //{ id: 10, name: "Delete" },
    { id: 11, name: "FullAccess" },
    { id: 12, name: "Permissions" },
    { id: 13, name: "POAdministration" },
    { id: 14, name: "APITokens" },
    { id: 15, name: "KeyRead" },
    { id: 16, name: "AppAssignment" },
  ];

  const Modules = [
    { id: 1, name: "dashboard", opt: "DashboardOptions" },
    { id: 2, name: "pOs", opt: "PoOptions" },
    { id: 3, name: "keys", opt: "KeyOptions" },
    { id: 4, name: "PoInfo", opt: "PoInfoOptions" },
    { id: 5, name: "Search", opt: "SearchOptions" },
    { id: 6, name: "reporting", opt: "ReportOptions" },
    { id: 8, name: "administration", opt: "AdminOptions" },
  ];

  const permissions = {
    pOs: [],
    keys: [],
    poInfo: [],
    reporting: [],
    administration: [],
  };
  const options = {
    PoOptions,
    KeyOptions,
    PoInfoOptions,
    ReportOptions,
    AdminOptions,
  };
  const [state, setState] = useState({ permissions, options });
  const { org, updateParent, edit } = props;
  useEffect(() => {
    if (org && updateParent) {
      setState({ ...state, permissions: { pOs: [7], keys: [7] } });
    }
    if (edit) {
      edit.forEach((el) => {
        if (el.moduleId === 8) {
          const administration = state.permissions.administration;
          administration.push(el.authTypeId);
          setState({
            ...state,
            permissions: {
              ...state.permissions,
              administration,
            },
          });
        } else {
          let auth = AuthTypes.find((i) => i.id === el.authTypeId);
          let module = Modules.find((i) => i.id === el.moduleId);
          if (module.id === 2 || module.id === 3 || module.id === 6) {
            checkBoxSeed([auth.id], state.options[module.opt], module.name);
          }
        }
      });
    }
  }, []);
  useEffect(() => {
    if (org && updateParent) {
      updateParent(state.permissions, org);
    }
  }, [state]);

  const checkBoxSeed = (val, opts, name) => {
    var fullAccess = val.find(function (ele) {
      return ele === 11;
    });
    if (
      fullAccess ||
      (!fullAccess &&
        val.length + 1 === opts.length &&
        state.permissions[name] &&
        val.length !== 0)
    ) {
      const property = state.permissions[name];
      property.push(11);
      setState({
        ...state,
        permissions: { ...state.permissions, [name]: property },
      });
      changeOptions(opts, 11, true);
    } else {
      const property = state.permissions[name];
      property.push(val[0]);
      property.push(7);
      const newVals = [...new Set(property)];
      setState({
        ...state,
        permissions: { ...state.permissions, [name]: newVals },
      });
      changeOptions(opts, 11, false);
    }
  };

  const checkBoxChange = (v, opts, name) => {
    const val = [...new Set(v)];
    var fullAccess = val.find(function (ele) {
      return ele === 11;
    });
    if (
      fullAccess ||
      (!fullAccess &&
        val.length + 1 === opts.length &&
        state.permissions[name] &&
        val.length !== 0)
    ) {
      setState({
        ...state,
        permissions: { ...state.permissions, [name]: [11] },
      });
      changeOptions(opts, 11, true);
    } else {
      val.push(7);
      const newVals = [...new Set(val)];
      setState({
        ...state,
        permissions: { ...state.permissions, [name]: newVals },
      });
      changeOptions(opts, 11, false);
    }
  };

  return (
    <Form layout="horizontal">
      <Form.Item label="Site access">
        <Checkbox.Group
          style={radioStyle}
          options={[
            {
              label: "Read",
              value: 7,
            },
          ]}
          value={[7]}
          disabled
        />
      </Form.Item>
      <Form.Item label="POs">
        <Checkbox.Group
          style={radioStyle}
          options={state.options.PoOptions}
          onChange={(val) =>
            checkBoxChange(val, state.options.PoOptions, "pOs")
          }
          value={state.permissions.pOs}
        />
      </Form.Item>
      <Form.Item label="Keys">
        <Checkbox.Group
          style={radioStyle}
          options={state.options.KeyOptions}
          onChange={(val) =>
            checkBoxChange(val, state.options.KeyOptions, "keys")
          }
          value={state.permissions.keys}
        />
      </Form.Item>
      <Form.Item label="Reporting">
        <Checkbox.Group
          style={radioStyle}
          options={state.options.ReportOptions}
          onChange={(val) =>
            checkBoxChange(val, state.options.ReportOptions, "reporting")
          }
          value={state.permissions.reporting}
        />
      </Form.Item>

      <Form.Item label="Administration">
        <Checkbox.Group
          style={{ lineHeight: "38px", width: 160, marginTop: 8 }}
          options={state.options.AdminOptions}
          onChange={(val) =>
            setState({
              ...state,
              permissions: { ...state.permissions, administration: val },
            })
          }
          value={state.permissions.administration}
        />
      </Form.Item>
    </Form>
  );
};

export default PermissionItem;
