import React, { useState } from "react";
import { Button, Modal, message } from "antd";
import { graphql } from "react-apollo";
import { USERSACCESS_QUERY } from "../../../../Apollo/Queries";
import { REMOVEUSERACCESS_MUTATION } from "../../../../Apollo/Mutations";

const { confirm } = Modal;

//TODO: Azure Migration DONE - Replace pidkeys with azureObjectIds
const DeleteUsers = (props) => {
  const [loading, setLoading] = useState(false);
  function showConfirm() {
    const records = () => {
      return props.keys.length > 1 ? "these users?" : "this user?";
    };
    confirm({
      title: `Are you sure you want to remove ${records()}`,
      content: "Click OK to continue.",
      onOk() {
        if (props.keys.length > 0) {
          const azureObjectIds = props.keys;
          setLoading(true);
          props
            .mutate({
              variables: {
                azureObjectIds,
              },
              //FIXME: Azure Migration DONE - Remove update store. It is preventing refetch after REMOVEUSERACCESS_MUTATION
              /* update: (store, { data: { addUserAccess } }) => {
                let Users = store.readQuery({ query: USERSACCESS_QUERY });
                for (let i = 0; i < azureObjectIds.length; i++) {
                  Users.getUsersWithAccess.splice(
                    Users.getUsersWithAccess.findIndex(matchesEl),
                    1
                  );

                  function matchesEl(el) {
                    return el.azureObjectId === azureObjectIds[i];
                  }
                }
                store.writeQuery({ query: USERSACCESS_QUERY, data: Users });
              }, */
              refetchQueries: [
                {
                  query: USERSACCESS_QUERY
                }
              ]
            })
            .then(({ data }) => {
              setLoading(false);
              props.setToDelete([]);
            })
            .catch((error) => {
              setLoading(false);
              if (error.networkError) {
                if (error.networkError.result.errors[0].message !== null) {
                  message.error(error.networkError.result.errors[0].message);
                }
              } else {
                message.error("An Error occured. Please try again.");
              }
            });
        } else {
          message.error("No users selected.");
        }
      },
    });
  }

  return (
    <div style={{ marginBottom: 15 }}>
      <Button loading={loading} type="danger" onClick={showConfirm}>
        {loading ? "Removing" : "Remove"}
      </Button>
    </div>
  );
};

const DeleteUser = graphql(REMOVEUSERACCESS_MUTATION)(DeleteUsers);

export default DeleteUser;
