import React, { useState, useEffect } from "react";
import {
  KEYSBYUSER_QUERY,
  BROWSELIST_QUERY,
  KEYSBYSOFTWAREID_QUERY,
} from "../../Apollo/Queries";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import { Popconfirm, Table, Button, DatePicker, message, Tooltip, Avatar } from "antd";
import Spinner from "../../Shared/Spinner/Spinner";
import { Query, graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import moment from "moment";
import {
  UPDATE_ENDDATE_MUTATION,
  REMOVE_KEYUSE_MUTATION,
  REMOVE_KEYUSECOMPUTER_MUTATION,
} from "../../Apollo/Mutations";
import AssignKeyByUser from "./AssignKeyByUser";
import { useMediaQuery } from "react-responsive";
import SelectComputer from "./SelectComputer";
import useGlobal from "../../GlobalState/Store";
import { colors } from "../../Shared/Helpers";

const ManageKeysByUser = (props) => {
  const ntId = props.match.params.id;
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [endDate, setEndDate] = useState(null);

  const [authState, setauthState] = useState({
    createDelete: false,
    claims: [],
  });
  const globalState = useGlobal()[0];
  const { authClaims } = globalState;

  useEffect(() => {
    if (authClaims.length > 0) {
      var claims = authClaims.filter(
        (i) => (i.moduleId === 3 && i.authTypeId !== 7) || i.ops.id === 11
      );
      let allAuth = authClaims.filter((i) => i.ops.id === 11);
      let create = [];
      create = claims.filter(
        (i) => (i.moduleId === 3 && i.authTypeId === 11) || i.authTypeId === 8
      );
      if (create.length > 0 || allAuth.length > 0) {
        setauthState({ createDelete: true, claims });
      } else {
        setauthState({ createDelete: false, claims });
      }
    }
  }, [authClaims]);

  const { createDelete } = authState;

  const isTablet = useMediaQuery({
    query: "(max-width: 830px)",
  });
  const isMobil = useMediaQuery({
    query: "(max-width: 665px)",
  });

  const endDateSelect = (a, b) => {
    setEndDate(b);
  };

  const editClick = (key) => {
    setEditingKey(key);
  };
  const isEditing = (record) => record.id === editingKey;

  const deleteRecord = (rec) => {
    setLoading(true);
    props
      .removeKey({
        variables: {
          id: rec.id,
          keyDataId: rec.licenseTypeId,
        },
        refetchQueries: [
          {
            query: BROWSELIST_QUERY,
          },
          {
            query: KEYSBYSOFTWAREID_QUERY,
            variables: {
              SoftwareId: rec.softwareId,
            },
          },
          {
            query: KEYSBYUSER_QUERY,
            variables: {
              nt: ntId,
            },
          },
        ],
      })
      .then(({ data }) => {
        setLoading(false);
        setEditingKey("");
      })
      .catch((error) => {
        setLoading(false);
        message.error("An Error occured. Please try again.");
      });
  };

  const removeSystem = (id, nt, clear, len, rec) => {
    if (!id || id.length !== 0) {
      setLoading(true);
      props
        .removeSystem({
          variables: {
            id: id,
            keyDataId: rec.licenseTypeId,
          },
          refetchQueries: [
            {
              query: BROWSELIST_QUERY,
            },
            {
              query: KEYSBYSOFTWAREID_QUERY,
              variables: {
                SoftwareId: rec.softwareId,
              },
            },
            /* {
              query: KEYUSE_QUERY,
              variables: {
                licenseId: rec.licenseTypeId,
              },
            }, */
            {
              query: KEYSBYUSER_QUERY,
              variables: {
                nt: ntId,
              },
            },
          ],
        })
        .then(({ data }) => {
          setLoading(false);
          setEditingKey("");
          return len > 1 ? clear() : null;
        })
        .catch((error) => {
          setLoading(false);
          message.error("An Error occured. Please try again.");
        });
    } else {
      message.info("Please select computer name.");
    }
  };

  const updateSubmit = (rec) => {
    const id = rec.id;
    const date = rec.endDate;
    if (endDate !== date) {
      setLoading(true);
      props
        .updateKey({
          variables: {
            keyuse: {
              id: id,
              userEndDate: endDate || null,
            },
            keyDataId: rec.licenseTypeId,
          },
          update: (store, { data: { updateKey } }) => {
            const keyUse = store.readQuery({
              query: KEYSBYUSER_QUERY,
              variables: {
                nt: ntId,
              },
            });
            keyUse.keysByUser.find((i) => i.id === id).endDate = endDate;

            store.writeQuery({
              query: KEYSBYUSER_QUERY,
              variables: {
                nt: ntId,
              },
              data: keyUse,
            });
          },
        })
        .then(({ data }) => {
          setLoading(false);
          setEditingKey("");
        })
        .catch((error) => {
          setLoading(false);
          message.error("An Error occured. Please try again.");
        });
    } else {
      setEditingKey("");
    }
  };

  const checkAuth = (rec, type) => {
    let claim = [];
    if (authState.claims.length > 0) {
      if (type) {
        claim = authState.claims.filter(
          (i) =>
            (i.ops.id === rec.opsId &&
              i.moduleId === 3 &&
              i.authTypeId === 11) ||
            (i.ops.id === rec.opsId &&
              i.moduleId === 3 &&
              i.authTypeId === 8) ||
            i.ops.id === 11
        );

        if (claim.length > 0) {
          return true;
        }
        return false;
      } else {
        claim = authState.claims.filter(
          (i) =>
            (i.ops.id === rec.opsId &&
              i.moduleId === 3 &&
              i.authTypeId === 11) ||
            (i.ops.id === rec.opsId &&
              i.moduleId === 3 &&
              i.authTypeId === 9) ||
            i.ops.id === 11
        );
        if (claim.length > 0) {
          return true;
        }
        return false;
      }
    }
  };
  const columns = [
    {
      title: "Software Name",
      dataIndex: "softwareName",
      key: "softwareName",
      width: 200,
      sorter: (a, b) =>
        a.systemName.localeCompare(b.systemName, "en", { numeric: true }),
      render: (text, rec) => {
        const avatar = (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              maxHeight: 200,
            }}
          >
            <div style={{ marginRight: 10, paddingTop: 2 }}>
              <Avatar
                style={{
                  backgroundColor: colors[rec.color],
                }}
              >
                {`${text[0].toUpperCase()}${text[1].toUpperCase()}`}
              </Avatar>
            </div>
            <div>
              <h3 style={{ color: "whitesmoke", marginBottom: 0 }}>{text}</h3>
              <p>{rec.org}</p>
            </div>
          </div>
        );
        return <div>{avatar}</div>;
      },
    },
    {
      title: isMobil && editingKey !== "" ? null : "System",
      //width: isMobil && editingKey !== "" ? 1 : null,
      dataIndex: "systemName",
      key: "systemName",
      width: editingKey ? (isTablet ? 170 : 240) : 200,
      render: (text, record) => {
        if (record.computers && record.computers.length > 0) {
          return (
            <SelectComputer
              removeSystem={removeSystem}
              record={record}
              editingKey={editingKey}
              //Delete={createDelete}
              Update={checkAuth(record, false)}
            />
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Assigned date",
      dataIndex: "startDate",
      width: 150,
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: editingKey ? (isMobil ? 200 : 300) : 150,
      render: (text, record) => {
        const editable = isEditing(record);
        const date = moment().format("MM/DD/YYYY");
        return editable ? (
          <div
            style={{
              display: "inline",
            }}
          >
            <DatePicker
              style={{
                width: 130,
                marginBottom: 5,
              }}
              defaultValue={text ? moment(text, "MM/DD/YYYY") : null}
              format={"MM/DD/YYYY"}
              onChange={endDateSelect}
              loading
              autoFocus
            />
            <span
              style={{
                marginLeft: 5,
              }}
            >
              <Tooltip title="Save">
                <Button
                  icon={<CheckOutlined />}
                  onClick={() => updateSubmit(record)}
                  size="small"
                  shape="circle"
                  style={{
                    color: "rgba(0,255,0,.5)",
                    borderColor: "rgba(0,255,0,.5)",
                    marginRight: 5,
                  }}
                  ghost
                />
              </Tooltip>
              <Tooltip title="Cancel">
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => setEditingKey("")}
                  size="small"
                  style={{
                    marginLeft: 2,
                    color: "rgba(255,0,0,.8)",
                    borderColor: "rgba(255,0,0,.8)",
                  }}
                  ghost
                />
              </Tooltip>
            </span>
          </div>
        ) : (
          <div
            style={{
              color:
                text < date ? "red" : text === "No End" ? "whitesmoke" : null,
            }}
          >
            {text ? text : "No End"}
            <span style={{ float: "right" }}>
              <Tooltip visible={false} title="Edit end date">
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  disabled={editingKey !== "" || !checkAuth(record, true)}
                  onClick={() => editClick(record.id)}
                  size="small"
                  ghost
                ></Button>
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      width: isMobil || isTablet ? 35 : 130,
      render: (t, r) => (
        <div>
          <Popconfirm
            title="Are you sure you want to remove this item?"
            disabled={editingKey !== "" || !createDelete}
            onConfirm={() => deleteRecord(r)}
          >
            <Tooltip visible={false} title="Remove key assignment">
              <Button
                type="danger"
                icon={<UserDeleteOutlined />}
                disabled={editingKey !== "" || !checkAuth(r, true)}
                size="small"
                ghost
              >
                {isMobil || isTablet ? "" : "Remove"}
              </Button>
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const columnsHelper = () => {
    if (isTablet && !isMobil) {
      return columns.filter((i) => i.key !== "startDate");
    } else if (isTablet && isMobil) {
      return columns.filter(
        (i) => i.key !== "systemName" && i.key !== "startDate"
      );
    } else {
      return columns;
    }
  };

  return (
    <div>
      <Spinner loading={loading}>
        <div
          style={{
            padding: "1em",
          }}
        >
          <Query
            query={KEYSBYUSER_QUERY}
            variables={{
              nt: ntId,
            }}
          >
            {({ data, loading, error }) => {
              if (loading) return <p>Loading...</p>;
              if (error) return <p>ERROR</p>;
              if (data && data.keysByUser.length > 0) {
                console.log(data);
                const name = data.keysByUser[0].displayName.split(",");
                return (
                  <div>
                    <AssignKeyByUser
                      ntId={ntId}
                      create={createDelete}
                      claims={authState.claims}
                    />
                    <div className="ant-descriptions-title">
                      {`${name[1]} ${name[0]}`}
                      <span style={{ paddingLeft: ".5em" }}>
                        <Tooltip
                          key={`${ntId}-tooltip`}
                          placement={isMobil ? "bottom" : "right"}
                          title={
                            <div>
                              {/* <p>{data.keysByUser[0].userInfo.title}</p> */}
                              <p>Username: {data.keysByUser[0].userNt}</p>
                            </div>
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    </div>
                    <Table
                      rowKey={(rec) => rec.id}
                      columns={columnsHelper()}
                      dataSource={data.keysByUser}
                      pagination={true}
                      size={isMobil || isTablet ? "small" : "default"}
                    />
                  </div>
                );
              } else {
                return (
                  <h3
                    style={{
                      paddingTop: "2em",
                      color: "whitesmoke",
                      textAlign: "center",
                    }}
                  >
                    All records for this user have been removed. User no longer
                    exists in SAM.
                  </h3>
                );
              }
            }}
          </Query>
        </div>
      </Spinner>

      <Button
        style={{ margin: "20px 20px 20px 20px" }}
        onClick={() => props.history.goBack()}
        type="primary"
      >
        <LeftOutlined />
        Go back
      </Button>
    </div>
  );
};

const ManageKeyByUser = compose(
  graphql(UPDATE_ENDDATE_MUTATION, {
    name: "updateKey",
  }),
  graphql(REMOVE_KEYUSE_MUTATION, {
    name: "removeKey",
  }),
  graphql(REMOVE_KEYUSECOMPUTER_MUTATION, {
    name: "removeSystem",
  })
)(ManageKeysByUser);

export default ManageKeyByUser;
