import React, { useState } from "react";
import { LeftOutlined } from '@ant-design/icons';
import { Descriptions, Collapse, Tag, Button, Progress, message } from "antd";
import { GETPO_QUERY } from "../../../Apollo/Queries";
import { Query } from "react-apollo";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import useGlobal from "../../../GlobalState/Store";
import StringKey from "./StringKey";
import { downloadEnvCheck } from "../../../Apollo/Apollo";

const { Panel } = Collapse;
const linkRef = React.createRef();

const FullPO = (props) => {
  const [dlState, setdlState] = useState({
    percent: 0,
    status: "active",
    display: false,
  });
  const { percent, status, display } = dlState;
  const poId = props.match.params.id;
  const globalState = useGlobal()[0];
  const {
    authClaims,
    authState: { rawToken, accessToken },
  } = globalState;
  const isMobil = useMediaQuery({
    query: "(max-width: 665px)",
  });
  const keyInfoHelper = (s) => {
    const superAdmin = authClaims.filter((i) => i.ops.id === 11);
    const keyClaim = authClaims.filter(
      (i) => i.authTypeId === 15 && i.ops.id === s.opsId
    );
    if (superAdmin.length > 0 || keyClaim.length > 0) {
      if (s.keyType === "String" && s.keyString) {
        return (
          <Descriptions.Item label="Key String">
            <StringKey stringKey={s.keyString} />
          </Descriptions.Item>
        );
      }
      else if (s.keyType === "File" || s.keyType === "RegKey") {
        const downloadRoot = downloadEnvCheck();
        return (
          <Descriptions.Item label="Download Key">
            {s.attachedFiles.map((rec, i) => {
              return (
                <div key={i}>
                  <a
                    //ref={linkRef}
                    onClick={() =>
                      downloadClick(
                        `${downloadRoot}/${s.poLineItemId}`,
                        rec.name
                      )
                    }
                  >
                    {rec.name}
                  </a>
                  <br />
                </div>
              );
            })}
            {display ? (
              <div style={{ width: 170 }}>
                <Progress percent={percent} size="small" status={status} />
              </div>
            ) : null}
            <a ref={linkRef} />
          </Descriptions.Item>
        );
      }
    }
  };
  const downloadClick = (url, name) => {
    setdlState({ ...dlState, percent: 25, display: true });
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: rawToken ? `Bearer ${rawToken}` : "",
        Access: accessToken,
      },
    })
      .then(async (res) => {
        setdlState({ ...dlState, percent: 50, display: true });
        return await res.blob();
      })
      .then((blob) => {
        setdlState({ ...dlState, percent: 75, display: true });
        const href = window.URL.createObjectURL(blob);
        const a = linkRef.current;
        a.download = name;
        a.href = href;
        setdlState({ ...dlState, percent: 99, display: true });
        a.click();
        a.href = "";
        setdlState({ ...dlState, percent: 100, status: null, display: true });
      })
      .catch((err) => {
        setdlState({
          ...dlState,
          percent: 99,
          status: "exception",
          display: true,
        });
        message.error("An error occured!");
        console.error(err);
      });
  };
  const collapse = (softwareDetails) => {
    return (
      <Collapse style={{ maxWidth: 500 }} accordion>
        {softwareDetails.map((s) => {
          return (
            <Panel key={s.softwareId} header={s.softwareName}>
              <Descriptions bordered={true} column={1}>
                <Descriptions.Item label="Org">{s.org}</Descriptions.Item>
                <Descriptions.Item label="License Type">
                  {s.licenseType}
                </Descriptions.Item>
                <Descriptions.Item label="Install Type">
                  {s.isUserBased ? "User" : "Machine"}
                </Descriptions.Item>
                <Descriptions.Item label="Number of seats">
                  {s.numberOfInstallsAllowed === null
                    ? "Unlimited"
                    : s.numberOfInstallsAllowed}
                </Descriptions.Item>
                <Descriptions.Item label="Installs per seat">
                  {s.installsPerSeat}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    s.numberOfInstallsAllowed === null
                      ? "Cost"
                      : "Line item cost"
                  }
                >
                  {`$ ${Number(s.costPerLicense).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}`}
                </Descriptions.Item>
                {s.expiration ? (
                  <Descriptions.Item label="Expiration Date">
                    <span>{s.expiration}</span>
                  </Descriptions.Item>
                ) : null}
                <Descriptions.Item label="Key Type">
                  {s.keyType}
                </Descriptions.Item>
                {keyInfoHelper(s)}
              </Descriptions>
            </Panel>
          );
        })}
      </Collapse>
    );
  };

  return (
    <div style={{ padding: "1em" }}>
      <Query
        query={GETPO_QUERY}
        variables={{
          ID: poId,
        }}
      >
        {({ data, loading, error }) => {
          if (loading) return <div className="text-primary">Loading...</div>;
          if (error) return <p>ERROR</p>;
          const {
            pObyId: { ponumber, dateOfPurchase, vendor, softwareDetails },
          } = data;
          return (
            <div>
              <Descriptions title="Full PO Summary" bordered column={1}>
                <Descriptions.Item label="Order number">
                  <span className="white-text">{ponumber}</span>
                </Descriptions.Item>
                <Descriptions.Item label="Purchase Date">
                  <span className="white-text">
                    {moment(dateOfPurchase).format("l")}
                  </span>
                </Descriptions.Item>
                {/* <Descriptions.Item label="Cost">
                  <span className="white-text">{`$ ${Number(
                    cost
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2
                  })}`}</span>
                </Descriptions.Item> */}
                <Descriptions.Item label="Vendor">
                  <span className="white-text">{vendor}</span>
                </Descriptions.Item>
                <Descriptions.Item label="Publisher/s">
                  <div style={{ display: "inline" }}>
                    {softwareDetails.map((p) => {
                      return <Tag key={p.publisherId}>{p.publisherName}</Tag>;
                    })}
                  </div>
                </Descriptions.Item>
                {isMobil ? null : (
                  <Descriptions.Item label="Software">
                    {collapse(softwareDetails)}
                  </Descriptions.Item>
                )}
              </Descriptions>
              {isMobil ? collapse(softwareDetails) : null}
            </div>
          );
        }}
      </Query>
      <Button
        style={{ marginTop: 20 }}
        onClick={() => props.history.goBack()}
        type="primary"
      >
        <LeftOutlined />
        Go back
      </Button>
    </div>
  );
};

export default FullPO;
