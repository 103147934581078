import React, { useState } from "react";
import Spinner from "../../Shared/Spinner/Spinner";
import ModalPopup from "../ModalPopup/ModalPopup";
import { Form } from "antd";
import { DatePicker, Select, Spin, message } from "antd";
import { ASSIGN_KEYS_MUTATION } from "../../Apollo/Mutations";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

import {
  SOFTWARESEARCH_QUERY,
  KEYUSE_QUERY,
  BROWSELIST_QUERY,
  KEYSBYSOFTWAREID_QUERY,
  KEYSBYUSER_QUERY,
} from "../../Apollo/Queries";
import moment from "moment";
import { formItemLayout } from "../../Shared/Helpers";

const initialState = {
  software: undefined,
  license: [],
  selectedLicense: undefined,
  computer: null,
  startDate: moment(),
  endDate: null,
};

const AssignKeyByUsers = (props) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({ ...initialState });

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
    setState({ ...initialState });
  };

  const onSubmit = () => {
    if (state.software && state.startDate && state.selectedLicense) {
      setLoading(true);
      props
        .mutate({
          variables: {
            keyuse: {
              userNt: props.ntId,
              systemName: state.computer,
              userStartDate: state.startDate,
              userEndDate: state.endDate ? state.endDate : null,
              keyDataId: state.selectedLicense,
              visible: true,
            },
            keyDataId: state.selectedLicense,
          },
          refetchQueries: [
            {
              query: KEYSBYUSER_QUERY,
              variables: {
                nt: props.ntId,
              },
            },
            {
              query: BROWSELIST_QUERY,
            },
            {
              query: KEYSBYSOFTWAREID_QUERY,
              variables: {
                SoftwareId: state.software,
              },
            },
          ],
        })
        .then(({ data }) => {
          setLoading(false);
          handleCancel();
        })
        .catch((error) => {
          setLoading(false);
          message.error("An Error occured. No seats available!");
        });
    } else {
      message.error("Please fill out all fields");
    }
  };

  const onSearchChange = (e) => {
    if (e) {
      setTimeout(() => {
        props.data.refetch({
          soft: e,
        });
      }, 500);
    }
  };
  const onchangeSoft = (e) => {
    setState({
      ...state,
      software: e,
      license: props.data.searchKeysBySoftware.find((i) => i.id === e),
    });
    if (e == null) {
      setState({ ...initialState });
    }
  };

  const onChangeStart = (a, b) => {
    setState({ ...state, startDate: b === "" ? null : b });
  };

  const onChangeEnd = (a, b) => {
    setState({ ...state, endDate: b });
  };
  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const softwareListHelper = () => {
    return props.data.searchKeysBySoftware
      ? props.data.searchKeysBySoftware.map((a) => {
          return (
            <Select.Option key={a.id}>
              {`${a.publisherName} ${a.name}`}
            </Select.Option>
          );
        })
      : null;
  };
  const licenseListHelper = () => {
    return state.software && state.license
      ? state.license.keys.map((a) => {
          let claim = [];
          claim = props.claims.filter(
            (i) =>
              (i.ops.id === a.opsId &&
                i.moduleId === 3 &&
                i.authTypeId === 11) ||
              (i.ops.id === a.opsId &&
                i.moduleId === 3 &&
                i.authTypeId === 8) ||
              i.ops.id === 11
          );
          if (claim.length > 0)
            return (
              <Select.Option key={a.licenseTypeId}>
                {`${a.org}; ${a.licenseType}; ${a.keyUsage}`}
              </Select.Option>
            );
        })
      : null;
  };

  const onSelectLic = (r) => {
    setState({
      ...state,
      selectedLicense: r,
    });
  };
  return (
    <div style={{ marginBottom: 15 }}>
      <ModalPopup
        type="outlined"
        header="Assign Key"
        icon="user-add"
        buttonText="Assign new key"
        name="Submit"
        disable={props.create ? false : true}
        notFoundContent={loading ? <Spin size="small" /> : null}
        showModal={showModal}
        handleCancel={handleCancel}
        visible={visible}
        submit={onSubmit}
      >
        <Spinner loading={loading}>
          <Form>
            <Form.Item autoFocus required label="Software" {...formItemLayout}>
              <Select
                showArrow={false}
                autoFocus
                notFoundContent={null}
                filterOption={false}
                showSearch
                placeholder="Search software"
                allowClear
                value={state.software}
                onChange={onchangeSoft}
                onSearch={onSearchChange}
              >
                {softwareListHelper()}
              </Select>
            </Form.Item>
            <Form.Item required label="License" {...formItemLayout}>
              <Select
                placeholder="Select license"
                allowClear
                onSelect={onSelectLic}
                value={state.selectedLicense}
              >
                {licenseListHelper()}
              </Select>
            </Form.Item>
            <Form.Item required label="Assigned date" {...formItemLayout}>
              <DatePicker
                value={
                  state.startDate ? moment(state.startDate, "MM/DD/YYYY") : null
                }
                onChange={onChangeStart}
                format={"MM/DD/YYYY"}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item label="End Date" {...formItemLayout}>
              <DatePicker
                value={
                  state.endDate ? moment(state.endDate, "MM/DD/YYYY") : null
                }
                disabledDate={disabledDate}
                onChange={onChangeEnd}
                format={"MM/DD/YYYY"}
              />
            </Form.Item>
          </Form>
        </Spinner>
      </ModalPopup>
    </div>
  );
};

const AssignKeyByUser = compose(
  graphql(SOFTWARESEARCH_QUERY),
  graphql(ASSIGN_KEYS_MUTATION)
)(AssignKeyByUsers);

export default AssignKeyByUser;
