export const radioStyle = {
  marginTop: 8,
  lineHeight: "38px",
  width: 140,
};

export const KeyOptions = [
  { label: "Full Access", value: 11, disabled: false },
  { label: "Read", value: 7, disabled: false },
  { label: "Create", value: 8, disabled: false },
  { label: "Update", value: 9, disabled: false },
  //{ label: "Remove", value: 10, disabled: false },
];
export const PoOptions = [
  { label: "Full Access", value: 11, disabled: false },
  { label: "Read", value: 7, disabled: false },
  { label: "Create", value: 8, disabled: false },
];
export const PoInfoOptions = [
  { label: "Full Access", value: 11, disabled: false },
];
export const ReportOptions = [
  { label: "Full Access", value: 11, disabled: false },
];
export const AdminOptions = [
  { label: "Permissions", value: 12, disabled: false },
  { label: "PO administration", value: 13, disabled: false },
  { label: "API tokens", value: 14, disabled: false },
];
export const Modules = [
  { id: 1, name: "Dashboard" },
  { id: 2, name: "PO" },
  { id: 3, name: "Key" },
  { id: 4, name: "PoInfo" },
  { id: 5, name: "Search" },
  { id: 6, name: "Reporting" },
  { id: 8, name: "Administration" },
  { id: 10, name: "AppAssignments" },
];
export const permissionState = {
  userNt: [],
  POs: [],
  Keys: [],
  PoInfo: [],
  Reporting: [],
  Administration: [],
  PoOptions,
  KeyOptions,
  PoInfoOptions,
  ReportOptions,
  AdminOptions,
};

export const permissions = {
  pOs: [],
  keys: [],
  poInfo: [],
  reporting: [],
  administration: [],
};
export const options = {
  PoOptions,
  KeyOptions,
  PoInfoOptions,
  ReportOptions,
  AdminOptions,
};
