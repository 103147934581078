import React, { Component } from "react";
import ModalPopup from "../ModalPopup/ModalPopup";
import { Form } from "antd";
import { Input, message } from "antd";
import { ADDVENDOR_MUTATION } from "../../Apollo/Mutations";
import { VENDORLIST_QUERY } from "../../Apollo/Queries";
import { graphql } from "react-apollo";
import Spinner from "../../Shared/Spinner/Spinner";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const inputs = [
  {
    id: 1,
    displayName: "Vendor Name",
    name: "name",
  },
  {
    id: 2,
    displayName: "Support Contact",
    name: "supportContact",
  },
  {
    id: 3,
    displayName: "Kb Url",
    name: "kbUrl",
  },
  {
    id: 4,
    displayName: "Notes",
    name: "notes",
  },
];

class AddVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      supportContact: null,
      kbUrl: null,
      notes: null,
      loading: false,
      inputValidationStatus: "",
      inputValidationMessage: "",
    };
  }

  startLoading = () => {
    this.setState({
      loading: true,
    });
  };

  stopLoading = () => {
    this.setState({
      loading: false,
    });
  };

  onModalClose = () => {
    const clearState = {
      name: null,
      supportContact: null,
      kbUrl: null,
      notes: null,
      visible: false,
      inputValidationStatus: "",
      inputValidationMessage: "",
    };
    this.setState(clearState);
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
    this.onModalClose();
  };

  handleSubmit = () => {
    if (!this.state.name) {
      this.setState({
        inputValidationMessage: "Vendor name cannot be empty.",
        inputValidationStatus: "error",
      });
    }
    if (this.state.name) {
      this.startLoading();
      this.props
        .mutate({
          variables: {
            vendor: {
              name: this.state.name,
              supportContact: this.state.supportContact,
              kbUrl: this.state.kbUrl,
              notes: this.state.notes,
            },
          },
          update: (store, { data: { createVendor } }) => {
            const vendor = store.readQuery({ query: VENDORLIST_QUERY });
            vendor.vendorList.unshift(createVendor);
            store.writeQuery({ query: VENDORLIST_QUERY, data: vendor });
          },
        })
        .then(({ data }) => {
          this.stopLoading();
          this.onModalClose();
          message.success("Vendor added!");
        })
        .catch((error) => {
          this.stopLoading();
          message.error("An error occured. Please try again.");
        });
    }
  };

  valueHelper = (name) => {
    for (let i in this.state) {
      if (i === name) {
        return this.state[i];
      }
    }
  };

  render() {
    return (
      <div>
        <ModalPopup
          header="Add New Vendor"
          buttonText="Add New Vendor"
          name="Submit"
          submit={this.handleSubmit}
          onModalClose={this.onModalClose}
          showModal={this.showModal}
          handleCancel={this.handleCancel}
          visible={this.state.visible}
        >
          <Spinner loading={this.state.loading}>
            <Form>
              {inputs.map((p) => {
                if (p.name === "name") {
                  return (
                    <Form.Item
                      key={p.id}
                      {...formItemLayout}
                      label={p.displayName}
                      validateStatus={this.state.inputValidationStatus}
                      help={this.state.inputValidationMessage}
                      required
                    >
                      <Input
                        autoFocus
                        name={p.name}
                        onChange={this.onChange}
                        value={this.valueHelper(p.name)}
                      />
                    </Form.Item>
                  );
                }
                if (p.name === "notes") {
                  const { TextArea } = Input;
                  return (
                    <Form.Item
                      key={p.id}
                      {...formItemLayout}
                      label={p.displayName}
                    >
                      <TextArea
                        name={p.name}
                        onChange={this.onChange}
                        value={this.valueHelper(p.name)}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                      />
                    </Form.Item>
                  );
                } else {
                  return (
                    <Form.Item
                      key={p.id}
                      {...formItemLayout}
                      label={p.displayName}
                    >
                      <Input
                        name={p.name}
                        onChange={this.onChange}
                        value={this.valueHelper(p.name)}
                      />
                    </Form.Item>
                  );
                }
              })}
            </Form>
          </Spinner>
        </ModalPopup>
      </div>
    );
  }
}
const AddVendorWithData = graphql(ADDVENDOR_MUTATION)(AddVendor);
export default AddVendorWithData;
