import React from "react";
import { Form } from "antd";
import { Select } from "antd";
import { SOFTWARELISTBYPUBLISHERID_QUERY } from "../../Apollo/Queries";
import { Query } from "react-apollo";
import { formItemLayout } from "../../Shared/Helpers";
//import AddSoftwareWithData from "./AddSoftware";
//import useGlobal from "../../GlobalState/Store";

const SoftwareList = (props) => {
  //const [globalState, globalActions] = useGlobal();

  //let fullAccess;
  /* const addSoftwareHelper = () => {
    fullAccess = props.PoClaim.filter(i => i.authTypeName === "FullAccess");
    if (fullAccess.length > 0) {
      return <AddSoftwareWithData />;
    }
  }; */

  const { Option } = Select;
  const { value, dropDownSelect, guid } = props;

  const emptyForm = (status) => {
    return (
      <Form
        layout={props.assignment ? "horizontal" : "inline"}
        {...(props.assignment ? { ...formItemLayout } : null)}
      >
        <Form.Item
          validateStatus={status}
          hasFeedback
          label="Software"
          required
        >
          <Select
            placeholder="Select Software"
            onChange={dropDownSelect}
            style={{ minWidth: 180 }}
          />
        </Form.Item>
      </Form>
    );
  };

  const queryHelper = () => {
    if (guid && guid.length > 0) {
      return (
        <Query
          query={SOFTWARELISTBYPUBLISHERID_QUERY}
          variables={{
            publisher: guid,
          }}
        >
          {({ data, loading, error }) => {
            if (loading) return emptyForm("validating");
            if (error) return <p>ERROR</p>;
            return (
              <Form
                layout={props.assignment ? "horizontal" : "inline"}
                {...(props.assignment ? { ...formItemLayout } : null)}
              >
                <Form.Item hasFeedback label="Software" required>
                  <Select
                    placeholder="Select Software"
                    onChange={dropDownSelect}
                    style={{ minWidth: 180 }}
                    value={value}
                    labelInValue={props.multiple ? false : true}
                    optionFilterProp="label"
                    onDeselect={props.onDeselectChange}
                    mode={props.multiple ? "multiple" : "default"}
                  >
                    {data.softwareByPublisherId
                      ? data.softwareByPublisherId.map((p) => {
                          return (
                            <Option
                              label={p.name}
                              ref={p.publisherId}
                              key={p.id}
                            >{`${p.name ? p.name : null} ${p.version}`}</Option>
                          );
                        })
                      : null}
                  </Select>
                </Form.Item>
                {/* <Form.Item>{addSoftwareHelper()}</Form.Item> */}
              </Form>
            );
          }}
        </Query>
      );
    } else {
      return emptyForm("");
    }
  };

  // var names = [];
  // for (let i = 0; i < props.name.length; i++) {
  // 	names.push(props.name[i][0]);
  // }
  return <div style={{}}>{queryHelper()}</div>;
};

export default SoftwareList;
