import axios from "axios";
import { authProvider } from "../Auth/authProvider";
import configData from "../config.json";

const instance = axios.create({
  baseURL: "https://" + configData.API.Server + configData.API.Path,
});

instance.interceptors.request.use(async function (config) {
  const accessToken = await authProvider.getAccessToken();
  const access = accessToken.accessToken;
  config.headers.Authorization = `Bearer ${access}`;
  config.headers.Access = access;

  return config;
});
export default instance;
