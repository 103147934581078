import React from "react";
import { PURCHASESBYSOFTWAREID_QUERY } from "../../Apollo/Queries";
import { Query } from "react-apollo";
import { EyeOutlined } from '@ant-design/icons';
import { Button, Table } from "antd";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import useGlobal from "../../GlobalState/Store";

const Purchases = (props) => {
  const globalState = useGlobal()[0];
  const { opsList } = globalState;

  const isMobil = useMediaQuery({
    query: "(max-width: 500px)",
  });
  const columns = [
    {
      title: "Org",
      dataIndex: "org",
      key: "org",
      sorter: (a, b) =>
        a.org.localeCompare(b.org, "en", {
          numeric: false,
        }),
    },
    {
      title: "Purchase Date",
      dataIndex: "dateOfPurchase",
      key: "dateOfPurchase",
      width: 150,
      sorter: true,
    },
    {
      title: "PO Number",
      dataIndex: "ponumber",
      key: "ponumber",
    },
    {
      title: "License Type",
      dataIndex: "licenseType",
      key: "licenseType",
    },
    {
      title: "Cost",
      dataIndex: "costPerLicense",
      key: "costPerLicense",
      render: (text) => <div>${text}</div>,
    },
    {
      title: "Key(s) Purchased",
      dataIndex: "keysPurchased",
      key: "keysPurchased",
      render: (text, record) =>
        record.keysPurchased === 0 ? "Unlimited" : record.keysPurchased,
    },
    {
      key: "action",
      width: isMobil ? 45 : 100,
      render: (t, r) => (
        <Link to={`/PurchaseOrder/${r.lineItemId}`} style={{ float: "right" }}>
          <Button type="primary" size="small" icon={<EyeOutlined />}>
            {isMobil ? "" : "View Full PO"}
          </Button>
        </Link>
      ),
    },
  ];
  const columnsHelper = () => {
    if (props.isTablet && !props.isMobil) {
      return columns.filter(
        (i) => i.key !== "licenseType" && i.key !== "costPerLicense"
      );
    } else if (props.isTablet && props.isMobil) {
      return columns.filter(
        (i) =>
          i.key !== "licenseType" &&
          i.key !== "costPerLicense" &&
          i.key !== "keysPurchased" &&
          i.key !== "ponumber"
      );
    } else {
      return columns;
    }
  };
  const displayFilter = (data) => {
    const admin = opsList.find((i) => i === 11);
    if (data && data.purchaseListBySoftware) {
      if (admin) return data.purchaseListBySoftware;
      var filtered = data.purchaseListBySoftware.filter((i) =>
        opsList.includes(i.opsId)
      );
      return filtered;
    }
  };

  return (
    <Query
      query={PURCHASESBYSOFTWAREID_QUERY}
      variables={{
        softwareId: props.softwareId,
      }}
    >
      {({ data, loading, error }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>ERROR</p>;
        const filtered = displayFilter(data);
        return (
          <Table
            size={isMobil ? "small" : "default"}
            rowKey={(rec) => rec.id}
            columns={columnsHelper()}
            dataSource={filtered}
            pagination={false}
          />
        );
      }}
    </Query>
  );
};

export default Purchases;
