import AddTokenWithData from "./AddToken";
import React, { useState, useRef } from "react";
import { graphql } from "react-apollo";
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Table, Input, Button, Popconfirm, message } from "antd";
import { TOKENLIST_QUERY } from "../../../Apollo/Queries";
import { REMOVE_APITOKEN_MUTATION } from "../../../Apollo/Mutations";
import { Query } from "react-apollo";
import Spinner from "../../../Shared/Spinner/Spinner";

const ManageAPITokens = (props) => {
  const setSearchText = useState("")[1];
  const [loading, setLoading] = useState(false);
  let searchInput = useRef();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ paddingTop: 12, marginRight: 0, width: 40 }}>
        <SearchOutlined
          style={{
            color: filtered ? "orange" : "white",
            fontSize: 16,
          }}
          size="500" />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const deleteRecord = (rec) => {
    setLoading(true);
    props
      .mutate({
        variables: {
          id: rec.id,
        },
        update: (store, { data: { deleteToken } }) => {
          const KeytoRemove = store.readQuery({
            query: TOKENLIST_QUERY,
          });
          KeytoRemove.tokenList = KeytoRemove.tokenList.filter(function (obj) {
            return obj.id !== rec.id;
          });
          store.writeQuery({
            query: TOKENLIST_QUERY,
            data: KeytoRemove,
          });
        },
      })
      .then(({ data }) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error("An Error occured. Please try again.");
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name, "en", { numeric: true }),
      ...getColumnSearchProps("name"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (t, r) => (
        <Popconfirm
          title="Are you sure you want to remove this item?"
          onConfirm={() => deleteRecord(r)}
        >
          <Button type="danger" icon={<DeleteOutlined />} size="small" ghost>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Spinner loading={loading}>
      <div style={{ margin: "20px" }}>
        <Query query={TOKENLIST_QUERY}>
          {({ data, loading, error }) => {
            if (loading) return <p>Loading...</p>;
            if (error) return <p>ERROR</p>;

            return (
              <>
                <div className="ant-descriptions-title">Manage Tokens</div>
                <div style={{ minWidth: "24em" }} className="center">
                  <AddTokenWithData />
                  <Table
                    style={{ marginTop: 10 }}
                    columns={columns}
                    rowKey={(rec) => rec.id}
                    dataSource={data.tokenList}
                    loading={loading}
                    pagination={{
                      defaultPageSize: 25,
                      pageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "25", "50"],
                    }}
                  />
                </div>
              </>
            );
          }}
        </Query>
      </div>
    </Spinner>
  );
};

const ManageAPITokensData = graphql(REMOVE_APITOKEN_MUTATION)(ManageAPITokens);
export default ManageAPITokensData;
