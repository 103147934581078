import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-boost";
import { authProvider } from "../Auth/authProvider";
import configData from "../config.json";
const cache = new InMemoryCache();

export const uploadEnvCheck = () => {
  return (
    "https://" + configData.API.Server + configData.API.Path + "file/upload"
  );
};

export const downloadEnvCheck = () => {
  return (
    "https://" + configData.API.Server + configData.API.Path + "file/download"
  );
};

export const envCheck = () => {
  return "https://" + configData.API.Server + configData.API.Path + "graphql";
};

export const Client = () => {
  const apolloClient = new ApolloClient({
    uri: envCheck(),
    cache,

    request: async (operation) => {
      const token = await authProvider.getIdToken();
      const idToken = token.idToken.rawIdToken;
      const accessToken = await authProvider.getAccessToken();
      const access = accessToken.accessToken;
      operation.setContext({
        headers: {
          Authorization: token ? `Bearer ${idToken}` : "",
          Access: access,
        },
      });
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.log("graphQLErrors", graphQLErrors);
      }
      if (networkError) {
        console.log("networkError", networkError);
        if (networkError.statusCode === 401) {
          return {
            request: async (operation) => {
              const token = await authProvider.getIdToken();
              const idToken = token.idToken.rawIdToken;
              operation.setContext({
                headers: {
                  Authorization: token ? `Bearer ${idToken}` : "",
                },
              });
            },
          };
        }
      }
    },
  });

  return apolloClient;
};
