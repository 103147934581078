import React, { useState, useEffect } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { Select, Tooltip } from "antd";
import { UPGRADEKEYS_LIST } from "../../../../Apollo/Queries";
import { Query } from "react-apollo";
import useGlobal from "../../../../GlobalState/Store";

const LicenseKeys = (props) => {
  const globalState = useGlobal()[0];
  const { authClaims } = globalState;

  const [auth, setAuth] = useState({ allAuth: false, claims: [] });

  useEffect(() => {
    if (authClaims.length > 0) {
      const claim = authClaims.filter((i) => i.ops.id === 11);
      if (claim.length > 0) {
        setAuth({ ...auth, allAuth: true });
      } else {
        const auth = authClaims.filter(
          (i) => i.moduleId === 2 && i.authTypeId === 11
        );
        setAuth({ allAuth: false, claims: auth });
      }
    }
  }, [authClaims]);

  const optionsHelper = (p) => {
    return (
      <Option
        ops={p.opsId}
        org={p.org}
        label={p.licenseType}
        ref={p.softwareId}
        key={p.id}
      >
        <Tooltip
          placement="top"
          title={
            <div>
              <p>
                {p.org}; {p.keyUsage}; {p.licenseType};{" "}
                {p.isUserBased ? "Per User" : "Machine based"}
              </p>
            </div>
          }
        >
          <InfoCircleOutlined style={{ marginRight: 5 }} />
        </Tooltip>
        {`${p.org}; ${p.keyUsage}`}
      </Option>
    );
  };

  const { Option } = Select;
  const licenseTypeHelper = (p) => {
    if (props.value && props.value.key) {
      props.dropDownSelect([], null);
    }
    return (
      <Form layout="inline">
        <Form.Item label={props.label} required>
          <Select placeholder={p} style={{ minWidth: 200 }} />
        </Form.Item>
      </Form>
    );
  };
  if (!props.softwareType || props.softwareType.length < 1) {
    return licenseTypeHelper("Select Key");
  }
  return (
    <div>
      <Query
        query={UPGRADEKEYS_LIST}
        variables={{
          SoftwareId: props.softwareType,
        }}
      >
        {({ data, loading, error }) => {
          if (loading) return licenseTypeHelper("Loading...");
          if (error) return <p>ERROR</p>;
          return (
            <div>
              <Form layout="inline">
                <Form.Item label={props.label} required>
                  <Select
                    mode={props.multiple ? "multiple" : "default"}
                    showSearch
                    placeholder="Select Existing Key"
                    style={{ minWidth: 200 }}
                    onChange={(e, f) => props.dropDownSelect(e, f)}
                    optionFilterProp="label"
                    value={props.value}
                  >
                    {data.keysbySoftwareList.map((p) => {
                      if (auth.allAuth) {
                        return optionsHelper(p);
                      } else {
                        const allowed = auth.claims.filter(
                          (i) => i.ops.id === p.opsId
                        );
                        if (allowed.length > 0) {
                          return optionsHelper(p);
                        }
                      }
                    })}
                  </Select>
                </Form.Item>
              </Form>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default LicenseKeys;
