import React, { useEffect, useState } from "react";
import posed from "react-pose";
import moment from "moment";
import SoftwareUsage from "./SoftwareUsage";
import ExpiringIn90Days from "./ExpiringIn90Days";
//import CostProjection from "./CostProjection";
import SamToSccmInstalls from "./SamToSccmInstalls";

const CardList = posed.ul({
  shown: {
    y: "0%",
    staggerChildren: 250,
  },
  hidden: {
    y: "-100%",
  },
});

const CardItem = posed.li({
  shown: { opacity: 1 },
  hidden: { opacity: 0 },
});

/* const PosedDiv = posed.div({
  shown: {
    y: "0%",
    opacity: 1,
    staggerChildren: 250
  },
  hidden: {
    opacity: 0,
    y: "-100%"
  }
}); */

const visitData = [];
const beginDay = new Date().getTime();
for (let i = 0; i < 20; i += 1) {
  visitData.push({
    x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    y: Math.floor(Math.random() * 100) + 10,
  });
}
const Home = (props) => {
  const [on, setOn] = useState(false);

  useEffect(() => {
    setTimeout(slideIn(), 50);
    // eslint-disable-next-line no-use-before-define
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const slideIn = () => {
    setOn(!on);
  };

  return (
    <div style={{ padding: "1em", marginBottom: 20 }}>
      <h2 className="text-heading" style={{ textAlign: "center" }}>
        Dashboard
      </h2>
      <CardList
        pose={on ? "shown" : "hidden"}
        style={{ marginLeft: "1em", marginRight: "1em" }}
        className="cards"
      >
        <CardItem className="card">
          <SamToSccmInstalls />
        </CardItem>
        <CardItem className="card">
          <ExpiringIn90Days />
        </CardItem>
        <CardItem className="card">
          <SoftwareUsage most={true} title="Most Utilized" />
        </CardItem>
        <CardItem className="card">
          <SoftwareUsage most={false} title="Least Utilized" />
        </CardItem>
      </CardList>

      {/* <PosedDiv pose={on ? "shown" : "hidden"} className="CostProjection">
        <CostProjection />
      </PosedDiv> */}
    </div>
  );
};

export default Home;
