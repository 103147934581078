import React, { Component } from "react";
import ModalPopup from "../../ModalPopup/ModalPopup";
import { Form, Input, notification, message } from "antd";
import { graphql } from "react-apollo";
import Spinner from "../../../Shared/Spinner/Spinner";
import { ADDTOKEN_MUTATION } from "../../../Apollo/Mutations";
import { TOKENLIST_QUERY } from "../../../Apollo/Queries";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const inputs = {
  id: 1,
  displayName: "Token Name",
};

class AddToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      name: null,
      loading: false,
    };
  }

  startLoading = () => {
    this.setState({
      loading: true,
    });
  };

  stopLoading = () => {
    this.setState({
      loading: false,
    });
  };

  onModalClose = () => {
    const clearState = {
      name: null,
      id: null,
      visible: false,
    };
    this.setState(clearState);
  };

  onChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
    this.onModalClose();
  };

  handleSubmit = () => {
    if (!this.state.name) {
      this.setState({
        inputValidationMessage: "Token name cannot be empty.",
        inputValidationStatus: "error",
      });
    }
    if (this.state.name) {
      this.startLoading();
      this.props
        .mutate({
          variables: {
            token: {
              name: this.state.name,
            },
          },
          update: (store, { data: { createToken } }) => {
            const token = store.readQuery({ query: TOKENLIST_QUERY });
            token.tokenList.unshift(createToken);
            store.writeQuery({ query: TOKENLIST_QUERY, data: token });
          },
        })
        .then(({ data }) => {
          this.stopLoading();
          this.onModalClose();

          notification.open({
            message: data.createToken.name,
            duration: 120,
            description: "ID: " + data.createToken.id,
          });
        })
        .catch((error) => {
          this.stopLoading();
          message.error("An error occured. Please try again.");
        });
    }
  };

  render() {
    return (
      <div>
        <Spinner loading={this.state.loading}>
          <ModalPopup
            header="Add New Token"
            buttonText="Add New Token"
            name="Submit"
            submit={this.handleSubmit}
            onModalClose={this.onModalClose}
            showModal={this.showModal}
            handleCancel={this.handleCancel}
            visible={this.state.visible}
          >
            <Form>
              <Form.Item
                {...formItemLayout}
                label={inputs.displayName}
                required
                validateStatus={this.state.inputValidationStatus}
                help={this.state.inputValidationMessage}
              >
                <Input
                  autoFocus
                  onChange={this.onChange}
                  value={this.state.name}
                />
              </Form.Item>
            </Form>
          </ModalPopup>
        </Spinner>
      </div>
    );
  }
}
const AddTokenWithData = graphql(ADDTOKEN_MUTATION)(AddToken);
export default AddTokenWithData;
