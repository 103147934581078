import React, { useEffect } from "react";
import { WaterWave } from "ant-design-pro/lib/Charts";
import { SAMTOSCCMUSAGE_QUERY } from "../../Apollo/Queries";
import { Query } from "react-apollo";
import useGlobal from "../../GlobalState/Store";

function SamToSccmInstalls(props) {
  //const [loading, setLoading] = useState(false);
  const globalState = useGlobal()[0];
  const { opsList } = globalState;

  return (
    <div style={{}}>
      <div className="Dashboard">
        <div
          className="ant-table-title ant-descriptions-title"
          style={{ background: "#8c8c8c", marginBottom: 20 }}
        >
          Sam to SCCM
        </div>
        {opsList.length > 0 ? (
          <Query
            query={SAMTOSCCMUSAGE_QUERY}
            variables={{
              opsList,
            }}
          >
            {({ data, loading, error }) => {
              if (loading) return null;
              if (error) return <p>ERROR</p>;
              const percent = data ? data.samToSccmUsage : null;
              return <WaterWave height={170} title="Usage" percent={percent} />;
            }}
          </Query>
        ) : null}
      </div>
    </div>
  );
}
export default SamToSccmInstalls;
