import React, { useState, useEffect } from "react";
import { graphql } from "react-apollo";
import { SCCMSEARCH_QUERY } from "../../../Apollo/Queries";
import { AutoComplete, Select, Form } from "antd";
import { formItemLayout } from "../../../Shared/Helpers";

const SoftwareCollectionSearch = (props) => {
  const [sccmName, setSccmName] = useState("");
  const [sccmAppId, setsccmAppId] = useState(0);
  const [collectionList, setCollectionList] = useState([]);
  const [sccmCollection, setSccmCollection] = useState(undefined);
  const [searching, setSearching] = useState("");
  const [searchMessage, setSearchMessage] = useState("");
  useEffect(() => {
    if (props.updateParent) {
      props.updateParent(sccmName, sccmCollection, sccmAppId);
    }
  }, [sccmName, sccmCollection, sccmAppId]);

  const onSelectSccm = (e, f) => {
    setsccmAppId(f.key);
    if (e) {
      setCollectionList(
        props.data.autoSuggestBySccmName.filter((i) => i.applicationName === e)
      );
    }
  };

  const onChangeSccm = (e) => {
    setSccmName(e);
    if (!e) {
      setCollectionList([]);
      setSccmCollection(undefined);
    }
  };
  const onSearchChangeSccm = (e) => {
    if (e) {
      setSearching("validating");
      setSearchMessage("");
      setTimeout(() => {
        props.data.refetch({ name: e }).then(({ data }) => {
          setSearching("");
          if (data && data.autoSuggestBySccmName.length < 1) {
            setSearchMessage("No found.");
          }
        });
      }, 500);
    }
  };

  const dataHelper = () => {
    if (props.data.autoSuggestBySccmName) {
      let uniq = {};
      const filtered = props.data.autoSuggestBySccmName.filter(
        (obj) => !uniq[obj.appModelId] && (uniq[obj.appModelId] = true)
      );
      return filtered.map((a) => {
        return (
          <AutoComplete.Option key={a.appModelId} value={a.applicationName}>
            {a.applicationName}
          </AutoComplete.Option>
        );
      });
    }
  };

  return (
    <Form {...formItemLayout}>
      <Form.Item
        validateStatus={searching}
        hasFeedback
        help={searchMessage}
        label="Sccm Name"
        required
      >
        <AutoComplete
          allowClear
          onSelect={onSelectSccm}
          onChange={onChangeSccm}
          onSearch={onSearchChangeSccm}
          placeholder="Search..."
        >
          {dataHelper()}
        </AutoComplete>
      </Form.Item>

      <Form.Item label="Collection" required>
        <Select
          onChange={(e) => setSccmCollection(e)}
          value={sccmCollection}
          placeholder="Select"
        >
          {collectionList
            ? collectionList.map((c) => {
                return (
                  <Select.Option key={c.collectionId}>
                    {c.collectionName}
                  </Select.Option>
                );
              })
            : null}
        </Select>
      </Form.Item>
    </Form>
  );
};
const SoftwareCollectionSearchWithData = graphql(SCCMSEARCH_QUERY)(
  SoftwareCollectionSearch
);

export default SoftwareCollectionSearchWithData;
