import React, { useState, useEffect } from "react";
import { AutoComplete, Form } from "antd";
import { graphql } from "react-apollo";
import { AUTOSUGGESTGROUP_QUERY } from "../../../../Apollo/Queries";
import { PermissionsFormLayout } from "../../../../Shared/Helpers";
import { debounce } from "lodash";

const { Option } = AutoComplete;

const GroupLookups = (props) => {
  const [searching, setSearching] = useState("");
  const [state, setState] = useState({ grpName: "", grpSid: "" });
  const [searchMessage, setSearchMessage] = useState("");

  const { updateParent } = props;
  useEffect(() => {
    if (updateParent) {
      updateParent(state.grpName, state.grpSid);
    }
  }, [state.grpName]);
  const dataHelper = () => {
    return props.data.autoSuggestByGroup
      ? props.data.autoSuggestByGroup.map((a) => {
          return <Option key={a.sid} value={a.name}>{a.name}</Option>;
        })
      : null;
  };


    const onSearchChange = (e, f) => {
      console.log(JSON.stringify("onSearchChange: " + e));
    if (e) {
      setSearching("validating");
      setSearchMessage("");
      if(e.length > 1)
      {
      props.data
        .refetch({ name: e })
        .then(({ data }) => {
          setSearching("");
          if (data.autoSuggestByGroup && data.autoSuggestByGroup.length < 1) {
            setSearchMessage(<p style={{ color: "#f36f21" }}>Not found.</p>);
          }
          console.log("onSearchChange e: " + JSON.stringify(e))
          console.log("onSearchChange data: " + JSON.stringify(data))
          console.log("onSearchChange data.autoSuggestByGroup: " + JSON.stringify(data.autoSuggestByGroup))
        })
        .catch((error) => {
          setSearching("");
          // setSearchMessage(
          //   <p style={{ color: "#f36f21" }}>Error! please try again.</p>
          // );
          console.log(`Search Error:  ${JSON.stringify(error)}`);
        });
      }
    }
  }

  const onBlurChange = (e) => {
    console.log("onBlurChange: " + e.target.value);
    //FIXME: Azure Migration DONE - Change e to e.target.value
    if (e.target.value !== state.grpName) {
      setState({ ...state, grpName: "" });
    }
  };

  const onChangeGrp = (e, f) => {
    console.log("onChangeGrp e: " + JSON.stringify(e));
    console.log("onChangeGrp f: " + JSON.stringify(f.key));
    setState({ ...state, grpName: e, grpSid: f.key});
  };
  return (
    <Form {...PermissionsFormLayout}>
      <Form.Item
        required
        label="Group"
        validateStatus={searching}
        hasFeedback
        help={searchMessage}
      >
        <AutoComplete
          autoFocus
          placeholder="Search Group"
          onSelect={onChangeGrp}
          onSearch={onSearchChange}
          onBlur={(e) => onBlurChange(e)}
        >
          {dataHelper()}
        </AutoComplete>
      </Form.Item>
    </Form>
  );
};
const GroupLookup = graphql(AUTOSUGGESTGROUP_QUERY)(GroupLookups);
export default GroupLookup;
