import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { Select } from "antd";
import { ORGLIST_QUERY } from "../../../Apollo/Queries";
import { Query } from "react-apollo";
import { formItemLayout } from "../../../Shared/Helpers";
import useGlobal from "../../../GlobalState/Store";

const OrgList = (props) => {
  const globalState = useGlobal()[0];
  const { authClaims } = globalState;
  const [auth, setauth] = useState({ allAuth: false, claims: [] });

  useEffect(() => {
    if (authClaims.length > 0) {
      const allAuth = authClaims.filter((i) => i.ops.id === 11);
      if (allAuth.length > 0) {
        setauth({ ...auth, allAuth: true });
      } else {
        var auth = authClaims.filter(
          (i) => (i.moduleId === 2 && i.authTypeId === 11) || i.ops.id === 11
        );
        if (auth.length > 0) {
          setauth({ allAuth: false, claims: auth });
        }
      }
    }
  }, [authClaims]);

  const { Option } = Select;
  const orgListHelper = () => {
    return (
      <Form
        layout={props.assignment ? "horizontal" : "inline"}
        {...(props.assignment ? { ...formItemLayout } : null)}
      >
        <Form.Item label="Org" required>
          <Select placeholder="Loading..." style={{ minWidth: 200 }} />
        </Form.Item>
      </Form>
    );
  };
  return (
    <div>
      <Query query={ORGLIST_QUERY}>
        {({ data, loading, error }) => {
          if (loading) return orgListHelper();
          if (error) return <p>ERROR</p>;
          return (
            <div>
              <Form
                layout={props.assignment ? "horizontal" : "inline"}
                {...(props.assignment ? { ...formItemLayout } : null)}
              >
                <Form.Item label="Org" required>
                  <Select
                    showSearch
                    placeholder={props.disabled ? null : "Select Org"}
                    style={{
                      minWidth: 200,
                      color: props.disabled ? "whitesmoke" : null,
                    }}
                    onChange={props.orgSelect}
                    labelInValue
                    disabled={props.disabled ? true : false}
                    showArrow={props.disabled ? false : true}
                    value={props.value}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {auth.allAuth
                      ? data.orgList.map((p) => {
                          return (
                            <Option key={p.id} value={p.id}>
                              {p.subRegion}
                            </Option>
                          );
                        })
                      : auth.claims.map((p) => {
                          return (
                            <Option key={p.ops.id} value={p.ops.id}>
                              {p.ops.subRegion}
                            </Option>
                          );
                        })}
                  </Select>
                </Form.Item>
              </Form>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default OrgList;
