import React from "react";
import Home from "./Shared/Dashboard/Home";
import BrowseList from "./Components/Browse/BrowseList";
import FullPO from "./Components/Browse/PO/FullPO";
import ManageKey from "./Components/Browse/ManageKey";
import SearchResults from "./Components/SearchResults";
import ManageKeyByUser from "./Components/Browse/ManageKeyByUser";
import Reporting from "./Components/Reporting";
import Permissions from "./Components/Administration/Permissions";
import ManageSoftware from "./Components/Administration/ManageSoftware";
import ManageVendors from "./Components/Administration/ManageVendors";
import ManagePublishers from "./Components/Administration/ManagePublishers";
import ManageAPITokens from "./Components/Administration/ManageAPITokens";
import { Route } from "react-router-dom";
import NewPurchaseOrder from "./Components/NewPurchaseOrder/PoLineItems/NewPurchaseOrder";
import AppAssignments from "./Components/Administration/AppAssignments";
import KeyPools from "./Components/Administration/KeyPools";
import NewUser from "./Components/Administration/Permissions/Users/NewUser";
import Edit from "./Components/Administration/Permissions/Users/Edit";
import NewGroup from "./Components/Administration/Permissions/Groups/NewGroup";
import EditGroup from "./Components/Administration/Permissions/Groups/EditGroup";

export const claimRouter = (claims) => {
  console.log("claims", claims);
  let routes = {
    1: <Route key={1} exact path="/" render={(props) => <Home {...props} />} />,
    2: (
      <Route
        key={2}
        path="/Search/:search"
        render={(props) => <SearchResults {...props} />}
      />
    ),
    3: (
      <Route
        key={3}
        path="/Browse"
        render={(props) => <BrowseList {...props} />}
      />
    ),
    4: (
      <Route
        key={4}
        path="/BrowsebyId/:id"
        render={(props) => <BrowseList {...props} />}
      />
    ),
    5: <Route key={5} path="/PurchaseOrder/:id" component={FullPO} />,

    7: (
      <Route
        key={7}
        path="/KeysByUser/:id"
        render={(props) => <ManageKeyByUser {...props} />}
      />
    ),
    8: (
      <Route
        key={8}
        path="/ManageKey/:name/:softid/:opsid/:id"
        render={(props) => <ManageKey {...props} />}
      />
    ),

    15: <Route key={15} path="/Administration/AddUser" component={NewUser} />,
    16: <Route key={16} path="/Administration/EditUser/:id" component={Edit} />,
    17: <Route key={17} path="/Administration/AddGroup" component={NewGroup} />,
    18: (
      <Route
        key={18}
        path="/Administration/EditGroup/:id"
        component={EditGroup}
      />
    ),
  };

  if (claims) {
    claims.forEach((e) => {
      if (
        (e.moduleName === "PO" && e.authTypeName === "FullAccess") ||
        e.authTypeName === "SuperAdmin"
      ) {
        routes[6] = (
          <Route
            key={6}
            path="/NewPurchaseOrder"
            render={(props) => <NewPurchaseOrder {...props} />}
          />
        );
      }
      if (
        e.authTypeName === "POAdministration" ||
        e.authTypeName === "SuperAdmin"
      ) {
        routes[10] = (
          <Route
            key={10}
            path="/Administration/ManagePublishers"
            component={ManagePublishers}
          />
        );
        routes[11] = (
          <Route
            key={11}
            path="/Administration/ManageVendors"
            component={ManageVendors}
          />
        );
        routes[12] = (
          <Route
            key={12}
            path="/Administration/ManageSoftware"
            component={ManageSoftware}
          />
        );
      }
      if (e.authTypeName === "APITokens" || e.authTypeName === "SuperAdmin") {
        routes[13] = (
          <Route
            key={13}
            path="/Administration/ManageAPITokens"
            component={ManageAPITokens}
          />
        );
      }
      if (
        e.authTypeName === "AppAssignment" ||
        e.authTypeName === "SuperAdmin"
      ) {
        routes[19] = (
          <Route
            key={19}
            path="/Administration/AppAssignments"
            render={(props) => <AppAssignments {...props} />}
          />
        );
      }
      // FIXME: Azure Migration - Broken Link (404)
      if (e.authTypeName === "KeyPools" || e.authTypeName === "SuperAdmin") {
        routes[20] = (
          <Route
            key={20}
            path="/Administration/KeyPools"
            render={(props) => <KeyPools {...props} />}
          />
        );
      }
      if (
        (e.moduleName === "Reporting" && e.authTypeName === "FullAccess") ||
        e.authTypeName === "SuperAdmin"
      ) {
        routes[9] = <Route key={9} path="/Reports" component={Reporting} />;
      }
      if (e.authTypeName === "Permissions" || e.authTypeName === "SuperAdmin") {
        routes[14] = (
          <Route
            key={14}
            path="/Administration/Permissions"
            component={Permissions}
          />
        );
      }
    });
  }
  var result = Object.keys(routes).map(function (key) {
    return [Number(key), routes[key]];
  });

  return result;
};
