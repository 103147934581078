import React from "react";

import {
  ApiOutlined,
  AppstoreOutlined,
  AuditOutlined,
  BookOutlined,
  DashboardOutlined,
  LockOutlined,
  ProfileOutlined,
  SolutionOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import { Icon as MdiIcon } from "@mdi/react";
import { mdiGroup } from "@mdi/js";

import logo from "./images/logo.png";

export const layoutHelper = (claims) => {
  let POAdministration = false;
  let APITokens = false;
  let Permissions = false;
  let AppAssignments = false;
  let KeyPools = false;
  let icons = [];
  const createPO = claims.filter(
    (i) =>
      (i.moduleName === "PO" && i.authTypeName === "FullAccess") ||
      i.authTypeName === "SuperAdmin"
  );
  if (createPO.length > 0) {
    icons.unshift(
      <Menu.Item key="/NewPurchaseOrder">
        <AppstoreOutlined />
        <span>New Purchase Order</span>
        <Link to="/NewPurchaseOrder" />
      </Menu.Item>
    );
  }
  const reporting = claims.filter(
    (i) =>
      (i.moduleName === "Reporting" && i.authTypeName === "FullAccess") ||
      i.authTypeName === "SuperAdmin"
  );
  if (reporting.length > 0) {
    icons.push(
      <Menu.Item key="/Reports">
        <AuditOutlined />
        <span>Reports</span>
        <Link to="/Reports" />
      </Menu.Item>
    );
  }
  claims.forEach((e, i) => {
    if (
      (e.moduleName === "Administration" && e.authTypeName === "Permissions") ||
      e.authTypeName === "SuperAdmin"
    ) {
      Permissions = true;
    }
    if (
      (e.moduleName === "Administration" &&
        e.authTypeName === "POAdministration") ||
      e.authTypeName === "SuperAdmin"
    ) {
      POAdministration = true;
    }
    if (
      (e.moduleName === "Administration" && e.authTypeName === "APITokens") ||
      e.authTypeName === "SuperAdmin"
    ) {
      APITokens = true;
    }
    if (
      (e.moduleName === "Administration" &&
        e.authTypeName === "AppAssignment") ||
      e.authTypeName === "SuperAdmin"
    ) {
      AppAssignments = true;
    }
    if (
      (e.moduleName === "Administration" && e.authTypeName === "KeyPools") ||
      e.authTypeName === "SuperAdmin"
    ) {
      KeyPools = true;
    }
  });

  let MenuItemAppAssignments = null;
  let MenuItemKeyPools = null;
  let Software = null;
  let Vendors = null;
  let Publishers = null;
  let MenuItemAPITokens = null;
  let MenuItemPermissions = null;
  if (Permissions) {
    MenuItemPermissions = (
      <Menu.Item key="/Administration/Permissions">
        <LockOutlined />
        <span>Permissions</span>
        <Link to="/Administration/Permissions" />
      </Menu.Item>
    );
  }

  if (APITokens) {
    MenuItemAPITokens = (
      <Menu.Item key="/Administration/ManageAPITokens">
        <ApiOutlined />
        <span>API tokens</span>
        <Link to="/Administration/ManageAPITokens" />
      </Menu.Item>
    );
  }

  if (POAdministration) {
    Software = (
      <Menu.Item key="/Administration/ManageSoftware">
        <BookOutlined />
        <span>Software</span>
        <Link to="/Administration/ManageSoftware" />
      </Menu.Item>
    );
    Vendors = (
      <Menu.Item key="/Administration/ManageVendors">
        <ProfileOutlined />
        <span>Vendors</span>
        <Link to="/Administration/ManageVendors" />
      </Menu.Item>
    );
    Publishers = (
      <Menu.Item key="/Administration/ManagePublishers">
        <SolutionOutlined />
        <span>Publishers</span>
        <Link to="/Administration/ManagePublishers" />
      </Menu.Item>
    );
  }

  if (AppAssignments) {
    MenuItemAppAssignments = (
      <>
        <Menu.Item key="/Administration/AppAssignments">
          <AuditOutlined />
          <span>App Assignments</span>
          <Link to="/Administration/AppAssignments" />
        </Menu.Item>
      </>
    );
  }

  if (KeyPools) {
    MenuItemKeyPools = (
      <Menu.Item key="/Administration/KeyPools">
        <MdiIcon path={mdiGroup} className="anticon anticon-api" size="1em" />
        <span>Key pools</span>
        <Link to="/Administration/KeyPools" />
      </Menu.Item>
    );
  }

  if (POAdministration || APITokens || Permissions) {
    icons.push(
      <SubMenu
        key="sub1"
        title={
          <span>
            <ProfileOutlined />
            <span>Administration</span>
          </span>
        }
      >
        {MenuItemAPITokens}
        {MenuItemPermissions}
        {MenuItemAppAssignments}
        {MenuItemKeyPools}
        {Software}
        {Vendors}
        {Publishers}
      </SubMenu>
    );
  }
  return icons;
};

const SubMenu = Menu.SubMenu;

class SiderMenuItems extends React.Component {
  displayName = SiderMenuItems.name;

  render() {
    const { location } = this.props;
    const { claims } = this.props;
    return (
      <>
        <div className="sider-menu-index-logo">
          <a href="/">
            <img alt="" src={logo} />
            <h1>IT SAM</h1>
          </a>
        </div>

        <Menu
          theme="dark"
          defaultSelectedKeys={[location.pathname]}
          mode="inline"
        >
          <Menu.Item key="/">
            <DashboardOutlined />
            <span>Dashboard</span>
            <Link to="/" />
          </Menu.Item>
          <Menu.Item key="/Browse">
            <UnorderedListOutlined />
            <span>Browse</span>
            <Link to="/Browse" />
          </Menu.Item>
          {layoutHelper(claims)}
        </Menu>
      </>
    );
  }
}

export default withRouter(SiderMenuItems);
