import React from "react";
import { Card, Button } from "antd";

const Reporting = (props) => {
  // const [redirect, setRedirect] = useState(false);
  /* console.log(props);
  useEffect(() => {
    setRedirect(true);
    setTimeout(() => {
      window.open("http://stackoverflow.com", "_blank");
      props.history.push("/");
      setRedirect(false);
    }, 3000);
  }, []); */

  /* const options = {
    height: 1040,
    width: "100%"
    // All other vizCreate options are supported here, too
    // They are listed here: https://onlinehelp.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#ref_head_9
  }; */

  return (
    <div style={{ padding: "1em" }}>
      <h2
        className="text-heading"
        style={{ textAlign: "center", marginBottom: 50 }}
      >
        Reports
      </h2>

      <Card
        title="Tableau Reporting"
        extra={
          <Button>
            <a
              href="https://data.comcast.com/#/workbooks/11440/views"
              rel="noopener noreferrer"
              target="_blank"
            >
              View
            </a>
          </Button>
        }
        style={{ width: 300, margin: "0 auto", color: "white" }}
      >
        <p style={{ color: "whitesmoke" }}>
          Note: Clicking the link above will take you to SAM Tableau Reporting
          site.
        </p>
      </Card>
    </div>
  );
};

export default Reporting;
