import React, { useState } from "react";
import PermissionTable from "../PermissionsBuilder/PermissionTable";
import { LeftOutlined } from '@ant-design/icons';
import { Row, Col, Button, message } from "antd";
import { Link } from "react-router-dom";
import { graphql } from "react-apollo";
import { ADDNEWGROUPPERMISSIONS_MUTATION } from "../../../../Apollo/Mutations";
import Spinner from "../../../../Shared/Spinner/Spinner";
import { GROUPACCESS_QUERY } from "../../../../Apollo/Queries";
import GroupLookup from "./GroupLookup";

const NewGroups = (props) => {
  const [state, setState] = useState({
    grpSid: "",
    permissions: [],
    loading: false,
    superAdmin: false
  });
  const updatePermissions = (childState, admin) => {
    setState({ ...state, permissions: childState, superAdmin: admin });
  };
  const updateParent = (grpName, grpSid) => {
    console.log("NewGroup.js updaeUser grpSid: " + grpSid);
    setState({...state, grpName, grpSid})
  };

  const onSubmit = () => {
    if (
      (state.grpName && state.permissions.length > 0) ||
      (state.grpName && state.superAdmin)
    ) {
      setState({ ...state, loading: true });
      props
        .mutate({
          variables: {
            var: {
              id: state.grpSid,
              admin: state.superAdmin,
              userPermissions: state.permissions,
            },
          },
          update: (store, { data: { addNewGroupPermissions } }) => {
            const Group = store.readQuery({ query: GROUPACCESS_QUERY });
            Group.getGroupsWithAccess.unshift(addNewGroupPermissions);
            store.writeQuery({ query: GROUPACCESS_QUERY, data: Group });
          },
          refetchQueries: [
            {
              query: GROUPACCESS_QUERY,
            },
          ],
        })
        .then(({ data }) => {
          setState({ ...state, loading: false });
          props.history.push("/Administration/Permissions");
          message.success(
            `${data.addNewGroupPermissions.name} added successfully!`
          );
        })
        .catch((error) => {
          setState({ ...state, loading: false });
          if (error.networkError) {
            if (error.networkError.result.errors[0].message !== null) {
              message.error(error.networkError.result.errors[0].message);
            }
          } else {
            message.error("An Error occured. Please try again.");
          }
        });
    } else {
      message.info("Please fill out all mandatory fields properly.");
    }
  };

  return (
    <div style={{ padding: "1em", marginBottom: "1em" }}>
      <Spinner loading={state.loading}>
        <Row>
          <Col span={24}>
            <div className="ant-descriptions-title">Add New Group</div>
          </Col>
        </Row>
        <Row gutter={[8, 24]}>
          <Col span={24}>
            <GroupLookup updateParent={updateParent} />
            <PermissionTable
              updateParent={updatePermissions}
              heading="New User Permissions Builder"
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Link to="/Administration/Permissions">
              <Button type="primary">
                <LeftOutlined />
                Go back
              </Button>
            </Link>
          </Col>
          <Col span={12}>
            <Button
              onClick={onSubmit}
              style={{ float: "right" }}
              type="primary"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Spinner>
    </div>
  );
};

const NewGroup = graphql(ADDNEWGROUPPERMISSIONS_MUTATION)(NewGroups);

export default NewGroup;
