import React from "react";
import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Tabs } from "antd";
import Users from "./Users/Users";
import Groups from "./Groups/Groups";
import useGlobal from "../../../GlobalState/Store";

const { TabPane } = Tabs;

const Permissions = (props) => {
  const [globalState, globalActions] = useGlobal();
  const {
    permissionsTab: { key },
  } = globalState;
  const { selectTab } = globalActions;
  return (
    <div style={{ margin: "20px 20px 20px 20px" }}>
      <div className="ant-descriptions-title">Permissions</div>
      <Tabs defaultActiveKey={key} onChange={(e) => selectTab(e)}>
        <TabPane
          tab={
            <span>
              <UserOutlined />
              Users
            </span>
          }
          key="1"
        >
          <Users />
        </TabPane>
        <TabPane
          tab={
            <span>
              <TeamOutlined />
              Groups
            </span>
          }
          key="2"
        >
          <Groups />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Permissions;
