import React from "react";
import { GLOBALSEARCH_QUERY } from "../../Apollo/Queries";
import { graphql } from "react-apollo";
import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";
import { Link, Redirect } from "react-router-dom";

const SearchResults = (props) => {
  const { data } = props;

  const usersHelper = (userData) => {
    const columns = [
      {
        title: "Users",
        key: "displayName",
        dataIndex: "displayName",
        render: (t, r) => {
          const name = r.displayName.split(",");
          return (
            <>
              {`${name[1]} ${name[0]}`}
              <span style={{ paddingLeft: ".5em" }}>
                <Tooltip
                  key={r.id}
                  placement="right"
                  title={
                    <div>
                      <p>NT: {r.userNt}</p>
                    </div>
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            </>
          );
        },
      },
      {
        dataIndex: "action",
        key: "action",
        width: 100,
        render: (t, r) => (
          <Link to={`/KeysByUser/${r.userNt}`} style={{ float: "right" }}>
            <Button icon={<EyeOutlined />}>Manage</Button>
          </Link>
        ),
      },
    ];
    if (userData.length > 0) {
      return (
        <div
          style={{
            maxWidth: 600,
            margin: "0 auto",
          }}
        >
          <Table
            showHeader={false}
            rowKey={(rec) => rec.id}
            columns={columns}
            dataSource={userData}
            pagination={userData.length > 10 ? true : false}
          />
        </div>
      );
    } else {
      return <p>No Results.</p>;
    }
  };

  const softwareHelper = (softData) => {
    const columns = [
      {
        title: "Name",
        key: "softwareName",
        dataIndex: "softwareName",
      },
      {
        title: "Publisher",
        key: "publisherName",
        dataIndex: "publisherName",
      },
      {
        dataIndex: "action",
        key: "action",
        width: 100,
        render: (t, r) => (
          <Link to={`/BrowsebyId/${r.softwareId}`} style={{ float: "right" }}>
            <Button icon={<EyeOutlined />}>Details</Button>
          </Link>
        ),
      },
    ];
    if (softData.length > 0) {
      return (
        <div
          style={{
            maxWidth: 600,
            margin: "0 auto",
          }}
        >
          <Table
            rowKey={(rec) => rec.softwareId}
            columns={columns}
            dataSource={softData}
            pagination={softData.length > 10 ? true : false}
          />
        </div>
      );
    } else {
      return <p>No Results.</p>;
    }
  };

  if (data.loading) {
    return (
      <div
        style={{
          padding: "30px 30px 30px 30px",
        }}
      >
        Loading...
      </div>
    );
  }
  if (data.error) {
    return <div>Error Occured </div>;
  }

  //Check if only 1 user came back; If so redirect
  if (
    data.globalSearch.users.length === 1 &&
    data.globalSearch.softwareList.length === 0
  ) {
    //Ensure we have a NT
    if (data.globalSearch.users[0] && data.globalSearch.users[0].userNt) {
      const path = `/KeysByUser/${data.globalSearch.users[0].userNt}`;
      return <Redirect to={path} />;
    }
  }
  //Check if only 1 software came back; If so redirect
  if (
    data.globalSearch.users.length === 0 &&
    data.globalSearch.softwareList.length === 1
  ) {
    if (
      data.globalSearch.softwareList[0] &&
      data.globalSearch.softwareList[0].softwareId
    ) {
      const path = `/BrowsebyId/${data.globalSearch.softwareList[0].softwareId}`;
      return <Redirect to={path} />;
    }
  }

  return (
    <div
      style={{
        textAlign: "center",
        maxWidth: 600,
        margin: "auto",
      }}
    >
      <div className="searchHeader">Users</div>
      {usersHelper(data.globalSearch.users)}

      <div className="searchHeader">Software</div>
      {softwareHelper(data.globalSearch.softwareList)}
    </div>
  );
};

const SearchResultsWithData = graphql(GLOBALSEARCH_QUERY, {
  options: ({ match }) => ({ variables: { search: match.params.search } }),
})(SearchResults);

export default SearchResultsWithData;
