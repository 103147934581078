import React from "react";
import { DeleteOutlined, DownOutlined, MoreOutlined } from "@ant-design/icons";
import { Table, Button, Menu, Dropdown, Tag, Avatar } from "antd";
import useGlobal from "../../../GlobalState/Store";
import ModifyNewLIneItem from "./NewSoftware/ModifyNewLineItem";
import { WindowWidth } from "../../../Shared/windowWidth";
import AddNewLineItem from "./NewSoftware/AddNewLineItem";
import AddMaintainanceItem from "./Maintainance/AddMaintainanceItem";
import ModifyMaintainanceItem from "./Maintainance/ModifyMaintainanceItem";
import AddUpgradeItem from "./Upgrade/AddUpgradeItem";
import ModifyUpgradeLineItem from "./Upgrade/ModifyUpgradeLineItem";

const LineItems = (props) => {
  const [globalState, globalActions] = useGlobal();
  const { poLineItemList } = globalState;
  const { removeFromPoLineItemList } = globalActions;
  const width = WindowWidth();

  const colorCheck = (text) => {
    let color = "";
    if (text === "New Software") {
      color = " #228B22 ";
    } else if (text === "Maintenance") {
      color = "#108ee9";
    } else if (text === "Upgrade") {
      color = "#bb00bb";
    }
    return color;
  };
  const columns = [
    {
      title: "Software",
      dataIndex: "softwareType.label",
      key: "softwareType.label",
      sorter: (a, b) =>
        a.softwareType.label.localeCompare(b.softwareType.label, "en", {
          numeric: true,
        }),
      render: (text, rec) => {
        const avatar = (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              maxHeight: 80,
            }}
          >
            <div>
              <h3 style={{ color: "whitesmoke", marginBottom: 0 }}>{text}</h3>
              <p>
                {rec.softwareType.label} - {rec.publisherType.label}
              </p>
            </div>
          </div>
        );
        return <div>{avatar}</div>;
      },
    },
    /* {
      title: "Seats",
      dataIndex: "installNumber",
      key: "installNumber"
    }, */
    {
      title: "Item Type",
      dataIndex: "itemType",
      key: "itemType",
      render: (text, rec) => {
        const color = colorCheck(text);

        return (
          <div>
            <Tag style={{ marginBottom: 5 }} color={color}>
              {text}
            </Tag>
            {rec.itemType === "Upgrade" ? (
              rec.migrateUsers ? (
                <Tag style={{ color: "black" }} color="#ffc100">
                  Migrate
                </Tag>
              ) : null
            ) : null}
          </div>
        );
      },
    },
    {
      title: width > 400 ? "Action" : "",
      key: "action",
      render: (text, record) => {
        if (width > 530) {
          return (
            <div
              style={{ width: "auto", margin: 0, minWidth: 170, maxWidth: 220 }}
            >
              <div style={{ width: "50%", margin: 0, float: "left" }}>
                {record.itemType === "New Software" ? (
                  <ModifyNewLIneItem
                    icon="edit"
                    PoClaim={props.PoClaim}
                    record={record}
                  />
                ) : record.itemType === "Maintenance" ? (
                  <ModifyMaintainanceItem
                    icon="edit"
                    PoClaim={props.PoClaim}
                    record={record}
                  />
                ) : record.itemType === "Upgrade" ? (
                  <ModifyUpgradeLineItem
                    icon="edit"
                    PoClaim={props.PoClaim}
                    record={record}
                  />
                ) : null}
              </div>
              <div style={{ width: "50%", margin: 0, float: "left" }}>
                <Button
                  size="small"
                  type="danger"
                  ghost
                  onClick={() => removeFromPoLineItemList(record.id)}
                >
                  <DeleteOutlined />
                  Remove
                </Button>
              </div>
            </div>
          );
        } else {
          const menu = (
            <Menu>
              <Menu.Item>
                <ModifyNewLIneItem
                  PoClaim={props.PoClaim}
                  link={true}
                  record={record}
                />
              </Menu.Item>
              <Menu.Item onClick={() => removeFromPoLineItemList(record.id)}>
                <DeleteOutlined style={{ marginRight: 5 }} />
                Remove
              </Menu.Item>
            </Menu>
          );
          return (
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button type="secondary" shape="circle" icon={<MoreOutlined />} />
            </Dropdown>
          );
        }
      },
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item style={{ marginRight: 0 }}>
        <AddNewLineItem poClaim={props.PoClaim} />
      </Menu.Item>
      <Menu.Item>
        <AddMaintainanceItem poClaim={props.PoClaim} />
      </Menu.Item>
      <Menu.Item>
        <AddUpgradeItem poClaim={props.PoClaim} />
      </Menu.Item>
    </Menu>
  );

  const addlineItem = () => {
    return (
      <div>
        <Dropdown overlay={menu}>
          <Button>
            Add Item <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    );
  };

  return (
    <div>
      <div style={{ marginBottom: 10 }}>{addlineItem()}</div>
      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={poLineItemList}
        pagination={false}
      />
    </div>
  );
};

export default LineItems;
