import React, { useState, useRef } from "react";
import { EditOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Table, Avatar, Input, Button, Tooltip, Tag } from "antd";
import { GROUPACCESS_QUERY } from "../../../../Apollo/Queries";
import { graphql } from "react-apollo";
import DeleteGroup from "./DeleteGroup";
import { Link } from "react-router-dom";

const colors = ["Red", "Green", "Blue", "Orange", "Purple"];

const Group = (props) => {
  const setSearchText = useState("")[1];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { data } = props;
  let searchInput = useRef();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ paddingTop: 12, marginRight: 0, width: 40 }}>
        <SearchOutlined
          style={{
            color: filtered ? "orange" : "white",
            fontSize: 16,
          }}
          size="500" />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name, "en", { numeric: true }),
      render: (text, rec) => {
        const avatar = (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              height: 50,
              paddingTop: 8,
            }}
          >
            <div style={{ marginRight: 10 }}>
              <Avatar
                size="large"
                style={{ backgroundColor: colors[rec.color] }}
              >
                {"WD"}
              </Avatar>
            </div>
            <div style={{ marginTop: 8 }}>
              <h3 style={{ color: "whitesmoke" }}>
                {`${text} `}
                <Tooltip
                  key={rec.id}
                  placement="right"
                  title={rec.description ? rec.description : "No description"}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </h3>
            </div>
          </div>
        );
        return avatar;
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "Org",
      key: "ops",
      dataIndex: "ops",
      render: (ops) => (
        <>
          {ops.map((tag) => {
            let color = "volcano";
            return (
              <Tag style={{ marginBottom: 5 }} color={color} key={tag.id}>
                {tag.id === 11 ? "All Orgs" : tag.subRegion}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (data.loading) return <p>Loading...</p>;
  if (data.error) return <p>Error...</p>;
  const deleteHelper = () => {
    if (selectedRowKeys.length > 0) {
      return <>
        <div style={{ display: "inline-block", marginRight: 10 }}>
          <DeleteGroup
            setToDelete={setSelectedRowKeys}
            keys={selectedRowKeys}
          />
        </div>
        {selectedRowKeys.length === 1 ? (
          <div style={{ display: "inline-block" }}>
            <Link to={`/Administration/EditGroup/${selectedRowKeys[0]}`}>
              <Button type="primary">
                Edit
                <EditOutlined />
              </Button>
            </Link>
          </div>
        ) : null}
      </>;
    } else {
      return (
        <div style={{ marginBottom: 15 }}>
          <Link to="/Administration/AddGroup">
            <Button>Add Group</Button>
          </Link>
        </div>
      );
    }
  };

  const filterAdmin = (data) => {
    /* if (data) {
      data.forEach((e, i) => {
        const isAdmin = e.ops.filter((i) => i.id === 11);
        if (isAdmin.length > 0) {
          data = data.filter((i) => i.id !== e.id);
        }
      });
      return data;
    } */
    return data;
  };
  return (
    <div style={{ padding: 10 }}>
      {deleteHelper()}

      <Table
        columns={columns}
        rowKey={(rec) => rec.id}
        dataSource={filterAdmin(data.getGroupsWithAccess)}
        rowSelection={rowSelection}
        pagination={{
          defaultPageSize: 25,
          pageSize: 25,
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50"],
        }}
      />
    </div>
  );
};

const Groups = graphql(GROUPACCESS_QUERY)(Group);
export default Groups;
