import "babel-polyfill";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Auth from "./Auth/Auth";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "ant-design-pro/dist/ant-design-pro.css";

const WithAuth = () => {
  return (
    <Auth>
      <App />
    </Auth>
  );
};

ReactDOM.render(<WithAuth />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
