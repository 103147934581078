import React from "react";
import { PermissionsFormLayout } from "../../../../Shared/Helpers";
import { Form } from "antd";
import { Checkbox } from "antd";

export const AdminCheckbox = ({ isAdminChange, isAdmin }) => {
  return (
    <div>
      <Form {...PermissionsFormLayout}>
        <Form.Item label="Super Admin" hasFeedback>
          <Checkbox onChange={isAdminChange} checked={isAdmin}>
            (Provides full access to this application)
          </Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AdminCheckbox;
