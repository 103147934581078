import React, { useState, useRef } from "react";
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Table, Avatar, Input, Button, Tag } from "antd";
import { USERSACCESS_QUERY } from "../../../../Apollo/Queries";
import { graphql } from "react-apollo";
import DeleteUser from "./DeleteUser";
import { Link } from "react-router-dom";
import { Responsive } from "../../../../Shared/Helpers";

const colors = ["Red", "Green", "Blue", "Orange", "Purple"];

const User = (props) => {
  const setSearchText = useState("")[1];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isTablet, isMobil] = Responsive();

  let searchInput = useRef();
  const { data } = props;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 15 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ paddingTop: 12, marginRight: 0, width: 40 }}>
        <SearchOutlined
          style={{
            color: filtered ? "orange" : "white",
            fontSize: 16,
          }}
          size="500" />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name, "en", { numeric: false }),
      render: (text, rec) => {
        const letters = text.split(" ");
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              height: 50,
            }}
          >
            <div style={{ marginRight: 10, paddingTop: 10 }}>
              <Avatar
                size="large"
                style={{ backgroundColor: colors[rec.color] }}
              >
                {letters[0][0]}
                {letters[1][0]}
              </Avatar>
            </div>
            <div>
              <h3 style={{ color: "whitesmoke" }}>{text}</h3>
              <p>{rec.email}</p>
            </div>
          </div>
        );
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "Org",
      key: "ops",
      dataIndex: "ops",
      render: (ops) => (
        <>
          {ops.map((tag) => {
            let color = "volcano";
            return (
              <Tag style={{ marginBottom: 5 }} color={color} key={tag.id}>
                {tag.id === 11 ? "All Orgs" : tag.subRegion}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  const columnsHelper = () => {
    if (isMobil) {
      return columns.filter((i) => i.key !== "ops");
    }
    return columns;
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  if (data.loading) return <p>Loading...</p>;
  if (data.error) return <p>Error...</p>;

  const deleteHelper = () => {
    if (selectedRowKeys.length > 0) {
      return <>
        <div style={{ display: "inline-block", marginRight: 10 }}>
          <DeleteUser
            setToDelete={setSelectedRowKeys}
            keys={selectedRowKeys}
          />
        </div>
        {selectedRowKeys.length === 1 ? (
          <div style={{ display: "inline-block" }}>
            <Link to={`/Administration/EditUser/${selectedRowKeys[0]}`}>
              <Button type="primary">
                Edit
                <EditOutlined />
              </Button>
            </Link>
          </div>
        ) : null}
      </>;
    } else {
      return (
        <div style={{ marginBottom: 15 }}>
          <Link to="/Administration/AddUser">
            <Button>Add User</Button>
          </Link>
        </div>
      );
    }
  };
  const filterAdmin = (data) => {
    //TODO: Azure Migration DONE - Determine if Commented Out Code is needed for filtering for Admin in Users.js . Retain Commented Out
/*     if (data) {
      data.forEach((e, i) => {
        const isAdmin = e.ops.filter((ops) => ops.id === 11);
        if (isAdmin.length > 0) {
          //data = data.filter((i) => i.pidKey !== e.pidKey);
          data = data.filter((i) => i.azureObjectId !== e.azureObjectId);
        }
      });
    } */
    return data;
  };

  //TODO: Azure Migration DONE - Replace pidkey with azureObjectId
  return (
    <div style={{ padding: 10 }}>
      {deleteHelper()}
      <Table
        columns={columnsHelper()}
        rowSelection={rowSelection}
        rowKey={(rec) => rec.azureObjectId}
        dataSource={filterAdmin(data.getUsersWithAccess)}
        pagination={{
          defaultPageSize: 25,
          pageSize: 25,
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50"],
        }}
      />
    </div>
  );
};

const Users = graphql(USERSACCESS_QUERY)(User);

export default Users;
