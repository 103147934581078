import React from "react";
import { BROWSEKEYSBYSOFTWAREID_QUERY } from "../../Apollo/Queries";
import { Query } from "react-apollo";
import { EditOutlined } from '@ant-design/icons';
import { Table, Button } from "antd";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import useGlobal from "../../GlobalState/Store";

const Keys = (props) => {
  const globalState = useGlobal()[0];
  const { opsList } = globalState;
  const isMobil = useMediaQuery({
    query: "(max-width: 500px)",
  });
  const columns = [
    {
      title: "Org",
      dataIndex: "org",
      key: "org",
      sorter: (a, b) => a.org.localeCompare(b.org, "en", { numeric: false }),
    },
    {
      title: "Key Usage",
      dataIndex: "keyUsage",
      key: "keyUsage",
      sorter: (a, b) =>
        a.keyUsage.localeCompare(b.keyUsage, "en", { numeric: true }),
    },
    {
      title: "Expiration",
      dataIndex: "expiration",
      key: "expiration",
      render: (text, record) =>
        record.expiration === null ? "Never" : record.expiration,
    },
    {
      title: "License Type",
      dataIndex: "licenseType",
      key: "licenseType",
    },
    {
      key: "action",
      width: 100,
      render: (t, r) => {
        return (
          <Link
            to={`/ManageKey/${props.name}/${props.softwareId}/${r.opsId}/${r.licenseTypeId}`}
            style={{ float: "right" }}
          >
            <Button type="primary" size="small" icon={<EditOutlined />}>
              Assignments
            </Button>
          </Link>
        );
      },
    },
  ];
  const columnsHelper = () => {
    if (props.isMobil) {
      return columns.filter(
        (i) => i.key !== "licenseType" && i.key !== "expiration"
      );
    } else {
      return columns;
    }
  };
  const displayFilter = (data) => {
    const allAuth = opsList.filter((i) => i === 11);
    if (allAuth.length > 0) {
      return data.keysbySoftware;
    } else if (data && data.keysbySoftware) {
      var filtered = data.keysbySoftware.filter((i) =>
        opsList.includes(i.opsId)
      );
      return filtered;
    }
  };
  return (
    <Query
      query={BROWSEKEYSBYSOFTWAREID_QUERY}
      variables={{
        SoftwareId: props.softwareId,
      }}
    >
      {({ data, loading, error }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>ERROR</p>;
        const filtered = displayFilter(data);
        return (
          <Table
            rowKey={(rec) => rec.id}
            size={isMobil ? "small" : "default"}
            columns={columnsHelper()}
            dataSource={filtered}
            pagination={false}
          />
        );
      }}
    </Query>
  );
};

export default Keys;
