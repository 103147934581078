import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import Media from "react-media";
import "./layout.css";
import HeaderSearch from "../Shared/HeaderSearch";

import SiderMenu from "./SiderMenu";
import MobileDrawer from "./MobileDrawer";
import logo from "./images/logo.png";
import ActiveUser from "../Components/ActiveUser/ActiveUser";
import useGlobal from "../GlobalState/Store";
import { WindowWidth } from "../Shared/windowWidth";
import { Route } from "react-router-dom";
import { Responsive } from "./Helpers";

const AppLayout = (props) => {
  const [state, setState] = useState({
    showSearch: false,
    searchValue: "",
    collapsed: false,
    collapsed2: false,
  });
  const isMobil = Responsive()[1];
  const width = WindowWidth();

  const showDrawer = () => {
    setState({
      collapsed: true,
    });
  };

  const onClose = () => {
    setState({
      collapsed: !state.collapsed,
    });
  };

  const toggle = () => {
    setState({
      collapsed: !state.collapsed,
    });
  };
  const toggle2 = () => {
    setState({
      collapsed2: !state.collapsed2,
    });
  };

  const responsive = (bool) => {
    bool ? setState({ collapsed: true }) : setState({ collapsed: false });
  };
  const globalState = useGlobal()[0];

  const { Header, Content } = Layout;

  const {
    authState: { userName },
  } = globalState;

  const displaySearch = () => {
    setState({ ...state, showSearch: true });
  };

  const hideSearch = (v) => {
    if (!v) {
      setState({ ...state, showSearch: false });
    }
  };

  const mobilSearchHelper = () => {
    if (width < 665 && state.showSearch) {
      return null;
    } else {
      return (
        <>
          <span className="header-index-action options">
            <ActiveUser name={width > 668 ? userName : null} />
          </span>
        </>
      );
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Media query="(max-width: 599px)">
        {(matches) =>
          matches ? (
            <MobileDrawer
              visible={width < 600 ? state.collapsed2 : state.collapsed}
              showDrawer={showDrawer}
              onClose={onClose}
              claims={props.claims}
            />
          ) : (
            <SiderMenu
              claims={props.claims}
              collapsed={width < 600 ? state.collapsed2 : state.collapsed}
              callback={responsive}
            />
          )
        }
      </Media>

      <Layout style={{ minHeight: "100vh" }}>
        <Header
          className="ant-layout-header"
          style={{ padding: 0, width: "100%" }}
        >
          <div className="header-index-header">
            <Media query="(max-width: 599px)">
              {(matches) =>
                matches ? (
                  <div className="header-index-logo">
                    <img src={logo} alt="logo" width="32" />
                  </div>
                ) : null
              }
            </Media>
            <span className="header-index-trigger">
              {/* {width < 600 ? (
                <LegacyIcon
                  className="trigger trigger-size"
                  type={state.collapsed ? "menu-unfold" : "menu-fold"}
                  onClick={toggle2}
                />
              ) : (
                <LegacyIcon
                  className="trigger trigger-size"
                  type={state.collapsed ? "menu-unfold" : "menu-fold"}
                  onClick={toggle}
                />
              )} */}
            </span>
            <div className="header-index-right">
              <span className="header-index-action">
                {!isMobil || state.showSearch ? (
                  <Route
                    render={({ history }) => (
                      <HeaderSearch
                        claims={props.claims}
                        hideSearch={hideSearch}
                        history={history}
                      />
                    )}
                  />
                ) : (
                  <SearchOutlined
                    key="Icon"
                    style={{ fontSize: 18 }}
                    onClick={displaySearch}
                  />
                )}
              </span>
              {mobilSearchHelper()}
            </div>
          </div>
        </Header>
        <Content>
          <div style={{ minHeight: "360px" }} className="sam-content">
            {props.children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
