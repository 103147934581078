import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { authProvider } from "../../Auth/authProvider";
import avatar from "../../Shared/images/avatar.png";

class ActiveUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
    };
  }
  componentDidMount = async () => {
    const graphEndpoint =
      "https://graph.microsoft.com/v1.0/me/photos/48x48/$value";
    var token = await authProvider.getAccessToken();
    fetch(graphEndpoint, {
      method: "GET",
      headers: { Authorization: token ? `Bearer ${token.accessToken}` : "" },
    })
      .then((response) => {
        if (response != null && response.ok) {
          response.blob().then((data) => {
            if (data !== null) {
              var url = window.URL.createObjectURL(data);
              this.setState({
                image: window.URL.createObjectURL(data),
              });
            }
          });
        } else {
          this.setState({ image: avatar });
        }
      })
      .catch((response) => {
        this.setState({ image: avatar });
      });
    /*  const response = await axios(graphEndpoint, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "arraybuffer",
    });
    const avatar = new Buffer(response.data, "binary").toString("base64");
    fetch(); */
  };
  updatePhoto = (data) => {
    this.setState({
      image: data,
    });
  };
  render() {
    const menu = (
      <Menu>
        <Menu.Item>
          <span>Welcome {this.props.name}</span>
        </Menu.Item>
        {/* <Menu.Item>
                    <a target="_blank" rel="noopener noreferrer" href="http://www.apple.com/">
                        3rd menu item
                    </a>
                </Menu.Item> */}
      </Menu>
    );
    return (
      <Dropdown overlay={menu}>
        <span className="header-index-action">
          <img
            style={{
              marginRight: 8,
              verticalAlign: "middle",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginRight: "6px",
              borderStyle: "solid",
              borderColor: "rgb(105, 105, 105)",
            }}
            src={this.state.image}
          />

          {this.props.name ? this.props.name : null}
        </span>
      </Dropdown>
    );
  }
}

export default ActiveUser;
