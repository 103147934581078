import React, { Component } from "react";
import { Modal, Button, Popconfirm } from "antd";

class ModalPopup extends Component {
  state = { visible: false };

  modalButtonHelper = () => {
    if (!this.props.hideBtn) {
      if (this.props.link) {
        return (
          <div
            style={{
              color: "white",
              display: "block",
              marginLeft: -11,
              marginRight: -11,
              paddingRight: 10,
            }}
            onClick={this.props.showModal}
            disabled={this.props.disable ? true : false}
          >
            {/* <LegacyIcon
              type={this.props.icon}
              style={{ paddingLeft: 10, paddingRight: 5 }}
            /> */}
            {this.props.buttonText}
          </div>
        );
      } else {
        return (
          <Button
            type={this.props.type || "default"}
            size={this.props.size}
            onClick={this.props.showModal}
            disabled={this.props.disable ? true : false}
            ghost={this.props.ghost ? true : false}
          >
            {/* {this.props.icon ? <LegacyIcon type={this.props.icon} /> : null} */}
            {this.props.buttonText}
          </Button>
        );
      }
    } else return null;
  };

  render() {
    return (
      <div>
        {this.modalButtonHelper()}
        <Modal
          destroyOnClose={true}
          title={<h4 style={{ color: "whitesmoke" }}>{this.props.header}</h4>}
          visible={this.props.visible}
          onOk={this.props.submit}
          onCancel={this.props.handleCancel}
          footer={[
            this.props.deleteBtn ? (
              <Popconfirm key="delete" title="Are you sure?">
                <Button type="danger" style={{ float: "left" }}>
                  Delete
                </Button>
              </Popconfirm>
            ) : null,
            <Button key="back" onClick={this.props.handleCancel}>
              {this.props.close ? this.props.close : "Close"}
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={this.props.disable ? true : false}
              onClick={this.props.submit}
            >
              {this.props.name}
            </Button>,
          ]}
        >
          {this.props.children}
        </Modal>
      </div>
    );
  }
}

export default ModalPopup;
