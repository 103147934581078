import React, { useEffect } from "react";
import { Client } from "../Apollo/Apollo";
import { AzureAD } from "react-aad-msal";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import useGlobal from "../GlobalState/Store";
import { authProvider } from "./authProvider";

const Auth = (props) => {
  /* const getToken = async () => {
    const token = await authProvider.getIdToken();
    const idToken = token.idToken.rawIdToken;
    if (idToken) {
      return await idToken;
    }
  }; */
  const globalActions = useGlobal()[1];
  useEffect(() => {
    const runEffect = async () => {
      const userInfo = authProvider.getAccount();
      const token = await authProvider.getIdToken();
      const idToken = token.idToken.rawIdToken;
      const accessToken = await authProvider.getAccessToken();
      const access = accessToken.accessToken;
      var parts = userInfo.name.split(",");
      var name = parts[1].trim() + " " + parts[0];
      globalActions.addAuthState({
        authState: {
          userName: name,
          userNt: userInfo.userName.split("@")[0].toLowerCase(),
          userInfo: userInfo,
          userId: userInfo.userName,
          rawToken: idToken,
          accessToken: access,
        },
      });
    };
    runEffect();
  }, []);
  return (
    <AzureAD provider={authProvider} forceLogin>
      <ApolloProvider client={Client()}>
        <ApolloHooksProvider client={Client()}>
          {props.children}
        </ApolloHooksProvider>
      </ApolloProvider>
    </AzureAD>
  );
};

export default Auth;
