import AddPublisherWithData from "../../NewPurchaseOrder/AddPublisher";
import React, { useState, useRef } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Table, Avatar, Input, Button } from "antd";
import { PUBLISHERLIST_QUERY } from "../../../Apollo/Queries";
import { Query } from "react-apollo";
import EditPublishers from "./EditPublishers";

const colors = ["Red", "Green", "Blue", "Orange", "Purple"];
const ManagePublishers = (props) => {
  const setSearchText = useState("")[1];

  let searchInput = useRef();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ paddingTop: 12, marginRight: 0, width: 40 }}>
        <SearchOutlined
          style={{
            color: filtered ? "orange" : "white",
            fontSize: 16,
          }}
          size="500" />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name, "en", { numeric: false }),
      render: (text, rec) => {
        const avatar = (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              height: 50,
              paddingTop: 8,
            }}
          >
            <div style={{ marginRight: 10 }}>
              <Avatar
                size="medium"
                style={{
                  backgroundColor: colors[Math.floor(Math.random() * 5)],
                }}
              >
                {`${text[0].toUpperCase()}${text[1].toUpperCase()}`}
              </Avatar>
            </div>
            <div style={{ marginTop: 8 }}>
              <h3 style={{ color: "whitesmoke" }}>{`${text} `}</h3>
            </div>
          </div>
        );
        return (
          <EditPublishers rec={rec} avatar={avatar}>
            {avatar}
          </EditPublishers>
        );
      },
      ...getColumnSearchProps("name"),
    },
  ];

  return (
    <div style={{ margin: "20px" }}>
      <Query query={PUBLISHERLIST_QUERY}>
        {({ data, loading, error }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>ERROR</p>;

          return (
            <>
              <div className="ant-descriptions-title">Manage Publishers</div>
              <div style={{ minWidth: "24em" }} className="center">
                <AddPublisherWithData />
                <Table
                  style={{ marginTop: 10 }}
                  className="column"
                  columns={columns}
                  rowKey={(rec) => rec.id}
                  dataSource={data.publisherList}
                  pagination={{
                    defaultPageSize: 25,
                    pageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50"],
                  }}
                  size="small"
                />
              </div>
            </>
          );
        }}
      </Query>
    </div>
  );
};

export default ManagePublishers;
