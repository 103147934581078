import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { Input, DatePicker, Button, message } from "antd";
import VendorList from "../VendorList";
import useGlobal from "../../../GlobalState/Store";
import { dateFormat } from "../../../Shared/Helpers";
import LineItems from "./LineItems";
import Results from "./Result";
import { NEWPO_MUTATION } from "../../../Apollo/Mutations";
import { useMutation } from "@apollo/react-hooks";
import Spinner from "../../../Shared/Spinner/Spinner";
import {
  KEYSBYSOFTWAREID_QUERY,
  BROWSELIST_QUERY,
  KEYTYPELIST_QUERY,
  LICENSETYPELIST_QUERY,
} from "../../../Apollo/Queries";

const NewPurchaseOrder = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const { addPurchaseOrder, clearNewPo } = globalActions;
  const {
    poLineItemList,
    purchaseOrder: { vendorType, poNumber, orderDate },
  } = globalState;

  const [
    addNewPurchaseOrder,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(NEWPO_MUTATION, {
    onCompleted(data) {
      if (data.addNewPurchaseOrder === "Success") {
        setSubmitted(true);
        clearNewPo();
      } else if (!data.addNewPurchaseOrder) {
        message.error("Error Occured! Please refresh the page and try again!");
      } else {
        message.error(
          `Error: ${data.addNewPurchaseOrder} has license. Please use upgrade or maintenance`
        );
      }
    },

    onError(error) {
      message.error("Error Occured! Please refresh the page and try again!");
    },
  });

  const dropDownSelectVendor = (e) => {
    addPurchaseOrder({ vendorType: e });
  };
  const addPoLineItemList = () => {
    if (poLineItemList.length < 1) {
      message.info("Please add items");
    } else if (!orderDate) {
      message.info("Date is missing");
    } else {
      const {
        purchaseOrder: { poNumber, orderDate, vendorType },
        poLineItemList,
        authState: { userNt },
      } = globalState;
      const updateItemList = poLineItemList;
      updateItemList.forEach(function (v) {
        if (v.attachFile.length > 0) {
          v.attachFile.forEach((e) => {
            delete e["xhr"];
          });
        }
        if (v.attachReg.length > 0) {
          v.attachReg.forEach((e) => {
            delete e["xhr"];
          });
        }
        delete v.publisherTypeList;
        delete v.softwareTypeList;
        delete v.softPubKeys;
        delete v.licenseSoftKeys;
      });
      const refetchQueries = [];
      poLineItemList.forEach((element) => {
        let rec = {
          query: KEYSBYSOFTWAREID_QUERY,
          variables: {
            SoftwareId: element.softwareType.key,
          },
        };
        refetchQueries.push(rec);
      });
      refetchQueries.push({ query: BROWSELIST_QUERY });
      refetchQueries.push({ query: KEYTYPELIST_QUERY });
      refetchQueries.push({ query: LICENSETYPELIST_QUERY });
      addNewPurchaseOrder({
        variables: {
          var: {
            poNumber,
            orderDate,
            vendorType,
            enteredBy: userNt,
            lineItems: updateItemList,
          },
        },
        refetchQueries,
      });
    }
  };

  const inputChange = (e) => {
    addPurchaseOrder({ poNumber: e.target.value });
    if (!e.target.value) {
      addPurchaseOrder({ vendorType: undefined, orderDate: null });
    }
  };

  const handleChoose = (e) => {
    e.preventDefault();
  };

  const dateChange = (e) => {
    addPurchaseOrder({
      orderDate: e,
    });
  };
  const enterNewOrderClick = () => {
    clearNewPo();
    setSubmitted(false);
  };

  if (submitted) return <Results handleCLick={enterNewOrderClick} />;

  return (
    <Spinner loading={mutationLoading ? true : false}>
      <div style={{ padding: "1em" }}>
        <h2 className="text-heading" style={{ textAlign: "center" }}>
          New Purchase Order
        </h2>
        <Form layout="inline">
          <Form.Item
            style={{ paddingRight: 20, paddingBottom: 10 }}
            label="Date"
            required
          >
            <DatePicker
              format={dateFormat}
              placeholder="Enter Purchase Date"
              value={orderDate}
              onChange={dateChange}
            />
          </Form.Item>
          <Form.Item style={{ paddingRight: 20 }}>
            <VendorList
              PoClaim={props.PoClaim}
              handleSubmit={handleChoose}
              dropDownSelect={dropDownSelectVendor}
              value={vendorType}
            />
          </Form.Item>
          <Form.Item label="Order" style={{ paddingBottom: 20 }}>
            <Input
              style={{ maxWidth: 500 }}
              placeholder="Enter Purchase Order"
              allowClear
              onBlur={() =>
                poNumber
                  ? addPurchaseOrder({ poNumber: poNumber.toUpperCase() })
                  : null
              }
              onChange={inputChange}
              value={poNumber}
            />
          </Form.Item>
        </Form>
        <LineItems PoClaim={props.PoClaim} />
        <div
          style={{
            marginBottom: 40,
            marginTop: 20,
            float: "right",
          }}
        >
          <Button onClick={addPoLineItemList} type="primary">
            Submit Order
          </Button>
        </div>
      </div>
    </Spinner>
  );
};

export default NewPurchaseOrder;
