import React from "react";
import { Avatar } from "antd";
import { colors } from "../../../../Shared/Helpers";

export const changeOptions = (obj, value, desc) => {
  for (var i in obj) {
    if (obj[i].value !== value) {
      obj[i].disabled = desc;
    }
    if (obj[i].value === 7) {
      obj[i].disabled = true;
    }
  }
};

export const avatar = (name, email, color, isGroup) => {
  const letters = name.split(" ");
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        height: 50,
      }}
    >
      <div style={{ marginRight: 10, paddingTop: 10 }}>
        <Avatar size="large" style={{ backgroundColor: colors[color] }}>
          {isGroup
            ? name[0]
            : `${letters[0][0]}
          ${letters[1][0]}`}
        </Avatar>
      </div>
      <div>
        <h3 style={{ color: "whitesmoke" }}>{name}</h3>
        <p>{email}</p>
      </div>
    </div>
  );
};
