import React, { useEffect } from "react";
import { InboxOutlined } from '@ant-design/icons';
import { Form } from "antd";
import { Input, InputNumber, Checkbox, Radio, Upload, DatePicker, message } from "antd";
import useGlobal from "../../../../GlobalState/Store";
import LicenseTypeList from "../../LicenseTypeList";
import KeyTypeList from "../../KeyTypeList";
import { dateFormat } from "../../../../Shared/Helpers";
import PublisherList from "../../PublisherList";
import SoftwareList from "../../SoftwareList";
import { uploadEnvCheck } from "../../../../Apollo/Apollo";
import OrgList from "../OrgList";

const NewLineItem = (props) => {
  const [globalState, globalActions] = useGlobal();
  const { addPoLineItem } = globalActions;
  const {
    authState: { rawToken, accessToken },
    purchaseOrder: {
      poLineItem: {
        publisherType,
        softwareType,
        licenseType,
        orgType,
        installType,
        installNumber,
        checkBox,
        concurrency,
        installsPerSeat,
        lineItemCost,
        maintainanceCostPerSeat,
        maintainanceStart,
        expDate,
        keyType,
        keyData,
        attachFile,
        attachReg,
      },
    },
  } = globalState;
  useEffect(() => {});
  const onCheck = (e) => {
    const checkBox = e.target.checked;
    addPoLineItem({ checkBox });
    if (checkBox) {
      addPoLineItem({ installNumber: null });
    }
    if (!checkBox) {
      addPoLineItem({ installNumber: 1 });
    }
  };

  const concurrencyChange = (e) => {
    const concurrency = e.target.checked;
    addPoLineItem({ concurrency });
  };
  const dateChange = (e) => {
    addPoLineItem({ expDate: e });
  };

  const maintainanceStartChange = (e) => {
    addPoLineItem({ maintainanceStart: e });
  };
  const updateAttachedFiles = (info, type) => {
    let fileList = [...info.fileList];
    fileList = fileList.map((e) => {
      delete e.originFileObj;
      delete e.lastModified;
      delete e.lastModifiedDate;
      delete e.percent;
      delete e.response;
      return e;
    });
    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    //fileList = fileList.slice(-2);

    fileList = fileList.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.name === thing.name)
    );
    if (type === "File") {
      addPoLineItem({ attachFile: fileList });
    } else if (type === "RegKey") {
      addPoLineItem({ attachReg: fileList });
    }
  };
  const changeKeyType = (e) => {
    e.persist();
    let value = e.target.value;
    addPoLineItem({ keyData: value });
    //TODO: before writing to db use regex
    //console.log(keyData.match(/[^\r\n]+/g));
  };
  const selectLicenseType = (e) => {
    addPoLineItem({ licenseType: e });
  };
  const selectOrg = (e) => {
    addPoLineItem({ orgType: e });
  };
  const installTypeChange = (e) => {
    addPoLineItem({ installType: e.target.value });
  };
  const inputNumberChange = (e) => {
    addPoLineItem({ installNumber: e });
  };

  const installsPerSeatChange = (e) => {
    addPoLineItem({ installsPerSeat: e });
  };

  const keyTypeChange = (e) => {
    /* if (
      (e.label === "No Key" || e.label === "File" || e.label === "RegKey") &&
      keyData
    ) {
      addPoLineItem({ keyData: null });
    } */
    addPoLineItem({ keyType: e });
  };

  const maintainanceCostChange = (e) => {
    const number = parseFloat(e).toFixed(2);
    if (!isNaN(number)) {
      addPoLineItem({ maintainanceCostPerSeat: number });
    } else if (isNaN(number)) {
      addPoLineItem({ maintainanceCostPerSeat: null });
    }
  };

  const licenseCostChange = (e) => {
    const number = parseFloat(e).toFixed(2);
    if (!isNaN(number)) {
      addPoLineItem({ lineItemCost: number });
    } else if (isNaN(number)) {
      addPoLineItem({ lineItemCost: null });
    }
  };

  const dropDownSelectPublisher = (e) => {
    if (publisherType !== e) {
      addPoLineItem({ softwareType: undefined });
    }
    addPoLineItem({ publisherType: e });
  };
  const dropDownSelectSoftware = (e) => {
    addPoLineItem({ softwareType: e });
  };

  const beforeUpload = (a, type) => {
    const dup = attachFile.find((i) => i.name === a.name);
    if (type === "RegKey") {
      if (a.name.length > 3 && a.name.substr(a.name.length - 4) !== ".reg") {
        message.error("This is not a valid registry file.");
        return false;
      }
    } else if (a.size > 5242880) {
      message.error("This file exceeds 5MB limit.");
      return false;
    } else if (dup) {
      message.error("Duplicate filename!");
      return false;
    } else {
      return true;
    }
  };

  const uploadChange = (info, type) => {
    const under5mb = info.file.size < 5242880;
    if (
      type === "RegKey" &&
      info.file.name.length > 3 &&
      info.file.name.substr(info.file.name.length - 4) === ".reg" &&
      under5mb
    ) {
      updateAttachedFiles(info, type);
    }
    if (type === "File" && under5mb) {
      updateAttachedFiles(info, type);
    }
  };

  const dataHelper = (e) => {
    let data = {};
    data.uid = e.uid;
    return data;
  };

  const keyTypeHelper = () => {
    if (keyType && keyType.label === "String") {
      return (
        <Form layout="inline">
          <Form.Item label="Key data:">
            <Input.TextArea
              style={{ width: 325 }}
              rows={4}
              name=""
              onChange={changeKeyType}
              value={keyData}
            />
          </Form.Item>
        </Form>
      );
    }
    if (keyType && keyType.label === "File") {
      /* if (dropdown !== "File") {
        setDropdown("File");
         if (attachFile.length > 0) {
          addPoLineItem({ attachFile: [] });
        }
      }*/
      return (
        <Form layout="inline">
          <Form.Item label="Key data">
            <Upload.Dragger
              style={{ width: 325 }}
              name="file"
              data={dataHelper}
              multiple={true}
              beforeUpload={(e) => beforeUpload(e, keyType.label)}
              action={uploadEnvCheck()}
              headers={{
                Authorization: rawToken ? `Bearer ${rawToken}` : "",
                Access: accessToken,
              }}
              onChange={(e) => uploadChange(e, keyType.label)}
              fileList={attachFile}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                There is a 5MB upload limit per attached file.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      );
    }
    if (keyType && keyType.label === "RegKey") {
      /* if (dropdown !== "RegKey") {
        setDropdown("RegKey");
        if (attachFile.length > 0) {
          addPoLineItem({ attachFile: [] });
        }
      }*/
      return (
        <Form layout="inline">
          <Form.Item label="Key data">
            <Upload.Dragger
              style={{ width: 325 }}
              name="file"
              data={dataHelper}
              fileList={attachReg}
              multiple={true}
              beforeUpload={(e) => beforeUpload(e, keyType.label)}
              action={uploadEnvCheck()}
              headers={{
                Authorization: rawToken ? `Bearer ${rawToken}` : "",
                Access: accessToken,
              }}
              onChange={(e) => uploadChange(e, keyType.label)}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                There is a 5MB upload limit per attached file
              </p>
              <p className="ant-upload-hint">Only .reg files allowed</p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      );
    }
  };

  return (
    <div style={{ paddingLeft: 20 }}>
      <OrgList value={orgType} orgSelect={selectOrg} />
      <PublisherList
        PoClaim={props.PoClaim}
        dropDownSelect={dropDownSelectPublisher}
        value={publisherType}
        /* handleSubmit={handleChoose}
         */
      />
      <SoftwareList
        guid={publisherType !== undefined ? [publisherType.key] : []}
        PoClaim={props.PoClaim}
        dropDownSelect={dropDownSelectSoftware}
        value={softwareType}
      />
      <LicenseTypeList value={licenseType} dropDownSelect={selectLicenseType} />

      <Form layout="inline">
        <Form.Item label="Install Per" required>
          <Radio.Group
            defaultValue="User"
            buttonStyle="solid"
            onChange={installTypeChange}
            value={installType}
          >
            <Radio.Button value="User">User</Radio.Button>
            <Radio.Button value="Machine">Machine</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
      <Form layout="inline">
        <Form.Item
          label="Number of Seats"
          required
          style={{ paddingRight: 10 }}
        >
          <InputNumber
            disabled={checkBox}
            name=""
            min={1}
            onChange={inputNumberChange}
            defaultValue={1}
            value={installNumber}
          />
        </Form.Item>
        <Form.Item label="Check for Unlimited">
          <Checkbox onChange={onCheck} checked={checkBox} />
        </Form.Item>
      </Form>
      {installType === "User" ? (
        <Form layout="inline">
          <Form.Item label="Concurrent Usage Allowed">
            <Checkbox onChange={concurrencyChange} checked={concurrency} />
          </Form.Item>
        </Form>
      ) : null}
      {installType === "User" ? (
        !concurrency ? (
          <Form layout="inline">
            <Form.Item
              label="Installs per Seat"
              style={{ paddingRight: 10 }}
              required
            >
              <InputNumber
                name=""
                min={1}
                defaultValue={1}
                onChange={installsPerSeatChange}
                value={installsPerSeat}
              />
            </Form.Item>
          </Form>
        ) : null
      ) : null}
      <Form layout="inline" style={{}}>
        <Form.Item label="Cost (per seat)" required>
          <InputNumber
            min={0}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={licenseCostChange}
            value={lineItemCost}
            style={{ width: 150 }}
          />
        </Form.Item>
      </Form>
      <Form layout="inline">
        <Form.Item label="Maintenance Cost (per seat)">
          <InputNumber
            min={0}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={maintainanceCostChange}
            value={maintainanceCostPerSeat}
            style={{ width: 125 }}
          />
        </Form.Item>
      </Form>
      <Form layout="inline" style={{}}>
        <Form.Item label="Maintenance Start">
          <DatePicker
            format={dateFormat}
            placeholder="Select date"
            value={maintainanceStart}
            onChange={maintainanceStartChange}
          />
        </Form.Item>
      </Form>
      <Form layout="inline" style={{}}>
        <Form.Item
          label={
            licenseType
              ? licenseType.label === "Perpetual"
                ? "Maintenance Renewal Date"
                : "Expiration Date"
              : "Expiration Date"
          }
        >
          <DatePicker
            format={dateFormat}
            placeholder="Select date"
            value={expDate}
            onChange={dateChange}
          />
        </Form.Item>
      </Form>
      <KeyTypeList value={keyType} keyTypeChange={keyTypeChange} />

      {keyTypeHelper()}
    </div>
  );
};

export default NewLineItem;
