import React, { useState } from "react";
import { InboxOutlined } from '@ant-design/icons';
import { Form } from "antd";
import { Input, InputNumber, Upload, DatePicker, message, Checkbox } from "antd";
import useGlobal from "../../../../GlobalState/Store";
import KeyTypeList from "../../KeyTypeList";
import { dateFormat } from "../../../../Shared/Helpers";
import PublisherList from "../../PublisherList";
import SoftwareList from "../../SoftwareList";
import Keys from "../Keys";
import moment from "moment";
import { uploadEnvCheck } from "../../../../Apollo/Apollo";

const MaintainanceLineItem = (props) => {
  const [globalState, globalActions] = useGlobal();
  const [display, setDisplay] = useState(true);

  const { addPoLineItem } = globalActions;
  const {
    authState: { rawToken, accessToken },
    purchaseOrder: {
      poLineItem: {
        publisherType,
        softwareType,
        /* licenseType,
        installType, */
        concurrency,
        installsPerSeat,
        keyToMaintain,
        lineItemCost,
        maintainanceCostPerSeat,
        maintainanceStart,
        expDate,
        keyType,
        keyData,
        attachFile,
        attachReg,
      },
    },
  } = globalState;

  const dateChange = (e) => {
    addPoLineItem({ expDate: e });
  };

  const maintainanceStartChange = (e) => {
    addPoLineItem({ maintainanceStart: e });
  };

  const updateAttachedFiles = (info, type) => {
    let fileList = [...info.fileList];
    fileList = fileList.map((e) => {
      delete e.originFileObj;
      delete e.lastModified;
      delete e.lastModifiedDate;
      delete e.percent;
      delete e.response;
      return e;
    });

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    //fileList = fileList.slice(-2);

    fileList = fileList.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.name === thing.name)
    );
    if (type === "File") {
      addPoLineItem({ attachFile: fileList });
    } else if (type === "RegKey") {
      addPoLineItem({ attachReg: fileList });
    }
  };
  const changeKeyType = (e) => {
    e.persist();
    let value = e.target.value;
    addPoLineItem({ keyData: value });
    //TODO: before writing to db use regex
    //console.log(keyData.match(/[^\r\n]+/g));
  };

  const prePopulateMaintenanceInfo = (e, data) => {
    let rec = data.filter((i) => i.id === e.key);
    rec = rec[0];
    if (rec.cost) {
      licenseCostChange(rec.cost);
    }
    if (rec.isUserBased) {
      setDisplay(true);
      addPoLineItem({ concurrency: rec.concurrentUsage });
      addPoLineItem({ installsPerSeat: rec.installsPerSeat });
    } else {
      setDisplay(false);
    }
    if (rec.maintainanceCost) {
      maintainanceCostChange(rec.maintainanceCost);
    }
    if (rec.keyType) {
      keyTypeChange({ key: rec.keyTypeId, label: rec.keyType });
    }
    if (rec.keyType === "String") {
      addPoLineItem({ keyData: rec.keyString });
    }
    let attachedFiles = rec.attachedFilename;
    attachedFiles = attachedFiles.map((e) => {
      delete e.__typename;
      return e;
    });
    if (attachedFiles.length > 0) {
      if (rec.keyType === "File") {
        addPoLineItem({ attachFile: attachedFiles });
      }
      if (rec.keyType === "RegKey") {
        addPoLineItem({ attachReg: attachedFiles });
      }
    }
    maintainanceStartChange(
      rec.expiration ? moment(rec.expiration, "MM/DD/YYYY") : null
    );
  };

  const concurrencyChange = (e) => {
    const concurrency = e.target.checked;
    addPoLineItem({ concurrency });
  };
  const installsPerSeatChange = (e) => {
    addPoLineItem({ installsPerSeat: e });
  };

  const selectKeyToMaintain = (e, data) => {
    if (e.key) {
      addPoLineItem({ keyToMaintain: { key: e.key, label: e.label[1] } });
    } else {
      addPoLineItem({ keyToMaintain: [] });
    }
    if (data) {
      prePopulateMaintenanceInfo(e, data);
    }
  };
  const keyTypeChange = (e) => {
    addPoLineItem({ keyType: e });
  };

  const maintainanceCostChange = (e) => {
    const number = parseFloat(e).toFixed(2);
    if (!isNaN(number)) {
      addPoLineItem({ maintainanceCostPerSeat: number });
    } else if (isNaN(number)) {
      addPoLineItem({ maintainanceCostPerSeat: null });
    }
  };

  const licenseCostChange = (e) => {
    const number = parseFloat(e).toFixed(2);
    if (!isNaN(number)) {
      addPoLineItem({ lineItemCost: number });
    } else if (isNaN(number)) {
      addPoLineItem({ lineItemCost: null });
    }
  };

  const dropDownSelectPublisher = (e) => {
    if (publisherType !== e) {
      addPoLineItem({ softwareType: undefined });
    }
    addPoLineItem({ publisherType: e });
  };
  const dropDownSelectSoftware = (e) => {
    addPoLineItem({ softwareType: e });
  };

  const beforeUpload = (a, type) => {
    const dup = attachFile.find((i) => i.name === a.name);
    if (type === "RegKey") {
      if (a.name.length > 3 && a.name.substr(a.name.length - 4) !== ".reg") {
        message.error("This is not a valid registry file.");
        return false;
      }
    } else if (a.size > 5242880) {
      message.error("This file exceeds 5MB limit.");
      return false;
    } else if (dup) {
      message.error("Duplicate filename!");
      return false;
    } else {
      return true;
    }
  };

  const uploadChange = (info, type) => {
    const under5mb = info.file.size < 5242880;
    if (
      type === "RegKey" &&
      info.file.name.length > 3 &&
      info.file.name.substr(info.file.name.length - 4) === ".reg" &&
      under5mb
    ) {
      updateAttachedFiles(info, type);
    }
    if (type === "File" && under5mb) {
      updateAttachedFiles(info, type);
    }
  };

  const dataHelper = (e) => {
    let data = {};
    data.uid = e.uid;
    return data;
  };

  const keyTypeHelper = () => {
    if (keyType && keyType.label === "String") {
      return (
        <Form layout="inline">
          <Form.Item label="Key data:">
            <Input.TextArea
              style={{ width: 325 }}
              rows={4}
              name=""
              onChange={changeKeyType}
              value={keyData}
            />
          </Form.Item>
        </Form>
      );
    }
    if (keyType && keyType.label === "File") {
      /* if (dropdown !== "File") {
        setDropdown("File");
         if (attachFile.length > 0) {
          addPoLineItem({ attachFile: [] });
        }
      }*/

      return (
        <Form layout="inline">
          <Form.Item label="Key data">
            <Upload.Dragger
              style={{ width: 325 }}
              name="file"
              data={dataHelper}
              multiple={true}
              beforeUpload={(e) => beforeUpload(e, keyType.label)}
              action={uploadEnvCheck()}
              headers={{
                Authorization: rawToken ? `Bearer ${rawToken}` : "",
                Access: accessToken,
              }}
              onChange={(e) => uploadChange(e, keyType.label)}
              fileList={attachFile}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                There is a 5MB upload limit per attached file.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      );
    }
    if (keyType && keyType.label === "RegKey") {
      /* if (dropdown !== "RegKey") {
        setDropdown("RegKey");
        if (attachFile.length > 0) {
          addPoLineItem({ attachFile: [] });
        }
      }*/
      return (
        <Form layout="inline">
          <Form.Item label="Key data">
            <Upload.Dragger
              style={{ width: 325 }}
              name="file"
              data={dataHelper}
              fileList={attachReg}
              multiple={true}
              action={uploadEnvCheck()}
              headers={{
                Authorization: rawToken ? `Bearer ${rawToken}` : "",
                Access: accessToken,
              }}
              onChange={(e) => uploadChange(e, keyType.label)}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                There is a 5MB upload limit per attached file
              </p>
              <p className="ant-upload-hint">Only .reg files allowed</p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      );
    }
  };

  return (
    <div style={{ paddingLeft: 20 }}>
      <PublisherList
        PoClaim={props.PoClaim}
        dropDownSelect={dropDownSelectPublisher}
        value={publisherType}
        /* handleSubmit={handleChoose}
         */
      />
      <SoftwareList
        guid={publisherType !== undefined ? [publisherType.key] : []}
        PoClaim={props.PoClaim}
        dropDownSelect={dropDownSelectSoftware}
        value={softwareType}
      />
      <Keys
        softwareType={softwareType}
        value={keyToMaintain}
        dropDownSelect={selectKeyToMaintain}
        label="Maintain Key"
      />
      {display ? (
        <Form layout="inline">
          <Form.Item label="Concurrent Usage Allowed">
            <Checkbox onChange={concurrencyChange} checked={concurrency} />
          </Form.Item>
        </Form>
      ) : null}
      {display ? (
        <Form layout="inline">
          <Form.Item
            label="Installs per Seat"
            style={{ paddingRight: 10 }}
            required
          >
            <InputNumber
              name=""
              min={1}
              onChange={installsPerSeatChange}
              defaultValue={installsPerSeat}
              value={installsPerSeat}
            />
          </Form.Item>
        </Form>
      ) : null}
      <Form layout="inline">
        <Form.Item label="Cost" required>
          <InputNumber
            min={0}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={licenseCostChange}
            value={lineItemCost}
            style={{ width: 150 }}
          />
        </Form.Item>
      </Form>
      <Form layout="inline">
        <Form.Item required label="Maintenance Cost (per seat)">
          <InputNumber
            min={0}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={maintainanceCostChange}
            value={maintainanceCostPerSeat}
            style={{ width: 125 }}
          />
        </Form.Item>
      </Form>
      <Form layout="inline" style={{}}>
        <Form.Item label="Maintenance Start" required>
          <DatePicker
            format={dateFormat}
            placeholder="Select date"
            value={maintainanceStart}
            onChange={maintainanceStartChange}
          />
        </Form.Item>
      </Form>
      <Form layout="inline" style={{}}>
        <Form.Item label="Maintenance Renewal Date" required>
          <DatePicker
            format={dateFormat}
            placeholder="Select date"
            value={expDate}
            onChange={dateChange}
          />
        </Form.Item>
      </Form>
      <KeyTypeList
        required={true}
        value={keyType}
        keyTypeChange={keyTypeChange}
      />

      {keyTypeHelper()}
    </div>
  );
};

export default MaintainanceLineItem;
