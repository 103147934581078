import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button } from "antd";
import PermissionItem from "./PermissionItem";
import OrgSelect from "./OrgSelect";
import { options, permissions } from "./PermissionOptions";
import useGlobal from "../../../../GlobalState/Store";
import AdminCheckbox from "./AdminCheckbox";

const PermissionTable = (props) => {
  const [state, setState] = useState({
    btn: [],
    orgList: [],
    stateList: [],
    seedData: [],
  });

  const [isAdmin, setisAdmin] = useState(false);
  const [adminClaim, setadminClaim] = useState(false);
  const isAdminChange = (e) => {
    setisAdmin(e.target.checked);
  };
  const globalState = useGlobal()[0];
  const { authClaims } = globalState;

  useEffect(() => {
    const claim = authClaims.filter(
      (i) => i.moduleId === 11 && i.authTypeId === 17
    );
    if (claim.length > 0) {
      setadminClaim(true);
    }
  }, [authClaims]);

  const ref = useRef(state);
  const { updateParent, edit, authAccesses } = props;
  useEffect(() => {
    if (edit) {
      const uniqueAuth = Array.from(
        new Set(authAccesses.map((a) => a.ops.id))
      ).map((id) => {
        return authAccesses.find((a) => a.ops.id === id);
      });
      const getOrgPerms = (id) => {
        return authAccesses.filter((i) => i.ops.id === id);
      };
      const buildPrem = (ele) => {
        var existing = state.btn.find((i) => parseInt(i.key) === ele.ops.id);
        if (!existing) {
          const ops = { key: ele.ops.id, label: ele.ops.subRegion };
          const seedData = state.seedData;
          seedData.push(ops);
          setState({ ...state, seedData });
          const perms = getOrgPerms(ele.ops.id);
          addPermission(ops, perms);
        }
      };
      uniqueAuth.forEach((ele) => {
        if (adminClaim) {
          if (ele.moduleId !== 11 && ele.authTypeId !== 17) {
            buildPrem(ele);
          } else {
            setisAdmin(true);
          }
        } else {
          const match = authClaims.filter(
            (i) =>
              i.ops.id === ele.ops.id && i.moduleId === 8 && i.authTypeId === 12
          );
          if (match.length > 0) {
            buildPrem(ele);
          }
        }
      });
    }
  }, [adminClaim]);
  useEffect(() => {
    if (updateParent) {
      updateParent(state.stateList, isAdmin);
    }
  }, [state, isAdmin]);
  const updateItem = (childState, org) => {
    const i = ref.current.stateList.findIndex((i) => i.org.key === org.key);
    let newList = ref.current.stateList;
    newList[i] = { org, state: childState };
    setState({ ...ref.current, stateList: newList });
  };
  const addPermission = (e, perm) => {
    let btn = state.btn;
    let stateList = state.stateList;
    stateList.push({ org: e, state: { options, permissions } });
    btn.push(
      <Col xs={12} sm={8} md={6} lg={6} xl={4} xxl={3} key={e.key}>
        <div
          style={{
            borderLeft: "thin solid gray",
            borderRight: "thin solid gray",
            borderBottom: "thin solid gray",
          }}
        >
          <hr />
          <h3 style={{ color: "whitesmoke", textAlign: "center" }}>
            {e.label}
          </h3>
          <hr />
          <div style={{ paddingLeft: 10 }}>
            <PermissionItem edit={perm} org={e} updateParent={updateItem} />
          </div>
        </div>
      </Col>
    );
    setState({ ...state, btn, stateList });
  };

  const parentHelper = (orgList, org) => {
    setState({ ...state, orgList });
  };
  const removePermission = (e) => {
    let btn = state.btn;
    let stateList = state.stateList;
    const newList = stateList.filter((i) => i.org.key !== e.key);
    const newBtn = btn.filter((i) => parseInt(i.key) !== e.key);
    setState((previousState) => ({
      ...previousState,
      btn: newBtn,
      stateList: newList,
    }));
    ref.current = {
      ...state,
      btn: newBtn,
      stateList: newList,
    };
  };
  return (
    <div style={{ marginTop: 20 }}>
      {adminClaim ? (
        <Row>
          <Col span={24}>
            <AdminCheckbox isAdmin={isAdmin} isAdminChange={isAdminChange} />
          </Col>
        </Row>
      ) : null}
      {!isAdmin ? (
        <>
          <Row>
            <Col span={24}>
              <OrgSelect
                isAdmin={adminClaim}
                edit={edit ? state.seedData : false}
                add={addPermission}
                remove={removePermission}
                updateParent={parentHelper}
              />
            </Col>
          </Row>
          <Row gutter={[0, 24]}>{state.btn}</Row>
        </>
      ) : null}
    </div>
  );
};

export default PermissionTable;
