import { gql } from "apollo-boost";

export const ADDPUBLISHER_MUTATION = gql`
  mutation($publisher: PublisherInput!) {
    createPublisher(publisher: $publisher) {
      id
      name
      kbUrl
      supportContact
      kbUrl
      notes
    }
  }
`;

export const ADDTOKEN_MUTATION = gql`
  mutation($token: TokenInput!) {
    createToken(token: $token) {
      id
      name
    }
  }
`;

export const REMOVE_APITOKEN_MUTATION = gql`
  mutation($id: ID!) {
    deleteToken(token: $id) {
      id
    }
  }
`;

export const ADDVENDOR_MUTATION = gql`
  mutation($vendor: VendorInput!) {
    createVendor(vendor: $vendor) {
      id
      name
      kbUrl
      supportContact
      kbUrl
      notes
    }
  }
`;

export const ADDSOFTWARE_MUTATION = gql`
  mutation($software: SoftwareInput!) {
    createSoftware(software: $software) {
      id
      name
      publisherId
      publisherName
      version
      color
    }
  }
`;

export const UPDATESOFTWARE_MUTATION = gql`
  mutation($software: SoftwareInput!) {
    updateSoftware(software: $software) {
      id
      name
      publisherId
      publisherName
      version
      color
    }
  }
`;

export const NEWPO_MUTATION = gql`
  mutation($var: PoLineItemInputType) {
    addNewPurchaseOrder(poLineItems: $var)
  }
`;

export const UPDATE_ENDDATE_MUTATION = gql`
  mutation($keyuse: KeyUseInput!, $keyDataId: ID) {
    updateKeyUse(keyUse: $keyuse, keyDataId: $keyDataId) {
      userEndDate
      id
    }
  }
`;

export const REMOVE_KEYUSE_MUTATION = gql`
  mutation($id: ID!, $keyDataId: ID) {
    deleteKeyUse(id: $id, keyDataId: $keyDataId) {
      id
      systemName
      userNt
    }
  }
`;

export const REMOVE_KEYUSECOMPUTER_MUTATION = gql`
  mutation($id: ID!, $keyDataId: ID) {
    deleteKeyComputer(id: $id, keyDataId: $keyDataId) {
      userEndDate
      computers {
        id
        computerName
        keyUseId
      }
    }
  }
`;

export const ASSIGN_KEYS_MUTATION = gql`
  mutation($keyuse: KeyUseInput!, $keyDataId: ID) {
    assignNewKey(keyUse: $keyuse, keyDataId: $keyDataId) {
      id
      userNt
      systemName
      userStartDate
      userEndDate
      keyDataId
      visible
    }
  }
`;

export const UPLOAD_MUTATION = gql`
  mutation($args: String!) {
    uploadFile(args: $args)
  }
`;

// TODO: Azure Migration DONE - Add azureObjectId to variable and UserAccessInputType
export const ADDUSERACCESS_MUTATION = gql`
  mutation($params: UserAccessInputType!) {
    addUserAccess(addUser: $params) {
      azureObjectId
      pidKey
      name
      email
      ntlogon
      color
    }
  }
`;

// TODO: Azure Migration DONE - Retain Original, Change Signature to xREMOVEUSERACCESS_MUTATION 
export const xREMOVEUSERACCESS_MUTATION = gql`
  mutation($pidkeys: [Int]!) {
    deleteUserAccess(pidkeys: $pidkeys) {
      pidKey
      name
    }
  }
`;

// TODO: Azure Migration DONE - Change variable from pidkeys to azureObjectIds, add azureObjectId field
export const REMOVEUSERACCESS_MUTATION = gql`
  mutation($azureObjectIds: [Guid]!) {
    deleteUserAccess(azureObjectIds: $azureObjectIds) {
      azureObjectId
      name
    }
  }
`;

export const ADDGROUPACCESS_MUTATION = gql`
  mutation($params: GroupAccessInputType!) {
    addGroupAccess(addGroup: $params) {
      id
      name
      sid
      description
      color
    }
  }
`;

export const REMOVEGROUPACCESS_MUTATION = gql`
  mutation($groupIds: [Int]!) {
    deleteGroupAccess(groupIds: $groupIds) {
      id
      name
    }
  }
`;

export const UPDATEVENDOR_MUTATION = gql`
  mutation($vendor: VendorInput!) {
    updateVendor(vendor: $vendor) {
      id
      name
      supportContact
      kbUrl
      notes
    }
  }
`;

export const UPDATEPUBLISHER_MUTATION = gql`
  mutation($publisher: PublisherInput!) {
    updatePublisher(publisher: $publisher) {
      id
      name
      supportContact
      kbUrl
      notes
    }
  }
`;
export const ADDASSIGNMENT_MUTATION = gql`
  mutation($assign: AssignmentInput!) {
    addAppAssignment(assignment: $assign) {
      id
      licenseId
      publisher {
        id
        name
      }
      software {
        id
        name
        version
      }
      sccmName
      appModelId
      collectionId
      collectionName
      collectionId
      color
      ops {
        id
        subRegion
      }
    }
  }
`;

export const DELETEAPPASSIGNMENT_MUTATION = gql`
  mutation($id: ID!) {
    deleteAppAssignment(id: $id) {
      id
    }
  }
`;
export const UPDATEASSIGNMENT_MUTATION = gql`
  mutation($update: UpdateAssignmentInput!) {
    updateAppAssignment(assignment: $update) {
      id
      publisher {
        id
        name
      }
      software {
        id
        name
        version
      }
      sccmName
      appModelId
      collectionId
      collectionName
      collectionId
      color
      ops {
        id
        subRegion
      }
    }
  }
`;
// TODO: Azure Migration DONE - Add azureObjectId to variable and PermissionsInputType
// FIXME: Azure Migration DONE - Change opsCenter to opsCenter1
// FIXME: Azure Migration DONE - Missing "value" field in input parameter - Added "value" field to KeyValueInputTypeInt in GraphQL Server 
export const ADDNEWUSERPERMISSIONS_MUTATION = gql`
  mutation($var: PermissionsInputType!) {
    addNewUserPermissions(permissions: $var) {
      azureObjectId
      pidKey
      name
      email
      ntlogon
      color
      ops {
        id
        opsCenter1
        subRegion
      }
    }
  }
`;

// TODO: Azure Migration DONE - Add azureObjectId to variable and PermissionsInputType
// FIXME: Azure Migration DONE - Change opsCenter to opsCenter1
// FIXME: Azure Migration DONE - Missing "value" field in input parameter - Added "value" field to KeyValueInputTypeInt in GraphQL Server
export const UPDATEPERMISSIONS_MUTATION = gql`
  mutation($var: PermissionsInputType!) {
    updatePermissions(permissions: $var) {
      azureObjectId
      pidKey
      name
      email
      ntlogon
      color
      ops {
        id
        opsCenter1
        subRegion
      }
    }
  }
`;

// FIXME: Azure Migration DONE - Change opsCenter to opsCenter1
export const ADDNEWGROUPPERMISSIONS_MUTATION = gql`
  mutation($var: PermissionsInputType!) {
    addNewGroupPermissions(permissions: $var) {
      id
      name
      sid
      description
      color
      ops {
        id
        opsCenter1
        subRegion
      }
    }
  }
`;

// FIXME: Azure Migration DONE - Change opsCenter to opsCenter1
export const UPDATEGROUPPERMISSIONS_MUTATION = gql`
  mutation($var: PermissionsInputType!) {
    updateGroupPermissions(permissions: $var) {
      id
      name
      sid
      description
      color
      ops {
        id
        opsCenter1
        subRegion
      }
    }
  }
`;
