import React, { useState } from "react";
import { Table } from "antd";
import { KEYSBYEXP_QUERY } from "../../Apollo/Queries";
import { Query } from "react-apollo";
import moment from "moment";
import { Responsive } from "../Helpers";
import useGlobal from "../../GlobalState/Store";
import { Link } from "react-router-dom";

function ExpiringIn90Days(props) {
  const [isTablet, isMobil] = Responsive();
  
  const globalState = useGlobal()[0];

  const { opsList } = globalState;
  const columns = [
    {
      title: "Software",
      dataIndex: "softwareName",
      align: "center",
      key: "softwareName",
      render: (text, rec) => {
        return (
          <Link
            style={{ textDecoration: "underline" }}
            to={`/BrowsebyId/${rec.softId}`}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: "License",
      align: "center",
      dataIndex: "licenseType",
      key: "licenseType",
    },
    {
      title: "Expiration Date",
      align: "center",
      dataIndex: "expirationDate",
      key: "expirationDate",
      render: (text) => {
        return moment(text).format("MM/DD/YYYY");
      },
    },
    {
      title: "Key Usage",
      align: "center",
      dataIndex: "seatsInUse",
      key: "seatsInUse",
      render: (text, rec) => {
        return `${text}/${rec.seatsAvailable}`;
      },
    },
  ];

  const columnsHelper = () => {
    if (isMobil) {
      return columns.filter(
        (i) => i.key !== "seatsInUse" && i.key !== "licenseType"
      );
    }
    return columns;
  };

  return (
    <div>
      <Query
        query={KEYSBYEXP_QUERY}
        variables={{
          opsList,
        }}
      >
        {({ data, loading, error }) => {
          if (error) return <p>ERROR</p>;
          return (
            <div className="Dashboard">
              <Table
                title={() => (
                  <div
                    className="ant-descriptions-title"
                    style={{ marginBottom: 0, background: "#8c8c8c" }}
                  >
                    Expiring in 90 days
                  </div>
                )}
                style={{
                  backgroundColor: "#3d4144",
                  height: 300,
                }}
                rowKey={(rec) => rec.id}
                columns={columnsHelper()}
                dataSource={data.getExpiringKeys}
                size="middle"
                pagination={false}
                loading={loading}
              />
            </div>
          );
        }}
      </Query>
    </div>
  );
}
export default ExpiringIn90Days;
