import React, { useState } from "react";
import ModalPopup from "../../ModalPopup/ModalPopup";
import { Input, Select, message, Form } from "antd";
import { ADDSOFTWARE_MUTATION } from "../../../Apollo/Mutations";
import {
  PUBLISHERLIST_QUERY,
  SCCMSEARCH_QUERY,
  SOFTWAREPUBLISHERLIST_QUERY,
  SOFTWARELISTBYPUBLISHERID_QUERY,
} from "../../../Apollo/Queries";
import { graphql, Query } from "react-apollo";
import Spinner from "../../../Shared/Spinner/Spinner";
import { flowRight as compose } from "lodash";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const inputs = [
  {
    id: 1,
    displayName: "Publisher",
    name: "publisher",
  },
  {
    id: 2,
    displayName: "Software Name",
    name: "name",
  },
  {
    id: 3,
    displayName: "Version",
    name: "version",
  },
];

const AddSoftware = (props) => {
  const [name, setName] = useState(null);
  const [publisher, setPublisher] = useState([]);
  const [version, setVersion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [inputValidationStatus, setInputValidationStatus] = useState("");
  const [inputValidationMessage, setInputValidationMessage] = useState("");

  const startLoading = () => {
    setLoading(true);
  };
  const stopLoading = () => {
    setLoading(false);
  };

  const onModalClose = () => {
    setName(null);
    setPublisher(null);
    setVersion(null);
    setVisible(false);
    setInputValidationMessage("");
    setInputValidationStatus("");
  };
  const dropDownSelect = (e) => {
    setPublisher(e);
  };
  const onChange = (e) => {
    let value = e.target.value;
    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case "name":
        setName(value);
        break;
      case "publisher":
        setPublisher(value);
        break;
      case "version":
        setVersion(value);
        break;
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
    onModalClose();
  };
  const addSoftware = () => {
    if (!name || !publisher) {
      setInputValidationMessage("This field cannot be empty.");
      setInputValidationStatus("error");
    }
    if (name && publisher) {
      startLoading();
      props
        .mutate({
          variables: {
            software: {
              name: name,
              publisherId: publisher.key,
              version: !version ? "" : version,
            },
          },
          update: (store, { data: { createSoftware } }) => {
            let software = store.readQuery({
              query: SOFTWAREPUBLISHERLIST_QUERY,
            });
            if (createSoftware.name !== "Duplicate Record") {
              software = software.softwarePublisherList.unshift(createSoftware);
              store.writeQuery({
                query: SOFTWAREPUBLISHERLIST_QUERY,
                data: software,
              });
            }
          },
          refetchQueries: [
            {
              query: SOFTWARELISTBYPUBLISHERID_QUERY,
              variables: {
                publisher: [publisher.key],
              },
            },
          ],
        })
        .then(({ data }) => {
          if (data.createSoftware.name === "Duplicate Record") {
            message.error(data.createSoftware.name);
          } else {
            message.success(`${data.createSoftware.name} Added!`);
          }
          stopLoading();
          onModalClose();
        })
        .catch((error) => {
          message.error("Error! please try again.");
          stopLoading();
        });
    }
  };

  return (
    <div>
      <ModalPopup
        header="Add New Software"
        buttonText="Add New Software"
        name="Submit"
        submit={addSoftware}
        onModalClose={onModalClose}
        showModal={showModal}
        handleCancel={handleCancel}
        visible={visible}
      >
        <Spinner loading={loading}>
          <Form {...formItemLayout}>
            {inputs.map((p) => {
              if (p.name === "name") {
                return (
                  <Form.Item
                    key={p.id}
                    label={p.displayName}
                    validateStatus={inputValidationStatus}
                    help={inputValidationMessage}
                    required
                  >
                    <Input name={p.name} onChange={onChange} value={name} />
                  </Form.Item>
                );
              }
              if (p.name === "publisher") {
                return (
                  <Query key={p.id} query={PUBLISHERLIST_QUERY}>
                    {({ data, loading, error }) => {
                      if (loading) return <p>Loading...</p>;
                      if (error) return <p>ERROR</p>;
                      return (
                        <Form.Item
                          label={p.displayName}
                          validateStatus={inputValidationStatus}
                          help={inputValidationMessage}
                          required
                        >
                          <Select
                            autoFocus
                            showSearch
                            labelInValue
                            style={{ minWidth: 180 }}
                            onChange={dropDownSelect}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {data.publisherList
                              ? data.publisherList.map((l) => {
                                  return (
                                    <Select.Option key={l.id}>
                                      {l.name}
                                    </Select.Option>
                                  );
                                })
                              : null}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Query>
                );
              }
              if (p.name === "version") {
                return (
                  <Form.Item key={p.id} label={p.displayName}>
                    <Input name={p.name} onChange={onChange} value={version} />
                  </Form.Item>
                );
              }
            })}
          </Form>
        </Spinner>
      </ModalPopup>
    </div>
  );
};
const AddNewSoftware = compose(
  graphql(SCCMSEARCH_QUERY),
  graphql(ADDSOFTWARE_MUTATION)
)(AddSoftware);
export default AddNewSoftware;
