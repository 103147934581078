import React, { useState, useEffect } from "react";
import ModalPopup from "../../../ModalPopup/ModalPopup";
import NewLineItem from "./NewLineItem";
import useGlobal from "../../../../GlobalState/Store";
import { message } from "antd";

const initialState = {
  publisherType: undefined,
  softwareType: undefined,
  keyToMaintain: undefined,
  keysToUpgrade: [],
  licenseType: undefined,
  orgType: undefined,
  installType: "User",
  installNumber: 1,
  checkBox: false,
  concurrency: false,
  installsPerSeat: 1,
  lineItemCost: null,
  maintainanceCostPerSeat: null,
  maintainanceStart: null,
  expDate: null,
  keyType: undefined,
  keyData: "",
  attachFile: [],
  attachReg: [],
  itemType: null,
};

const ModifyNewLIneItem = (props) => {
  const [globalState, globalActions] = useGlobal();
  //const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const rec = props.record;

  const { addPoLineItem, updatePoLineItemList, editPoLineItem } = globalActions;
  const {
    purchaseOrder: { poLineItem },
  } = globalState;
  useEffect(() => {
    if (visible) {
      editPoLineItem({ ...rec });
    } else {
      clearPoLineItem();
    }
  }, [rec, visible]);
  const {
    publisherType,
    softwareType,
    licenseType,
    orgType,
    installType,
    installNumber,
    checkBox,
    /*  concurrency,
    installsPerSeat, */
    lineItemCost,
    maintainanceCostPerSeat,
    maintainanceStart,
    expDate,
    keyType,
    keyData,
    attachFile,
    attachReg,
  } = poLineItem;

  const clearPoLineItem = () => {
    addPoLineItem({ ...initialState });
  };

  /* const startLoading = () => {
    setLoading(true);
  };
  const stopLoading = () => {
    setLoading(false);
  };
 */
  const onModalClose = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    onModalClose();
  };
  const validatePoLineItem = () => {
    if (
      publisherType &&
      softwareType &&
      licenseType &&
      orgType &&
      (installNumber || checkBox) &&
      lineItemCost &&
      keyType.label &&
      installType
    ) {
      if (keyType.label === "File") {
        if (attachFile.length < 1) {
          message.info("Please attach a file or select a different key type.");
          return false;
        }
      } else if (keyType.label === "RegKey") {
        if (attachReg.length < 1) {
          message.info(
            "Please attach a Reg file or select a different key type."
          );
          return false;
        }
      } else if (keyType.label === "String") {
        if (!keyData) {
          message.info(
            "Please enter key string or select a different key type."
          );
          return false;
        }
      }
    } else {
      message.info("Please fill out all mandatory fields.");
      return false;
    }
    return true;
  };

  const removeUnusedKeys = (poLineItem) => {
    let data = poLineItem;
    if (data.keyType.label === "File") {
      data.attachReg = [];
      data.keyData = "";
    }
    if (data.keyType.label === "RegKey") {
      data.attachFile = [];
      data.keyData = "";
    }
    if (data.keyType.label === "String") {
      data.attachReg = [];
      data.attachFile = [];
    }
    if (data.keyType.label === "No Key") {
      data.attachReg = [];
      data.attachFile = [];
      data.keyData = "";
    }
    return data;
  };

  const handleSubmit = () => {
    const validated = validatePoLineItem();
    if (validated) {
      const item = removeUnusedKeys(poLineItem);
      updatePoLineItemList(item);
      clearPoLineItem();
      onModalClose();
    }
  };
  return (
    <div>
      <ModalPopup
        header="Modify"
        buttonText="Modify"
        link={props.link}
        name="Update"
        size="small"
        ghost={true}
        icon="edit"
        close="Cancel"
        type="primary"
        submit={handleSubmit}
        onModalClose={onModalClose}
        showModal={showModal}
        handleCancel={handleCancel}
        visible={visible}
      >
        <NewLineItem PoClaim={props.PoClaim} />
      </ModalPopup>
    </div>
  );
};

export default ModifyNewLIneItem;
