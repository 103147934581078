import React, { useState } from "react";
import SoftwareCollectionSearchWithData from "./SoftwareCollectionSearch";
import ModalPopup from "../../ModalPopup/ModalPopup";
import Spinner from "../../../Shared/Spinner/Spinner";
import PublisherList from "../../NewPurchaseOrder/PublisherList";
import SoftwareList from "../../NewPurchaseOrder/SoftwareList";
import { graphql } from "react-apollo";
import { ADDASSIGNMENT_MUTATION } from "../../../Apollo/Mutations";
import { APPASSIGNMENTS_QUERY } from "../../../Apollo/Queries";
import { Form, message } from "antd";
import Keys from "../../NewPurchaseOrder/PoLineItems/Keys";
import { formItemLayout } from "../../../Shared/Helpers";

const initialState = {
  loading: false,
  org: undefined,
  sccmApp: undefined,
  sccmCollection: undefined,
  software: undefined,
  publisher: undefined,
  visible: false,
  softwareType: undefined,
};
const AddAssignment = (props) => {
  const [state, setState] = useState(initialState);
  const {
    loading,
    sccmApp,
    sccmCollection,
    software,
    publisher,
    visible,
    softwareType,
  } = state;

  const dropDownSelectPublisher = (e) => {
    if (publisher !== e) {
      setState({
        ...state,
        software: undefined,
      });
    }
    setState({
      ...state,
      publisher: e,
    });
  };
  const dropDownSelectSoftware = (e, data) => {
    setState({
      ...state,
      software: e,
    });
  };

  const startLoading = () => {
    setState({
      ...state,
      loading: true,
    });
  };
  const stopLoading = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const onModalClose = () => {
    setState(initialState);
  };

  const showModal = () => {
    setState({
      ...state,
      visible: true,
    });
  };

  const handleCancel = (e) => {
    setState({
      ...state,
      visible: false,
    });
    onModalClose();
  };

  const selectKeyToMaintain = (e) => {
    setState({ ...state, softwareType: e });
  };

  const addAssignment = () => {
    if (
      state.publisher &&
      state.software &&
      state.softwareType &&
      state.sccmApp &&
      state.sccmCollection
    ) {
      startLoading();
      props
        .mutate({
          variables: {
            assign: {
              licenseId: softwareType.key,
              publisherId: publisher.key,
              softwareId: software.key,
              appModelId: sccmApp,
              collectionId: sccmCollection,
            },
          },
          update: (store, { data: { addAppAssignment } }) => {
            let assignment = store.readQuery({
              query: APPASSIGNMENTS_QUERY,
              variables: { opsIds: [] },
            });
            assignment.appAssignmentListByOrg.unshift(addAppAssignment);
            store.writeQuery({
              query: APPASSIGNMENTS_QUERY,
              variables: { opsIds: [] },
              data: assignment,
            });
          },
        })
        .then(({ data }) => {
          stopLoading();
          onModalClose();
          message.success("Assignment added successfully");
        })
        .catch((error) => {
          if (error.networkError) {
            if (error.networkError.result.errors[0].message === "duplicate") {
              message.warn("Duplicate record");
            }
          } else {
            message.error("An error occured!");
          }

          stopLoading();
        });
    }
  };
  const updateParent = (_app, collection, sccmAppId) => {
    setState({
      ...state,
      sccmApp: sccmAppId,
      sccmCollection: collection,
    });
  };
  return (
    <div>
      <ModalPopup
        header="Add New Assignment"
        buttonText="New Assignment"
        name="Submit"
        submit={addAssignment}
        onModalClose={onModalClose}
        showModal={showModal}
        handleCancel={handleCancel}
        visible={visible}
      >
        <Spinner loading={loading}>
          <PublisherList
            assignment={true}
            dropDownSelect={dropDownSelectPublisher}
            value={publisher}
          />
          <SoftwareList
            assignment={true}
            guid={publisher !== undefined ? [publisher.key] : []}
            dropDownSelect={dropDownSelectSoftware}
            value={software}
          />
          <div style={{ marginLeft: 48 }}>
            <Keys
              appAssign={true}
              softwareType={software}
              value={softwareType}
              dropDownSelect={selectKeyToMaintain}
              label="License"
            />
          </div>
          <div className="ant-descriptions-title">SCCM Info</div>
          <Form {...formItemLayout}>
            {/* <Form.Item label="Migrate users" required>
              <Radio.Group
                defaultValue={false}
                buttonStyle="solid"
                onChange={migrateChange}
                value={migrate}
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item> */}
          </Form>
          <SoftwareCollectionSearchWithData updateParent={updateParent} />
        </Spinner>
      </ModalPopup>
    </div>
  );
};

const AddNewAssignment = graphql(ADDASSIGNMENT_MUTATION)(AddAssignment);

export default AddNewAssignment;
