import React, { useState } from "react";
import ModalPopup from "../../ModalPopup/ModalPopup";
import Spinner from "../../../Shared/Spinner/Spinner";
import { Input, message, Form } from "antd";
import { graphql } from "react-apollo";
import { formItemLayout } from "../../../Shared/Helpers";
import { UPDATEVENDOR_MUTATION } from "../../../Apollo/Mutations";
import { VENDORLIST_QUERY } from "../../../Apollo/Queries";

const { TextArea } = Input;

const EditVendor = (props) => {
  const { rec } = props;

  const initialState = {
    SupportContact: rec.supportContact,
    KbUrl: rec.kbUrl,
    Notes: rec.notes,
  };

  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(true);

  const { SupportContact, KbUrl, Notes } = state;

  const onSubmit = () => {
    setUpdate(!update);
    if (
      SupportContact === initialState.SupportContact &&
      KbUrl === initialState.KbUrl &&
      Notes === initialState.Notes
    ) {
    } else {
      setLoading(true);
      props
        .mutate({
          variables: {
            vendor: {
              id: rec.id,
              name: rec.name,
              supportContact: SupportContact,
              kbUrl: KbUrl,
              notes: Notes,
            },
          },
          update: (store, { data: { updateVendor } }) => {
            const Vendor = store.readQuery({ query: VENDORLIST_QUERY });
            Vendor.vendorList.forEach((e) => {
              if (e.id === updateVendor.id) {
                e.name = updateVendor.name;
                e.supportContact = updateVendor.supportContact;
                e.kbUrl = updateVendor.kbUrl;
                e.notes = updateVendor.notes;
              }
            });
            store.writeQuery({ query: VENDORLIST_QUERY, data: Vendor });
          },
          /*  refetchQueries: [
            {
              query: VENDORLIST_QUERY
            }
          ] */
        })
        .then(({ data }) => {
          setLoading(false);
          setVisible(false);
          message.success(`Vendor updated!`);
        })
        .catch((error) => {
          setLoading(false);
          message.error("An Error occured. Please try again.");
        });
    }
  };

  const onInputChange = (e, n) => {
    setState({ ...state, [n]: e.target.value });
  };
  /* useEffect(() => {
    setState({ initialState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  const handleCancel = (e) => {
    setState({ ...initialState });
    setVisible(false);
    setUpdate(true);
  };
  const showModal = () => {
    setVisible(true);
  };

  const modalData = () => {
    if (visible) {
      return (
        <ModalPopup
          header={props.avatar}
          name={update ? "Edit" : "Save"}
          handleCancel={handleCancel}
          visible={visible}
          hideBtn={true}
          close={update ? "Close" : "Cancel"}
          submit={onSubmit}
        >
          <Spinner loading={loading}>
            <Form {...formItemLayout}>
              <Form.Item label="Support Contact">
                {update ? (
                  <div
                    style={{ color: SupportContact ? null : "gray" }}
                    className="editable-row"
                  >
                    {SupportContact ? SupportContact : "Empty"}
                  </div>
                ) : (
                  <Input
                    placeholder="Start typing"
                    autoFocus
                    onChange={(e) => onInputChange(e, "SupportContact")}
                    value={SupportContact}
                  />
                )}
              </Form.Item>
              <Form.Item label="Kb Url">
                {update ? (
                  <div
                    style={{ color: KbUrl ? null : "gray" }}
                    className="editable-row"
                  >
                    {KbUrl ? KbUrl : "Empty"}
                  </div>
                ) : (
                  <Input
                    onChange={(e) => onInputChange(e, "KbUrl")}
                    placeholder="Start typing"
                    value={KbUrl}
                  />
                )}
              </Form.Item>
              <Form.Item label="Notes">
                {update ? (
                  <div
                    style={{ paddingBottom: 24, color: Notes ? null : "gray" }}
                    className="editable-row"
                  >
                    {Notes ? Notes : "Empty"}
                  </div>
                ) : (
                  <TextArea
                    placeholder="Start typing"
                    onChange={(e) => onInputChange(e, "Notes")}
                    value={Notes}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                )}
              </Form.Item>
            </Form>
          </Spinner>
        </ModalPopup>
      );
    }
  };

  return (
    <div>
      <div onClick={showModal}>{props.children}</div>
      {modalData()}
    </div>
  );
};

const EditVendors = graphql(UPDATEVENDOR_MUTATION)(EditVendor);

export default EditVendors;
