import React from "react";
import { Form } from "antd";
import { Select } from "antd";
import { PUBLISHERLIST_QUERY } from "../../Apollo/Queries";
import { Query } from "react-apollo";
import { formItemLayout } from "../../Shared/Helpers";
//import AddPublisherWithData from "./AddPublisher";

const PublisherList = (props) => {
  const { Option } = Select;
  //let fullAccess;
  /* const addPublisherHelper = () => {
    fullAccess = props.PoClaim.filter(i => i.authTypeName === "FullAccess");
    if (fullAccess.length > 0) {
      return <AddPublisherWithData />;
    }
  }; */

  return (
    <div style={{}}>
      <Query query={PUBLISHERLIST_QUERY}>
        {({ data, loading, error }) => {
          if (loading)
            return (
              <Form
                layout={props.assignment ? "horizontal" : "inline"}
                {...(props.assignment ? { ...formItemLayout } : null)}
                onSubmit={props.handleSubmit}
              >
                <Form.Item hasFeedback label="Publisher" required>
                  <Select placeholder="Loading..." style={{ minWidth: 180 }} />
                </Form.Item>
              </Form>
            );
          if (error) return <p>ERROR</p>;
          const list = data.publisherList ? data.publisherList : null;
          return (
            <Form
              layout={props.assignment ? "horizontal" : "inline"}
              {...(props.assignment ? { ...formItemLayout } : null)}
              onSubmit={props.handleSubmit}
            >
              <Form.Item hasFeedback label="Publisher" required>
                <Select
                  showSearch
                  placeholder="Select Publisher"
                  style={{ minWidth: 180 }}
                  onChange={props.dropDownSelect}
                  value={props.value}
                  labelInValue={props.multiple ? false : true}
                  optionFilterProp="label"
                  mode={props.multiple ? "multiple" : "default"}
                  onDeselect={props.onDeselectChange}
                >
                  {list.map((p) => {
                    return (
                      <Option label={p.name} key={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {/* <Form.Item>{addPublisherHelper()}</Form.Item> */}
            </Form>
          );
        }}
      </Query>
    </div>
  );
};

export default PublisherList;
