import React from "react";
import Spinner from "../Spinner/Spinner";
import ComcastLogo from "../images/FullLogo.png";
import { CloseCircleOutlined } from '@ant-design/icons';

const Loading = props => {
  const { error } = props;
  return (
    <div
      style={{
        backgroundColor: "#3a3f44",
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        top: 0
      }}
    >
      <div
        style={{
          style: "flex",
          margin: "0px auto",
          textAlign: "center",
          fontSize: 20,
          color: "#DCE0DF"
        }}
      >
        <img src={ComcastLogo} alt="Comcast logo" width="350" />
        <br />
        Software Asset Management
        <br />
        <br />
        {error ? (
          <CloseCircleOutlined style={{ color: "#c8234a", fontSize: 24 }} />
        ) : (
          <Spinner loading={true} size="large" />
        )}
        <br />
        <br />
        {error
          ? "An error occured loading the site"
          : "Loading, please wait..."}
      </div>
    </div>
  );
};

export default Loading;
