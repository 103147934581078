import React, { useState, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Table, Avatar, Input, Button } from "antd";
import { SOFTWAREPUBLISHERLIST_QUERY } from "../../../Apollo/Queries";
import { Query } from "react-apollo";
import AddNewSoftware from "./AddNewSoftware";
import EditSoftware from "./EditSoftware";

const colors = ["Red", "Green", "Blue", "Orange", "Purple"];
const ManageSoftware = (props) => {
  const setSearchText = useState("")[1];
  const [isVisible, setIsVisible] = useState(false);
  const [record, setRecord] = useState("");

  let searchInput = useRef();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ paddingTop: 12, marginRight: 0, width: 40 }}>
        <SearchOutlined
          style={{
            color: filtered ? "orange" : "white",
            fontSize: 16,
          }}
          size="500"
        />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Software",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name, "en", { numeric: false }),
      render: (text, rec) => {
        if (text[0]) {
          const avatar = (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                maxHeight: 80,
              }}
            >
              <div style={{ marginRight: 10, paddingTop: 2 }}>
                <Avatar
                  size="medium"
                  style={{
                    backgroundColor: colors[rec.color],
                  }}
                >
                  {`${text[0].toUpperCase()}${text[1].toUpperCase()}`}
                </Avatar>
              </div>
              <div>
                <h3 style={{ color: "whitesmoke", marginBottom: 0 }}>{text}</h3>
                <p>{rec.publisherName}</p>
              </div>
            </div>
          );
          return <div>{avatar}</div>;
        }
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
  ];
  const onRowClick = (rec) => {
    setRecord(rec);
    setIsVisible(true);
  };

  return (
    <div style={{ margin: "20px" }}>
      <Query query={SOFTWAREPUBLISHERLIST_QUERY}>
        {({ data, loading, error }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>ERROR</p>;

          return (
            <>
              <div className="ant-descriptions-title">Manage Software</div>
              <EditSoftware
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                record={record}
                setRecord={setRecord}
              />
              <div style={{ minWidth: "24em" }}>
                <AddNewSoftware admin={true} />
                <Table
                  style={{ marginTop: 10 }}
                  onRow={(rec, i) => {
                    return {
                      onClick: (e) => onRowClick(rec),
                    };
                  }}
                  className="column"
                  columns={columns}
                  rowKey={(rec) => rec.id}
                  dataSource={data.softwarePublisherList}
                  pagination={{
                    defaultPageSize: 25,
                    pageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50"],
                  }}
                  size="small"
                />
              </div>
            </>
          );
        }}
      </Query>
    </div>
  );
};

export default ManageSoftware;
