import React from "react";
import { Result, Button } from "antd";

const Results = props => {
  return (
    <div>
      <Result
        status="success"
        title={
          <h4 style={{ color: "whitesmoke" }}>Order Submitted Successfully</h4>
        }
        extra={[
          <Button key="button" onClick={props.handleCLick} type="secondary">
            Enter New Order
          </Button>
        ]}
      />
    </div>
  );
};

export default Results;
