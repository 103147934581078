import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { Select } from "antd";
import { ORGLIST_QUERY } from "../../../../Apollo/Queries";
import { Query } from "react-apollo";
import { PermissionsFormLayout } from "../../../../Shared/Helpers";
import useGlobal from "../../../../GlobalState/Store";

const OrgSelect = (props) => {
  const globalState = useGlobal()[0];
  const { authClaims } = globalState;

  const [state, setState] = useState({
    selected: [],
  });
  useEffect(() => {
    if (props.edit) {
      setState({ ...state, selected: props.edit });
    }
  }, []);
  const { updateParent, add, remove, isAdmin } = props;
  useEffect(() => {
    if (updateParent) {
      updateParent(state.selected);
    }
  }, [state]);
  const { Option } = Select;
  const orgListHelper = () => {
    return (
      <Form {...PermissionsFormLayout}>
        <Form.Item label="Org" required>
          <Select placeholder="Loading..." style={{ minWidth: 200 }} />
        </Form.Item>
      </Form>
    );
  };
  const onChangeSelect = (e) => {
    let list = state.selected;
    list.push(e);
    add(e);
    setState({ ...state, selected: list });
  };
  const onChangeDeselect = (e) => {
    let list = state.selected;
    list = list.filter((i) => i.key !== e.key);
    remove(e);
    setState({ ...state, selected: list });
  };

  const optionsBuilder = (data) => {
    if (!isAdmin && authClaims.length > 0) {
      return authClaims.map((p) => {
        if (p.moduleId === 8 && p.authTypeId === 12) {
          return (
            <Option key={p.ops.id} value={p.ops.id}>
              {p.ops.subRegion}
            </Option>
          );
        }
      });
    } else {
      return data.orgList.map((p) => {
        return (
          <Option key={p.id} value={p.id}>
            {p.subRegion}
          </Option>
        );
      });
    }
  };

  return (
    <div>
      <Query query={ORGLIST_QUERY}>
        {({ data, loading, error }) => {
          if (loading) return orgListHelper();
          if (error) return <p>ERROR</p>;
          return (
            <div>
              <Form {...PermissionsFormLayout}>
                <Form.Item label="Org" required>
                  <Select
                    mode="multiple"
                    showSearch
                    value={state.selected}
                    placeholder="Select Org"
                    onSelect={onChangeSelect}
                    onDeselect={onChangeDeselect}
                    labelInValue
                  >
                    {optionsBuilder(data)}
                  </Select>
                </Form.Item>
              </Form>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default OrgSelect;
