import React, { useState } from "react";
import { Table } from "antd";
import { BROWSELIST_QUERY2 } from "../../Apollo/Queries";
import { Query } from "react-apollo";
import useGlobal from "../../GlobalState/Store";
import { Link } from "react-router-dom";

const SoftwareUsage = (props) => {
  const setLoading = useState(false)[1];
  const globalState = useGlobal()[0];
  const { opsList } = globalState;
  const columns = [
    {
      title: "Title",
      align: "center",
      dataIndex: "softwareName",
      key: "softwareName",
      sorter: (a, b) =>
        a.softwareName.localeCompare(b.softwareName, "en", { numeric: true }),
      render: (text, rec) => {
        return (
          <Link
            style={{ textDecoration: "underline" }}
            to={`/BrowsebyId/${rec.softId}`}
          >
            {text} {rec.version}
          </Link>
        );
      },
    },
    {
      title: "Key Usage",
      align: "center",
      dataIndex: "keyOverview",
      key: "keyOverview",
      render: (text) => `${text}%`,
    },
  ];
  const dataHelper = (data) => {
    if (props.most) {
      if (data) {
        data = data.sort((a, b) => b.keyOverview - a.keyOverview).slice(0, 5);
        return data;
      }
    } else {
      if (data) {
        data = data.sort((a, b) => a.keyOverview - b.keyOverview).slice(0, 5);
        return data;
      }
    }
  };
  return (
    <Query
      query={BROWSELIST_QUERY2}
      variables={{
        var: { most: props.most, opsList },
      }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          setLoading(true);
        }
        if (error) return <p>ERROR</p>;
        setLoading(false);
        return (
          <div className="Dashboard">
            <Table
              title={() => (
                <div
                  className="ant-descriptions-title"
                  style={{ marginBottom: 0, background: "#8c8c8c" }}
                >
                  {props.title}
                </div>
              )}
              style={{
                backgroundColor: "#3d4144",
                height: 300,
              }}
              rowKey={(rec) => rec.id}
              columns={columns}
              dataSource={dataHelper(data.browseList2)}
              size="middle"
              pagination={false}
              loading={loading}
            />
          </div>
        );
      }}
    </Query>
  );
};

export default SoftwareUsage;
