import React, { useEffect, useState } from "react";
import { InboxOutlined } from '@ant-design/icons';
import { Form } from "antd";
import { Input, InputNumber, Checkbox, Radio, Upload, DatePicker, message } from "antd";
import useGlobal from "../../../../GlobalState/Store";
import LicenseTypeList from "../../LicenseTypeList";
import KeyTypeList from "../../KeyTypeList";
import { dateFormat } from "../../../../Shared/Helpers";
import PublisherList from "../../PublisherList";
import SoftwareList from "../../SoftwareList";
//import Keys from "../Keys";
import { uploadEnvCheck } from "../../../../Apollo/Apollo";
import LicenseKeys from "./LicenseKeys";
import OrgList from "../OrgList";

const UpgradeLineItem = (props) => {
  const [globalState, globalActions] = useGlobal();
  const { addPoLineItem } = globalActions;
  const {
    authState: { rawToken, accessToken },
    purchaseOrder: {
      poLineItem: {
        migrateSoftware,
        publisherType,
        softwareType,
        publisherTypeList,
        softwareTypeList,
        softPubKeys,
        licenseSoftKeys,
        keysToUpgrade,
        licenseType,
        orgType,
        installType,
        migrateUsers,
        installNumber,
        checkBox,
        concurrency,
        installsPerSeat,
        lineItemCost,
        maintainanceCostPerSeat,
        maintainanceStart,
        expDate,
        keyType,
        keyData,
        attachFile,
        attachReg,
      },
    },
  } = globalState;
  const [keyOps, setKeyOps] = useState([]);
  useEffect(() => {
    if (keyOps.length > 0) {
      const isTrue = keyOps.every((val, i, arr) => val.Ops === arr[0].Ops);
      if (isTrue) {
        addPoLineItem({
          orgType: { key: keyOps[0].Ops, label: keyOps[0].Org },
        });
      } else {
        addPoLineItem({ orgType: { key: 1, label: "Enterprise" } });
      }
    }
  }, [keyOps]);

  const onCheck = (e) => {
    const checkBox = e.target.checked;
    addPoLineItem({ checkBox });
    if (checkBox) {
      addPoLineItem({ installNumber: null });
    }
    if (!checkBox) {
      addPoLineItem({ installNumber: 1 });
    }
  };
  const concurrencyChange = (e) => {
    const concurrency = e.target.checked;
    addPoLineItem({ concurrency });
  };
  const dateChange = (e) => {
    addPoLineItem({ expDate: e });
  };
  const selectKeysToUpgrade = (e, f) => {
    let ops = [];
    if (f && f.length > 0) {
      let List = [];
      f.forEach((e) => {
        List.push({ Soft: e.ref, Key: e.key });
        ops.push({ Soft: e.ref, Ops: e.props.ops, Org: e.props.org });
      });
      addPoLineItem({
        licenseSoftKeys: List,
      });
      setKeyOps(ops);
    } else {
      addPoLineItem({ licenseSoftKeys: [], orgType: undefined });
      setKeyOps([]);
    }
    addPoLineItem({ keysToUpgrade: e });
  };
  /*  if (keyOps.length > 0) {
    const isTrue = keyOps.every((val, i, arr) => val.Ops === arr[0].Ops);
    if (isTrue) {
      addPoLineItem({ orgType: { key: keyOps[0].ops, label: keyOps[0].org } });
    } else {
      addPoLineItem({ orgType: { key: 1, label: "Enterprise" } });
    }
  } */
  const maintainanceStartChange = (e) => {
    addPoLineItem({ maintainanceStart: e });
  };
  const updateAttachedFiles = (info, type) => {
    let fileList = [...info.fileList];
    fileList = fileList.map((e) => {
      delete e.originFileObj;
      delete e.lastModified;
      delete e.lastModifiedDate;
      delete e.percent;
      delete e.response;
      return e;
    });

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    //fileList = fileList.slice(-2);

    fileList = fileList.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.name === thing.name)
    );
    if (type === "File") {
      addPoLineItem({ attachFile: fileList });
    } else if (type === "RegKey") {
      addPoLineItem({ attachReg: fileList });
    }
  };
  const changeKeyType = (e) => {
    e.persist();
    let value = e.target.value;
    addPoLineItem({ keyData: value });
    //TODO: before writing to db use regex
    //console.log(keyData.match(/[^\r\n]+/g));
  };
  const selectLicenseType = (e) => {
    addPoLineItem({ licenseType: e });
  };
  const installTypeChange = (e) => {
    addPoLineItem({ installType: e.target.value });
  };
  const migrateUsersChange = (e) => {
    addPoLineItem({ migrateUsers: e.target.value });
  };
  const migrateSoftwareChange = (e) => {
    addPoLineItem({ migrateSoftware: e.target.value, keysToUpgrade: [] });
    setKeyOps([]);
  };
  const inputNumberChange = (e) => {
    addPoLineItem({ installNumber: e });
  };

  const installsPerSeatChange = (e) => {
    addPoLineItem({ installsPerSeat: e });
  };

  const keyTypeChange = (e) => {
    /* if (
      (e.label === "No Key" || e.label === "File" || e.label === "RegKey") &&
      keyData
    ) {
      addPoLineItem({ keyData: null });
    } */
    addPoLineItem({ keyType: e });
  };

  const maintainanceCostChange = (e) => {
    const number = parseFloat(e).toFixed(2);
    if (!isNaN(number)) {
      addPoLineItem({ maintainanceCostPerSeat: number });
    } else if (isNaN(number)) {
      addPoLineItem({ maintainanceCostPerSeat: null });
    }
  };

  const licenseCostChange = (e) => {
    const number = parseFloat(e).toFixed(2);
    if (!isNaN(number)) {
      addPoLineItem({ lineItemCost: number });
    } else if (isNaN(number)) {
      addPoLineItem({ lineItemCost: null });
    }
  };

  const dropDownSelectPublisher = (e) => {
    if (publisherType !== e) {
      addPoLineItem({ softwareType: undefined });
    }
    addPoLineItem({ publisherType: e });
    if (!migrateSoftware) {
      addPoLineItem({ keysToUpgrade: [] });
      setKeyOps([]);
    }
  };
  const dropDownMultiSelectPublisher = (e) => {
    /* if (publisherType !== e) {
      console.log(e);
      addPoLineItem({ softwareTypeList: [] });
    } */

    addPoLineItem({ publisherTypeList: e });
    addPoLineItem({ keysToUpgrade: [] });
    setKeyOps([]);
  };
  const dropDownSelectSoftware = (e) => {
    addPoLineItem({ softwareType: e });
    if (!migrateSoftware) {
      addPoLineItem({ keysToUpgrade: [] });
      setKeyOps([]);
    }
  };
  const dropDownMultiSelectSoftware = (e, f) => {
    if (f && f.length > 0) {
      let List = [];
      f.forEach((e) => {
        List.push({ Pub: e.ref, Key: e.key });
      });
      addPoLineItem({ softPubKeys: List });
    } else {
      addPoLineItem({ softPubKeys: [] });
      setKeyOps([]);
    }
    addPoLineItem({ softwareTypeList: e });
  };
  const onDeselectPublisherChange = (e) => {
    if (e) {
      const toRem = softPubKeys.filter((i) => i.Pub !== e);
      const newList = [];
      toRem.forEach((e) => {
        newList.push(e.Key);
      });
      addPoLineItem({
        softwareTypeList: newList,
        softPubKeys: toRem,
      });
    }
  };
  const onDeselectSoftwareChange = (e, f) => {
    if (e) {
      const toRem = licenseSoftKeys.filter((i) => i.Soft !== e);
      const remOps = keyOps.filter((i) => i.Soft !== e);
      const newList = [];
      toRem.forEach((e) => {
        newList.push(e.Key);
      });
      addPoLineItem({
        keysToUpgrade: newList,
        licenseSoftKeys: toRem,
      });
      setKeyOps(remOps);
    }
  };

  const beforeUpload = (a, type) => {
    const dup = attachFile.find((i) => i.name === a.name);
    if (type === "RegKey") {
      if (a.name.length > 3 && a.name.substr(a.name.length - 4) !== ".reg") {
        message.error("This is not a valid registry file.");
        return false;
      }
    } else if (a.size > 5242880) {
      message.error("This file exceeds 5MB limit.");
      return false;
    } else if (dup) {
      message.error("Duplicate filename!");
      return false;
    } else {
      return true;
    }
  };
  const uploadChange = (info, type) => {
    const under5mb = info.file.size < 5242880;
    if (
      type === "RegKey" &&
      info.file.name.length > 3 &&
      info.file.name.substr(info.file.name.length - 4) === ".reg" &&
      under5mb
    ) {
      updateAttachedFiles(info, type);
    }
    if (type === "File" && under5mb) {
      updateAttachedFiles(info, type);
    }
  };

  const dataHelper = (e) => {
    let data = {};
    data.uid = e.uid;
    return data;
  };

  const keyTypeHelper = () => {
    if (keyType && keyType.label === "String") {
      return (
        <Form layout="inline">
          <Form.Item label="Key data:">
            <Input.TextArea
              style={{ width: 325 }}
              rows={4}
              name=""
              onChange={changeKeyType}
              value={keyData}
            />
          </Form.Item>
        </Form>
      );
    }
    if (keyType && keyType.label === "File") {
      /* if (dropdown !== "File") {
        setDropdown("File");
         if (attachFile.length > 0) {
          addPoLineItem({ attachFile: [] });
        }
      }*/
      return (
        <Form layout="inline">
          <Form.Item label="Key data">
            <Upload.Dragger
              style={{ width: 325 }}
              name="file"
              data={dataHelper}
              multiple={true}
              beforeUpload={(e) => beforeUpload(e, keyType.label)}
              action={uploadEnvCheck()}
              headers={{
                Authorization: rawToken ? `Bearer ${rawToken}` : "",
                Access: accessToken,
              }}
              onChange={(e) => uploadChange(e, keyType.label)}
              fileList={attachFile}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                There is a 5MB upload limit per attached file.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      );
    }
    if (keyType && keyType.label === "RegKey") {
      /* if (dropdown !== "RegKey") {
        setDropdown("RegKey");
        if (attachFile.length > 0) {
          addPoLineItem({ attachFile: [] });
        }
      }*/
      return (
        <Form layout="inline">
          <Form.Item label="Key data">
            <Upload.Dragger
              style={{ width: 325 }}
              name="file"
              data={dataHelper}
              fileList={attachReg}
              multiple={true}
              beforeUpload={(e) => beforeUpload(e, keyType.label)}
              action={uploadEnvCheck()}
              headers={{
                Authorization: rawToken ? `Bearer ${rawToken}` : "",
                Access: accessToken,
              }}
              onChange={(e) => uploadChange(e, keyType.label)}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                There is a 5MB upload limit per attached file
              </p>
              <p className="ant-upload-hint">Only .reg files allowed</p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      );
    }
  };
  return (
    <div style={{ paddingLeft: 20 }}>
      {/* <Form layout="inline">
        <Form.Item label="Migrate to new software" required>
          <Radio.Group
            defaultValue={false}
            buttonStyle="solid"
            onChange={migrateSoftwareChange}
            value={migrateSoftware}
          >
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form> */}
      <PublisherList
        PoClaim={props.PoClaim}
        dropDownSelect={
          migrateSoftware
            ? dropDownMultiSelectPublisher
            : dropDownSelectPublisher
        }
        onDeselectChange={onDeselectPublisherChange}
        value={migrateSoftware ? publisherTypeList : publisherType}
        multiple={migrateSoftware ? true : false}
        /* handleSubmit={handleChoose}
         */
      />
      <SoftwareList
        guid={
          migrateSoftware
            ? publisherTypeList
            : publisherType !== undefined
            ? [publisherType.key]
            : []
        }
        onDeselectChange={onDeselectSoftwareChange}
        PoClaim={props.PoClaim}
        dropDownSelect={
          migrateSoftware ? dropDownMultiSelectSoftware : dropDownSelectSoftware
        }
        value={migrateSoftware ? softwareTypeList : softwareType}
        multiple={migrateSoftware ? true : false}
      />
      <LicenseKeys
        softwareType={
          migrateSoftware
            ? softwareTypeList
            : softwareType !== undefined
            ? [softwareType.key]
            : []
        }
        value={keysToUpgrade}
        dropDownSelect={selectKeysToUpgrade}
        multiple={true}
        label="Upgrade Key(s)"
      />
      <Form layout="inline">
        <Form.Item label="Migrate Users" required>
          <Radio.Group
            defaultValue={true}
            buttonStyle="solid"
            onChange={migrateUsersChange}
            value={migrateUsers}
          >
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
      <h3 style={{ color: "whitesmoke", textAlign: "center", minWidth: 140 }}>
        New Key Info
      </h3>
      <hr />
      {keysToUpgrade.length > 0 ? (
        <OrgList value={orgType} disabled={true} />
      ) : null}

      {migrateSoftware ? (
        <>
          <PublisherList
            PoClaim={props.PoClaim}
            dropDownSelect={dropDownSelectPublisher}
            value={publisherType}
            //multiple={true}
            /* handleSubmit={handleChoose}
             */
          />
          <SoftwareList
            guid={publisherType !== undefined ? [publisherType.key] : []}
            PoClaim={props.PoClaim}
            dropDownSelect={dropDownSelectSoftware}
            value={softwareType}
          />
        </>
      ) : null}
      <LicenseTypeList value={licenseType} dropDownSelect={selectLicenseType} />

      <Form layout="inline">
        <Form.Item label="Install Per" required>
          <Radio.Group
            defaultValue="User"
            buttonStyle="solid"
            onChange={installTypeChange}
            value={installType}
          >
            <Radio.Button value="User">User</Radio.Button>
            <Radio.Button value="Machine">Machine</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
      <Form layout="inline">
        <Form.Item
          label="Number of Seats"
          required
          style={{ paddingRight: 10 }}
        >
          <InputNumber
            disabled={checkBox}
            name=""
            min={1}
            onChange={inputNumberChange}
            defaultValue={1}
            value={installNumber}
          />
        </Form.Item>
        <Form.Item label="Check for Unlimited">
          <Checkbox onChange={onCheck} checked={checkBox} />
        </Form.Item>
      </Form>
      {installType === "User" ? (
        <Form layout="inline">
          <Form.Item label="Concurrent Usage Allowed">
            <Checkbox onChange={concurrencyChange} checked={concurrency} />
          </Form.Item>
        </Form>
      ) : null}
      {installType === "User" ? (
        !concurrency ? (
          <Form layout="inline">
            <Form.Item
              label="Installs per Seat"
              style={{ paddingRight: 10 }}
              required
            >
              <InputNumber
                name=""
                min={1}
                onChange={installsPerSeatChange}
                value={installsPerSeat}
              />
            </Form.Item>
          </Form>
        ) : null
      ) : null}
      <Form layout="inline" style={{}}>
        <Form.Item label="Cost (per seat)" required>
          <InputNumber
            min={0}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={licenseCostChange}
            value={lineItemCost}
            style={{ width: 150 }}
          />
        </Form.Item>
      </Form>
      <Form layout="inline">
        <Form.Item
          required={
            licenseType
              ? licenseType.label === "Perpetual"
                ? true
                : false
              : false
          }
          label="Maintenance Cost (per seat)"
        >
          <InputNumber
            min={0}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={maintainanceCostChange}
            value={maintainanceCostPerSeat}
            style={{ width: 125 }}
          />
        </Form.Item>
      </Form>
      <Form layout="inline" style={{}}>
        <Form.Item label="Maintenance Start">
          <DatePicker
            format={dateFormat}
            placeholder="Select date"
            value={maintainanceStart}
            onChange={maintainanceStartChange}
          />
        </Form.Item>
      </Form>
      <Form layout="inline" style={{}}>
        <Form.Item
          label={
            licenseType
              ? licenseType.label === "Perpetual"
                ? "Maintenance Renewal Date"
                : "Expiration Date"
              : "Expiration Date"
          }
        >
          <DatePicker
            format={dateFormat}
            placeholder="Select date"
            value={expDate}
            onChange={dateChange}
          />
        </Form.Item>
      </Form>
      <KeyTypeList value={keyType} keyTypeChange={keyTypeChange} />

      {keyTypeHelper()}
    </div>
  );
};

export default UpgradeLineItem;
