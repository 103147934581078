import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { AutoComplete } from "antd";
import { graphql } from "react-apollo";
import { AUTOSUGGEST_QUERY } from "../../../../Apollo/Queries";
import { PermissionsFormLayout } from "../../../../Shared/Helpers";
// import { useDebouncedCallback } from "use-debounce/lib";
import { debounce } from "lodash";

const { Option } = AutoComplete;

const UserLookups = (props) => {
  const [searching, setSearching] = useState("");
  const [state, setState] = useState({ userNt: "" });
  const [searchMessage, setSearchMessage] = useState("");

  const { updateParent } = props;
  useEffect(() => {
    if (updateParent) {
      updateParent(state.userNt);
      //alert(state.userNt);
    }
  }, [state.userNt]);

  const dataHelper = () => {
    return props.data.autoSuggestByNt
      ? props.data.autoSuggestByNt.map((a) => {
          //BUG: Azure Migration DONE - Add required unique key for react element and add value attribute
          return <Option key={a.ntLogon + " - " + a.azureObjectId} value={a.ntLogon}>{`${a.name}; ${a.title}`}</Option>;
        })
      : null;
  };
  //BUG: Azure Migration DONE - Replace Debouncer
  // const [onSearchChange] = useDebouncedCallback((e) => {
const onSearchChange = (e) => {
    if (e) {
      setSearching("validating");
      setSearchMessage("");

      props.data
        .refetch({ nt: e })
        .then(({ data }) => {
          setSearching("");
          if (data && data.autoSuggestByNt.length < 1) {
            setSearchMessage("No found.");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
 };
  // }, 500);
  const onBlurChange = (e) => {
    if (e !== state.userNt) {
      //setState({ ...state, userNt: "" });
    }
  };

  const onChangeNt = (e) => {
    setState({ ...state, userNt: e , searchInput: { key: undefined, label: undefined }});
  };
  return (
    <Form {...PermissionsFormLayout}>
      <Form.Item
        required
        label="Username"
        validateStatus={searching}
        hasFeedback
        help={searchMessage}
      >
        <AutoComplete
          autoFocus
          placeholder="Lastname, Firstname or NT"
          onSelect={onChangeNt}
          //BUG: Azure Migration DONE - Replace Method
          //onSearch={onSearchChange}
          onSearch={debounce(onSearchChange, 500)}
          onBlur={(e) => onBlurChange(e)}
        >
          {dataHelper()}
        </AutoComplete>
      </Form.Item>
    </Form>
  );
};
const UserLookup = graphql(AUTOSUGGEST_QUERY)(UserLookups);
export default UserLookup;
