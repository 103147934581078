import React from "react";
import { Form } from "antd";
import { Select } from "antd";
import { LICENSETYPELIST_QUERY } from "../../Apollo/Queries";
import { Query } from "react-apollo";

const LicenseTypeList = (props) => {
  const { Option } = Select;
  const licenseTypeHelper = () => {
    return (
      <Form layout="inline">
        <Form.Item label="License Type" required>
          <Select placeholder="Loading..." style={{ minWidth: 200 }} />
        </Form.Item>
      </Form>
    );
  };
  return (
    <div>
      <Query query={LICENSETYPELIST_QUERY}>
        {({ data, loading, error }) => {
          if (loading) return licenseTypeHelper();
          if (error) return <p>ERROR</p>;
          return (
            <div>
              <Form layout="inline">
                <Form.Item label="License Type" required>
                  <Select
                    showSearch
                    placeholder="Select License"
                    style={{ minWidth: 200 }}
                    onChange={props.dropDownSelect}
                    labelInValue
                    value={props.value}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {data.licenseTypeList.map((p) => {
                      return (
                        <Option key={p.id} value={p.id}>
                          {p.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Form>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default LicenseTypeList;
