import { newGuid } from "../../Shared/Helpers";

export const addPurchaseOrder = (store, payload) => {
  store.setState({
    purchaseOrder: { ...store.state.purchaseOrder, ...payload },
  });
};

export const clearNewPo = (store, payload) => {
  store.setState({
    poLineItemList: [],
    ...store.state.purchaseOrder,
    /*  purchaseOrder: {
      poNumber: null,
      orderDate: null,
      vendorType: undefined,
      poLineItem: store.state.purchaseOrder.poLineItem, 
    },
     */
  });
};

export const editPoLineItem = (store, payload) => {
  store.setState({
    purchaseOrder: {
      ...store.state.purchaseOrder,
      poLineItem: { ...payload },
    },
  });
};

export const addPoLineItem = (store, payload) => {
  store.setState({
    purchaseOrder: {
      ...store.state.purchaseOrder,
      poLineItem: { ...store.state.purchaseOrder.poLineItem, ...payload },
    },
  });
};

export const removeFromPoLineItemList = (store, payload) => {
  let poList = [...store.state.poLineItemList];
  poList = poList.filter((i) => i.id !== payload);
  store.setState({
    poLineItemList: poList,
  });
};

export const updatePoLineItemList = (store, payload) => {
  const poLineItemList = store.state.poLineItemList;
  const index = poLineItemList.findIndex((obj) => obj.id === payload.id);
  poLineItemList[index] = payload;
  store.setState({ poLineItemList });
};

export const addToPoLineItemList = (store, payload) => {
  const poList = [...store.state.poLineItemList];
  payload.id = newGuid();
  //payload.itemType = "New Software";
  poList.push(payload);
  store.setState({
    poLineItemList: poList,
  });
};

export const getVendorGuids = (store, vendorGuids) => {
  store.setState({ vendorGuids });
};

export const setPoStepper = (store, poStepper) => {
  store.setState({ poStepper: poStepper });
};

export const setLicenseStepper = (store, licenseStepper) => {
  store.setState({ licenseStepper: licenseStepper });
};

export const setLicenseInfoData = (store, licenseInfoData) => {
  store.setState({
    licenseInfo: { ...store.state.licenseInfo, ...licenseInfoData },
  });
};

export const clearLicenseInfo = (store, payload) => {
  store.setState({
    licenseInfo: {},
  });
};

export const removeLicenseInfo = (store, payload) => {
  const licenseInfo = store.state.licenseInfo;
  delete licenseInfo[payload];
  store.setState({
    licenseInfo,
  });
};

export const updateLicenseInfo = (store, payload) => {
  store.setState({
    licenseInfo: payload,
  });
};

export const setBtnStatus = (store, payload) => {
  store.setState({
    btnStatus: payload,
  });
};

export const clearState = (store, payload) => {
  const state = {
    newOrderState: {
      PublisherType: [],
      VendorType: [],
      purchaseOrder: "",
      SoftwareType: [],
    },
    licenseInfo: {},
    poStepper: 0,
    licenseStepper: 0,
    btnStatus: true,
    vendorGuids: [],
  };
  store.setState({ ...state });
};

export const setExpandRows = (store, payload) => {
  store.setState({ expandRows: payload });
};

export const setActiveTab = (store, payload) => {
  store.setState({
    activeTab: payload,
  });
};

export const updateCost = (store, payload) => {
  store.setState({
    cost: payload,
  });
};
